var _ = require("../../../uam/module.js")(exports, {
  namespace: "ua.wizard",
  title: "Wizard",
  control: true,
  description: "A step-by-step wizard for controlling a user guided process.",
  fields: {
    "inner": {
      type: "array",
      "of": "inner",
      description: "The steps of the wizard, each with a title, description, and optional icon.",
      required: true
    }, "name": {
      type: "string",
      description: "The name of the wizard.",
      required: true
    }, "progressbarclass": {
      type: "string",
      description: "The class of the progress bar.",
      default: "bg-primary"
    }
  },
  // required: required//,
  // supportClient: false,
  // supportServer: true
});

var handlerz = [];
var uae = require("./templates/ua.element.js");
var uai = require("../interface/interface.js");

// var namespace = "ua.wizard";
// exports.namespace = namespace;

// var define = ; module.exports.define = define;

exports.render = function (options) {

  var wizardId = uai.generateRandomID(12);
  
  var wiz = {
    "n": "div",
    "id": wizardId,
    "data-wizard": "true",
    "name": options.name,
    "data-wizard-steps": options.inner.length,
    "data-wizard-current": "1",
    "data-wizard-hasSeen": "[1]"
  }

  var steps = options.inner.map((step, index) => {
    var cClass = "wizard-step";

    //is the first index
    if (!(index == 0)) {
      cClass += " d-none";
    }

    return {
      "n": "div",
      "data-wizard-id": wizardId,
      "data-wizard-step": index + 1,
      "c": cClass,
      "i": step
    }
  });


  /**
   * <div class="p-fixed left-0 right-0 bottom-0 p-2">
                    <div class="d-flex flex-fill p-3">
                        <div id="wizardProgress" class="bg-primary rounded" style="height: 2rem; width: 0%;"></div>
                    </div>
                    <button id="btnSubmit" class="button btn btn-success fw-bold box-shadow text-shadow text-white">Finish &amp;
                        Submit</button>
                </div>
   */

//  var progressbar

  wiz.i = [{
    "n": "div",
    "c": "wizard",
    "i": steps
  }, {
    "n": "div",
    "c": "bg-blur-75-black d-flex p-3 rounded mb-5 mt-3",
    "i": [{
      "n": "div", 
      "c": "d-flex flex-fill p-3",
      "i": {
        "n": "div",
        "data-wizard-id": wizardId,
        "data-wizard-progress": "true",
        "c": `rounded ${options.progressbarclass}`,
        "style": `height: 2rem; width: ${(1/options.inner.length)*100}%;`
      }
    }, {
      "n": "div",
      "c": "",
      "i": {
        "n": "button",
        "id": "btnBack",
        "c": " m-2 button btn btn-info fw-bold box-shadow text-shadow text-white d-none",
        "i": [{
          "n": "i",
          "c": "bi bi-arrow-left"
        }, {
          "n": "span",
          "c": "d-none d-lg-inline",
          "i": " Back"
        }]
        }
    }, {
      "n": "div",
      "c": "",
      "i": {
        "n": "button",
        "id": "btnContinue",
        "c": " m-2 button btn btn-success fw-bold box-shadow text-shadow text-white",
        "i": [{
          "n": "i",
          "c": "bi bi-arrow-right"
        }, {
          "n": "span",
          "c": "d-none d-lg-inline",
          "i": " Continue"
        }]
        }
    }]
  }];

  return wiz;
  
}; module.exports.render = this.render;



/**
 * Get the wizard's current step.
 * @param {*} name The name of the control.
 * @param {*} control The control to save.
 * @param {*} repo The repo (used to send back), with the prevalidation results.
 * @property {*} repo.success The success object (is this field ready to be saved).
 * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
 * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
 * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
 * @property {*} repo.errors.input.id The id of the input field, if applicable.
 * @property {*} repo.errors.input.name The name of the input field if applicable.
 * @property {*} repo.errors.type The type of error that occured.
 *  - Supports: "validation" - The input or field or control is invalid
 *  - Supports: "thowable" - Processing this field caused a throwable to error out.
 * @property {*} repo.errors.message The message to display to the user.
 * @returns The repo object with the data to save
 */
async function save(name, control, repo) {

  //get the current step
  var wizard = control.querySelector("[data-wizard='true']");
  var wizardId = wizard.getAttribute("id");
  var currentStep = wizard.getAttribute("data-wizard-current");
  var totalSteps = wizard.getAttribute("data-wizard-steps");

  repo.data = {
    id: wizardId,
    current: currentStep,
    total: totalSteps
  };
  
  return repo;

} module.exports.save = save;

/**
 * Sets the wizard's current step.
 * @param {*} name The name of the control.
 * @param {*} control The control itself (including placeholder tag).
 * @param {*} data The data to load into the control.
 */
async function load(name, control, data) {

  var wizard = control.querySelector("[data-wizard='true']");
  var wizardId = wizard.getAttribute("id");
  var currentStep = wizard.getAttribute("data-wizard-current");

  var nextStep = currentStep;
  var totalSteps = wizard.getAttribute("data-wizard-steps");

  switch (data) {
    case "next":

      //if the currentStep is less than the total steps
      if (currentStep < totalSteps) {
        
        nextStep++;

      } else {
        throw new Error("Cannot go to the next step, reached the end of the wizrd.");
      }

    break;
    case "back":

      //if the currentStep is greater than 1
      if (currentStep > 1) {
        
        nextStep--;

      } else {
        throw new Error("Cannot go back, reached the beginning of the wizard.");
      }

    break;
    default:

      data = parseInt(data);

      if (isNaN(data)) {
        throw new Error("Invalid data type, must be 'next', 'back', or a number.");
      }

      if (data > 0 && data <= totalSteps) {
        nextStep = data;
      } else {
        throw new Error("Invalid step number, must be greater than 0 and less than or equal to the total steps.");
      }

    break;
  }
  

  //hide the current step
  var currentStepDiv = control.querySelector(`[data-wizard-id="${wizardId}"][data-wizard-step="${currentStep}"]`);
  currentStepDiv.classList.add("d-none");
   
  var nextStepDiv = control.querySelector(`[data-wizard-id="${wizardId}"][data-wizard-step="${nextStep}"]`);
  nextStepDiv.classList.remove("d-none");

  //advance the progress bar
  var progress = control.querySelector(`[data-wizard-id="${wizardId}"][data-wizard-progress="true"]`);

  //if current step is the last step
  if (nextStep == totalSteps) {
    progress.style.width = `100%`;
  } else {
    progress.style.width = `${(nextStep/totalSteps)*100}%`;
  }

  //if the new step is more than 1 show the back button
  if (nextStep > 1) {
    var btnBack = control.querySelector("#btnBack");
    btnBack.classList.remove("d-none");
  } else {
    var btnBack = control.querySelector("#btnBack");
    btnBack.classList.add("d-none");
  }

  //update has seen uniquely
  var hasSeen = wizard.getAttribute("data-wizard-hasSeen");
  hasSeen = JSON.parse(hasSeen);

  //has the wizard step been seen yet?
  var nextStepSeen = hasSeen.includes(nextStep);
  if (!(nextStepSeen)) {
    console.log("Resetting validation for", `[data-wizard-id="${wizardId}"][data-wizard-step="${nextStep}"]`);
    ResetValidation(`[data-wizard-id="${wizardId}"][data-wizard-step="${nextStep}"]`);
  }

  //only push unique values
  if (!(hasSeen.includes(nextStep))) {
    hasSeen.push(nextStep);
  }

  wizard.setAttribute("data-wizard-hasSeen", JSON.stringify(hasSeen));


  //update the current step
  wizard.setAttribute("data-wizard-current", nextStep);

  //scroll to top
  document.body.scrollTop = 0;

} module.exports.load = load;


async function ResetValidation(selector) {
  var query = document.querySelector(selector);
  query.querySelectorAll(".is-invalid").forEach(element => {
    element.classList.remove("is-invalid");
  });
  query.querySelectorAll(".is-valid").forEach(element => {
    element.classList.remove("is-valid");
  });
}

/**
 * Add a handler to the wizard.
 * Handlers support wild cards. You can set wizardID to null (or false) and step to null (or false) to create a wildcard handler.
 * @param {*} wizardID The wizard ID to attach the handler to.
 * @param {*} onStep The step to attach the handler to.
 * @param {*} myFunction The function to run when the step is reached.
 */
function AddHandler(wizardID, onStep, myFunction) {
  handlerz.push({
    wizard: wizardID,
    step: onStep,
    handler: myFunction
  });
} module.exports.AddHandler = AddHandler;

/**
 * Run the handler for the wizard.
 * @param {*} wizardID The id of the wizard.
 * @param {*} step The step number of the wizard.
 * @param {*} StateOfTheWizard The state of the wizard (or document from Save()).
 */
async function RunHandler(wizardID, step, StateOfTheWizard) {


  for (var i = 0; i < handlerz.length; i++) {
    var handler = handlerz[i];

    if ((!(handler.wizard)) && (!(handler.step))) {
      //handler is a wildcard
    } else if ((!(handler.wizard)) && handler.step == step) {
      //handler is a wild card
    } else if (handler.wizard == wizardID && handler.step == step) {
      //handler is a specific wizard and step
    } else {
      //handler is not for this wizard and step
      continue;
    }


    await handler.handler(StateOfTheWizard);

  }

} 

try {
 window.wizard = {
    AddHandler: AddHandler
  };
} catch (error) {
  console.error("No window to attach the wizard handler to.");
}