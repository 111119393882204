/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Recursively converts short hand object name to complete object name.
 * 
 */

var namespace = "shuffleArray";
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // Generate a random index lower than the current element index
        const j = Math.floor(Math.random() * (i + 1));
        
        // Swap the elements at indexes i and j
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: shuffleArray,
    tests: [] 
};