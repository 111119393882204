/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for checking if a property is in an object and it is a specfied type.
 * 
 */

var namespace = "validate";
/**
 * Validates a data object.
 * 
 * For strings (and only strings) it will make sure the string is not empty.
 * Supported checks:
 * - string, number, boolean, object, array, function, promise, throwable
 * 
 * @param {*} search The object to search in.
 * @param {*} key The key to search for.
 * @param {*} type The type to search for.
 * @returns {boolean} true if the key is in the object and is the correct type.
 */
function validate(search, key, type = "string") {

    if (key in search) {
        switch (type) {
            case "string":

                if (search[key] === null) {
                    return false;
                    //or it equals "null"

                } else if (search[key].trim() === "null") {
                    return false;
                }

                return typeof search[key] === "string" && search[key].length > 0;

            case "number":
                return typeof search[key] === "number";

            case "boolean":
                return typeof search[key] === "boolean";

            case "object":
                // Ensure the value is an object and not null (since typeof null === "object")
                return typeof search[key] === "object" && search[key] !== null;

            case "array":
                return Array.isArray(search[key]);

            case "function":
                return typeof search[key] === "function";

            case "promise":
                // Checking for Promise using instanceof
                return search[key] instanceof Promise;

            // case "throwable":
            //     // Checking if it's an instance of Error
            //     return search[key] instanceof Error;

            default:
                return false;
        }
    }

    return false;
}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: validate,
    tests: [
        //make individual tests
        {
            namespace: `${namespace}.string`,
            must: true,
            run: async () => {
                var search = {
                    "string": "string"
                };
                var key = "string";
                var type = "string";
                $rtn = validate(search, key, type);
                return $rtn;
            }
        
    },{
        namespace: `${namespace}.stringNull`,
        must: false,
        run: async () => {
            var search = {
                "string": null
            };
            var key = "string";
            var type = "string";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    
},{
    namespace: `${namespace}.stringNullString`,
    must: false,
    run: async () => {
        var search = {
            "string": "null"
        };
        var key = "string";
        var type = "string";
        $rtn = validate(search, key, type);
        return $rtn;
    }

}, {
        namespace: `${namespace}.number`,
        must: true,
        run: async () => {
            var search = {
                "number": 1
            };
            var key = "number";
            var type = "number";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.boolean`,
        must: true,
        run: async () => {
            var search = {
                "boolean": true
            };
            var key = "boolean";
            var type = "boolean";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.object`,
        must: true,
        run: async () => {
            var search = {
                "object": {}
            };
            var key = "object";
            var type = "object";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.array`,
        must: true,
        run: async () => {
            var search = {
                "array": []
            };
            var key = "array";
            var type = "array";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.function`,
        must: true,
        run: async () => {
            var search = {
                "function": function () {}
            };
            var key = "function";
            var type = "function";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }, {
        namespace: `${namespace}.promise`,
        must: true,
        run: async () => {
            var search = {
                "promise": new Promise((resolve, reject) => {})
            };
            var key = "promise";
            var type = "promise";
            $rtn = validate(search, key, type);
            return $rtn;
        }
    }]
};