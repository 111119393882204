var _ = require("./module.js")(module, {
    namespace: "universe.payments.tabulate",
    title: "Tabulate",
    description: "Tabulates the order.",
    errors: {
        "invalidon": {
            title: "Invalid On",
            description: `The "on" property provided doesn't match any known discount type.`
        },
        "paylaterislessthanzero": {
            title: "Pay Later is Less Than Zero",
            description: "The total to pay later is negative. This is a serious issue. No reoccuring subscription should be less than 0. No payment to be made by the customer should be less than 0."
        },
        "invalidlineitems": {
            title: "Invalid Line Items",
            description: "The line items are invalid."
        }
    }
})

var describeAccountingDate = require("./functions/describeAccountingDate.js").function;

var exampleOrder = 
    {
        "type": "monthly",
        "profile": {
            "id": 518,
            "email": "states_splint_0t@icloud.com",
            "phone": "+16143789801",
            "roles": "",
            "address": {
                "city": "Columbus",
                "line1": "5365 Great Oak Way",
                "line2": "Apt E.",
                "state": "Ohio",
                "country": "United States",
                "postalcode": "43213"
            },
            "company": "",
            "donottxt": 0,
            "lastname": "Kazmierczak",
            "createdon": "2024-07-20T12:16:00.000Z",
            "firstname": "Justin (Test Account)",
            "sessionid": "fe26d7d6-a066-43ae-b132-8d19ffe122a0",
            "dateofbirth": "1990-04-07T00:00:00.000Z",
            "stripecustomer": ""
        },
        "techfee": true,
        "lineItems": [
            {
                "cost": 19.99,
                "name": "Vehicle Type: Small SUV/Pickup; Unlimited Vacuum and Emergency Clean Up"
            },
            {
                "tax": "7.5%",
                "name": "Tax"
            }
        ]
    
};

var conformCurrencStandard = require("./functions/conformCurrencyStandard.js").function;

function checkout(_checkout) {
    var checkout = {..._checkout};
    checkout.warnings = checkout.warnings || [];

    //check each warning for a .type = "tabulate", if so remove it
    checkout.warnings = checkout.warnings.filter(function (warning) {
        return warning.type != "tabulate";
    });

    //is there a warning in checkout, if not add a blank array

    var lines = checkout.lineItems;

    if (!(lines)) {
        lines = checkout.lineitems;
    } 

    if (!(lines)) {
        _.errors.invalidlineitems.throw(checkout);
    }

    var total = 0;
    var subtotal = 0;

    //actual cost
    for (var i = 0; i < lines.length; i++) {


        if ("cost" in lines[i]) {
            total += parseFloat(lines[i].cost);
            lines[i].cost = conformCurrencStandard(lines[i].cost);
            lines[i].tabulated = true;
        }

        //is the line item a tech fee, if it is remove it
        if ("techfee" in lines[i]) {
            lines[i] = null;
        }

    }

    //remove all blank lines
    lines = lines.filter(function (line) {
        return line != null;
    });

    //console.log("Total", total);

    var discountOn = {
        now: false,
        later: false
    }

    //let's process line items that are discounts
    for (var i = 0; i < lines.length; i++) {
        var line = lines[i];
        if ("discount" in line) {

            var discount = parseFloat(line.discount.replace("%", ""));

            if ("applyon" in line) {
                
                if (!(line.applyon in discountOn)) {
                    _.errors.invalidon.throw({
                        line
                    });
                }
                
                if (!(discountOn[line.applyon])) {
                    discountOn[line.applyon] = discount
                } else {
                    discountOn += discount;
                }

            } else {

                line.cost = total * (discount / 100);
                total -= line.cost;
                line.cost = conformCurrencStandard(line.cost);
            
            }

            line.tabulated = true;

        }
    }

    //is the app owner passing down the universe fee? techfee
    if (checkout.techfee) {
        var currentTechFee = "2%";
        
        var line = {
            name: "Technology Fee",
            cost: null,
            fee: "2%",
            tabulated: true,
            techfee: true
        };

        var techFee = parseFloat(currentTechFee.replace("%", ""));
        line.cost = total * (techFee / 100);
        total += line.cost;
        line.cost = conformCurrencStandard(line.cost);
        line.tabulated = true;
        lines.push(line);

    }

    subtotal = subtotal + total;

    //subtotal is before tax calculation
    // total = parseFloat(total);

    //tax calculation
    for (var i = 0; i < lines.length; i++) {

        var line = lines[i];
        if (line.tabulated) {
            continue;
        }

        if ("tax" in line) {
            // //console.log("Converting Tax", line.tax);
            var tax = parseFloat(line.tax.replace("%", ""));
            // //console.log("result of conversion", tax);
            line.cost = total * (tax / 100);
            total += line.cost;
            // //console.log("Tax", line.cost, total * (tax / 100), parseFloat(total)* (tax / 100));
            line.cost = conformCurrencStandard(line.cost);
           
            line.tabulated = true;
        }

    }

    //console.log("Total After Tax", total);

    // //round all lines to the nearest cent
    // for (var i = 0; i < lines.length; i++) {
    //     lines[i].cost = Math.round(lines[i].cost * 100) / 100;
    //     lines[i].rounded = true;
    // }

    subtotal = conformCurrencStandard(subtotal);
    total = conformCurrencStandard(total);

    //sort the lines
    //sort so taxes are last, any line item with a tax property is a tax not all line items have a tax property
    lines.sort(function (a, b) {
        if (a.tax) {
            return 1;
        }
        if (b.tax) {
            return -1;
        }
        return 0;
    });


    var check = {
        total: total,
        subtotal: subtotal,
        lines: lines,
        payOn: {

        },
        expires: new Date(new Date())
    };

    //get now or later amounts
    // switch (order.checkout.type) {
    //     case "weekly":
    //     case "monthly":
    //     case "yearly":
    //         vitalInfo = "After today, your subscription will renew " + order.checkout.type + " on or after " + describeAccountingDate(tabulation.expires) + ".";
    //         // element.i.push(infoAlert);
    //     break;
    //     case "now":
    //     break;
    //     case "later":
    //         vitalInfo = "You're agreeing to pay the remaining balance later, for example, after a scheduled appointment or other arrangement.";
    //         // element.i.push(infoAlert);
    //     break;
    // }

    var vitalInfo = null;

    switch (checkout.type) {
        case "weekly":
            //advance the expiration date by 7 days
            check.expires.setDate(check.expires.getDate() + 7);
        case "yearly":
            //advance the expiration date 1 year
            check.expires.setFullYear(check.expires.getFullYear() + 1);
        case "monthly":
            //advance the expiration date by 1 month
            check.expires.setMonth(check.expires.getMonth() + 1);
            check.payOn.now = total;
            check.payOn.later = total;

            vitalInfo = "After today, your subscription will renew " + checkout.type + " on or after " + describeAccountingDate(check.expires) + ".";

        break;
        case "now":
            check.payOn.now = total;
            // vitalInfo += ""
        break;
        case "later":
            check.payOn.now = 0;
            check.payOn.later = total;
            vitalInfo = "You're agreeing to pay the remaining balance later, for example, after a scheduled appointment or other arrangement."
        break;
    }

    //verify the discountOn is nto greater than 100%
    if (discountOn.now > 100) {
        discountOn.now = 100;
        checkout.warnings.push({
            type: "tabulate",
            message: "The total discount applied to pay now is greater than 100%. Defaulting to 100%."
        });
    }

    if (discountOn.later > 100) {
        discountOn.later = 100;
        checkout.warnings.push({
            type: "tabulate",
            message: "The total discount applied to pay later is greater than 100%. Defaulting to 100%."
        });
    }

    //apply the discountOn
    if (discountOn.now) {
        var curCheckPayOn = check.payOn.now + 0;
        check.payOn.now = total - (total * (discountOn.now / 100));
        check.total = check.payOn.now + 0;

        //find and update the lines with a discount that applies to now
        for (var i = 0; i < lines.length; i++) {
            if ("discount" in lines[i]) {
                if (lines[i].applyon == "now") {
                    //get the discount amount and apply it to the line
                    var discount = parseFloat(lines[i].discount.replace("%", ""));
                    lines[i].cost = (curCheckPayOn * (discount / 100)) * -1;
                    console.log("Discount", discount, lines[i].cost);
                }
            }
        }

    }

    if (discountOn.later) {
        check.payOn.later = total - (total * (discountOn.later / 100));

        //find and update the lines with a discount that applies to later
        for (var i = 0; i < lines.length; i++) {
            if ("discount" in lines[i]) {
                if (lines[i].applyon == "later") {
                    //get the discount amount and apply it to the line
                    var discount = parseFloat(lines[i].discount.replace("%", ""));
                    lines[i].cost = (check.payOn.later * (discount / 100)) * -1;
                    console.log("Discount", discount, lines[i].cost);
                }
            }
        }
    }

    //confirm the totals (pay.now) and (pay.later) are all positive or zero
    if (check.payOn.now < 0) {
        checkout.warnings.push({
            type: "tabulate",
            message: "The total to pay now is negative. Nothing can be paid by the customer. An attempt will fail."
        });
    }

    if (check.payOn.later < 0) {
        checkout.warnings.push({
            type: "tabulate",
            message: "The total to pay later is negative. Nothing can be paid by the customer. An attempt will fail."
        });
        _.errors.paylaterislessthanzero.throw({
            checkout
        });
    }

    check.vitalInfo = vitalInfo;
    return check;

} module.exports.checkout = checkout;

checkout(exampleOrder);