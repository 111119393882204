/*!
 * Protected by The Universe Terms of Service and Privacy Policy.
 * Subject to the Terms of Service and Privacy Policy.
 * 
 * Justin K Kazmierczak
 * © 2023. The Universe & Justin K Kazmierczak. All rights reserved.
 * 
 * Copy and distribution of this code is strictly prohibited.
 */

var namespace = "ua.a.count";
exports.namespace = namespace;

var uae = require("../elements/templates/ua.element.js");
// var uai = require("../interface/interface.js");
var events = require("../../../uam/events.js")

// var jsonRender = require("../interface/jsonRender.js");

var required = ["inner"];
// exports.required = required;

var define = {
  namespace: namespace,
  title: "Universe Animations Count Up",
  description: "Counts an item up from a number",
  supportServer: false,
  fields: {
    "inner": {
        type: "inner",
        description: "The number to start counting from"
    }, "lastnumber": {
        type: "number",
        description: "The number to count to."
    }, "step": {
        type: "number",
        description: "The amount of time (in miliseconds) between each count.",
        default: 1000
    },
      "restart": {
        type: "boolean",
        description: "Should the count restart when it reaches the last number?",
        default: true
    }
  },
  required: required
}; exports.define = define;

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Called by the interface to render the animation
 * @param {*} json The json to render the type on animation on.
 */
exports.render = function (json) {

    var options = uae.GetOptions(json, required);

    //check that options.inner is a number
    if (isNaN(options.inner)) {
      log.warn(`${namespace}: The inner value must be a number.`, {
        inner: options.inner,
        json: json
      }); 
    }

    var ele = document.createElement("span");
    ele.setAttribute("ua-anim", namespace);

    //do I have a range
    if ("lastnumber" in options) {
      ele.setAttribute("ua-range", options.inner + "-" + options.lastnumber);
    }

    //do I have a step
    if ("step" in options) {
      ele.setAttribute("ua-step", options.step);
    }

    //if there is no restart add it
    if (!("restart" in options)) {
      ele.setAttribute("ua-restart", true);
    } else {
      ele.setAttribute("ua-restart", options.restart);
    }

    ele.innerHTML = options.inner;

    return ele;

};

  // Set up the IntersectionObserver
  const observer = new IntersectionObserver(handleCount, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5, // Adjust this threshold as needed
  });

events.on("interface.oninteraction", async () => {
      
  // Get the elements you want to observe
  const elementsToObserve = document.querySelectorAll(`[ua-anim="${namespace}"]`);
  
  // Observe each element
  elementsToObserve.forEach((element) => {
    observer.observe(element);
  });

});

// await events.run("interface.oninteraction");

// var curInterval = null;

/**
 * Set's up the counter when the item is in view
 * @param {*} entries 
 * @param {*} observer 
 */
function handleCount(entries, observer) {
    entries.forEach((entry) => {
    //     console.log("handleFade", {
    //         entry: entry
    // });
        if (entry.isIntersecting) {
           
          entry.target.setAttribute("ua-isintersecting", true);

          var step = 1000;
          if (entry.target.getAttribute("ua-step")) {
            step = entry.target.getAttribute("ua-step");
          }

          curInterval = setInterval(() => {

            if (entry.target.getAttribute("ua-isintersecting")) {

              entry.target.innerHTML = parseInt(entry.target.innerHTML) + 1;

              var range = entry.target.getAttribute("ua-range");
              // console.log("range", range);
              if (!range) {
              } else {
                range = range.split("-");
                curNum = parseInt(entry.target.innerHTML);

                //equal to or greater than the last number
                if (curNum > range[1]) {
                  if (entry.target.getAttribute("ua-restart") == "true") {
                    //reset it
                    entry.target.innerHTML = range[0];
                  } else {
                    //end the interval
                    clearInterval(curInterval);
                  }
                }
              }

            } else {
              // console.log("welp");
            }

          }, step);


        } else {
            entry.target.setAttribute("ua-isintersecting", false);
        }

    });
  }