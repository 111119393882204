var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "checkout.preview",
    title: "Checkout Preview",
    description: "Previews a Payments by The Universe checkout object to the user.",
    editable: true,
    fields: {
        "checkout": {
            type: "object",
            description: "The checkout object.",
            required: true
        },
        "inner": {
            type: "inner",
            description: "The content to show after the checkout preview, in the checkout toolbox."
        },
        "showHeader": {
            type: "boolean",
            description: "Show the default checkout header.",
            default: false,
        },
        "alternateclass": {
            type: "class",
            description: "The class to alternate the line items.",
            default: "bg-blur-dark"
        }
    },
    errors: {
        "invalidlineitems": {
            title: "Invalid Line Items",
            description: "The line items are invalid."
        }
    },
    passthrough: {
        except: ["inner", "overlay", "overlayClass", "interval"]
    }
});

var tabulate = require("../../../uam/tabulate.js");
var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

// var events = require("../../../uam/events.js");
// var sleep = require("../../../uam/functions/sleep.js").function;

/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    var checkout = options.checkout;
    var tabulatedCheckout = tabulate.checkout(checkout);

    var headerz = "";

    if (options.showHeader) {
        headerz = {
            "n": "ua.header",
            "icon": "bi bi-bag-check",
            "title": "Checkout",
        }
    }

    var element = {
        "n": "div",
        "c": "",
        "i": [headerz]
    }

    var nowOrLater =  {
        n: "div",
        c: "row fs-1",
        i: []
    }

    // console.log()

    if ("now" in tabulatedCheckout.payOn) {
        nowOrLater.i.push({
            n: "div",
            c: "col-12 col-lg-6",
            i: "Today: $ " + tabulatedCheckout.payOn.now
        });
    }

    if ("later" in tabulatedCheckout.payOn) {
        nowOrLater.i.push({
            n: "div",
            c: "col-12 d-md-none",
            i: "Later: $ " + tabulatedCheckout.payOn.later
        });

        nowOrLater.i.push({
            n: "div",
            c: "col-12 col-lg-6 d-none d-md-block text-end",
            i: "Later: $ " + tabulatedCheckout.payOn.later
        });
    }

    element.i.push(nowOrLater);

    //for each line item
    for (var i = 0; i < tabulatedCheckout.lines.length; i++) {
        var line = tabulatedCheckout.lines[i];
        console.log({
            line
        });

        var name = line.name;

        // if (line.cost == 0) {
        //     continue;
        // }

        //if there is a fee or tax add the amount to the name
        if ("fee" in line) {
            name += " (" + line.fee + ")";
        }

        if ("tax" in line) {
            name += " (" + line.tax + ")";
        }

        if ("discount" in line) {
            name += " (" + line.discount + ")";
        }

        var lineElement = {
            n: "div",
            c: "row p-3 rounded",
            i: [{
                n: "div",
                c: "col-8",
                i: name
            }, {
                n: "div",
                c: "col-4 text-end",
                i: "$ " + line.cost
            }]
        }

        //is their a descrioption
        if ("description" in line) {
            lineElement.i.push({
                n: "div",
                c: "col-12 text-info",
                i: line.description
            });
        }

        var lInfo = [];

        if ("applyon" in line) {

            switch (line.applyon) {
                case "now":
                    lInfo.push("Apples to the amount you'll pay today.");
                    break;
                case "later":
                    lInfo.push("Apples to the amount you'll pay later (such as a reoccuring payment).");
                    break;
                default:
                    lInfo.push("Does not apply to any specific payment event.");
                    break;
            }

            // if ("now" in line.applyon) {
            //     lInfo.push("Apples to the amount you'll pay today.");
            // }

            // if ("later" in line.applyon) {
            //     lInfo.push("Apples to the amount you'll pay later (such as a reoccuring payment).");
            // }
        }

        if ("on" in line) {
            lInfo.push("Added on " + describeAccountingDate(line.on) + ".");
        }

        if ("promocode" in line) {
            lInfo.push("Promocode: " + line.promocode);
        }

        if ("code" in line) {
            lInfo.push("Code: " + line.code);
        }

        if (lInfo.length > 0) {
            lineElement.i.push({
                n: "div",
                c: "col-12 text-info ua-checkout-line-info",
                i: {
                    n: "ul",
                    i: lInfo.map(function (li) {
                        return {
                            n: "li",
                            i: li
                        }
                    })
                }
            });
        }

        //is the everyother one
        if (i % 2 == 0) {
            lineElement.c += " " + options.alternateclass;
        }

        element.i.push(lineElement);
    }

    // // add total
    // element.i.push({
    //     n: "div",
    //     c: "row",
    //     i: [{
    //         n: "div",
    //         c: "col-12 fs-3 text-end rounded",
    //         i: "$ " + tabulatedCheckout.total
    //     }]
    // });

    // readd the total

    //if their are more than 10 items
    if (tabulatedCheckout.lines.length > 10) {
        element.i.push(nowOrLater);
    }

    // console.log("Vehicle Render", options);

    var infoAlert = {
        n: "div",
        c: "bg-info text-white p-3 rounded m-3",
        i: ""
    };

    // var expir

    switch (checkout.type) {
        case "weekly":
        case "monthly":
        case "yearly":
            infoAlert.i = "After today, your subscription will renew " + checkout.type + " on or after " + describeAccountingDate(tabulatedCheckout.expires) + ".";
            element.i.push(infoAlert);
        break;
        case "now":
        break;
        case "later":
            infoAlert.i = "You're agreeing to pay the remaining balance later according to the terms and conditions of your purchase.";
            element.i.push(infoAlert);
        break;
    }

    // var legalCheckbox =  {
    //     "n": "ua.checkbox",
    //     "name": "legalagr",
    //     "title": ["You agree to be bound by ", {
    //         "n": "a",
    //         "href": "https://egtuniverse.com/legal",
    //         "i": "The Universe Terms of Service"
    //     }, ". Refunds are at the discretion of the company you are buying from. For confirm now but pay later, prices may change depending on varying factors. The company you buy from will inform you of any changes to your charges. It is industry standard not to offer prorated or refunds for recurring items. Be sure you've read and agreed to any terms or conditions from the company you're buying from before finalizing your purchase."],
    //     "checked": false,
    //     "required": true,
    //     "class": "pt-3 p-2 m-3"
    //   };

    legalCheckbox = {
        "n": "p",
        "c": "p-3",
        "i": ["You agree to be bound by ", {
            "n": "a",
            "href": "https://egtuniverse.com/legal",
            "target": "_blank",
            "i": "The Universe Terms of Service"
        }, ". Refunds are at the discretion of the app you are buying from. For confirm now but pay later, prices may change depending on varying factors. The app you buy from will inform you of any changes to your charges. It is industry standard not to offer prorated or refunds for recurring items. Be sure you've read, understand, and agreed to any terms or conditions from the app you're buying from before finalizing your purchase."]}

    // element.i.push(legalCheckbox);

    if ("inner" in options) {
        var pauNowToolbar = {
            n: "div",
            c: "row p-3",
            i: options.inner
        };

        element.i.push(pauNowToolbar);
    }


    //add a button to pay
    // var pauNowToolbar = {
    //     n: "div",
    //     c: "row p-3",
    //     i: [{
    //         n: "div",
    //         c: "col-6"
    //     }, {
    //         n: "div",
    //         c: "col-6 text-end",
    //         i: {
    //             n: "button",
    //             i: [{
    //                 n: "i",
    //                 c: "bi bi-wallet2"
    //             }, " Complete Purchase"],
    //             c: "btn btn-success compurchase"
    //         }
    //     }]
    // };

    // element.i.push(pauNowToolbar);

    return element;

}