/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
{
  "n": "pages.page",
  "title": "LUXE LEVELS - NAVY SILK DRESS BY AUR2230200158",
  "useBackgroundImage": false,
  "on": {
    "published": "20231114101740",
    "processed": "20240416183445",
    "describePublished": "November 14, 2023"
  },
  "url": "http://localhost:8517/shop/items/navy-silk-dress-by-aur2230200158.html",
  "i": {
    "product": {
      "id": "gid://shopify/Product/8553279127792",
      "title": "NAVY SILK DRESS BY AUR2230200158",
      "handle": "navy-silk-dress-by-aur2230200158",
      "description": "",
      "descriptionHtml": "",
      "productType": "Women's Dresses",
      "vendor": "AMANDA UPRICHARD",
      "tags": [
        "women clothing",
        "women's dresses"
      ],
      "collections": [
        "Women's Clothing",
        "Women's Dresses"
      ],
      "media": {
        "images": [
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00438.jpg?v=1712271502",
            "altText": "",
            "position": 0
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00425.jpg?v=1712271502",
            "altText": "",
            "position": 1
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00428.jpg?v=1712271502",
            "altText": "",
            "position": 2
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00431.jpg?v=1712271502",
            "altText": "",
            "position": 3
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00441.jpg?v=1712271478",
            "altText": "",
            "position": 4
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00465.jpg?v=1712271478",
            "altText": "",
            "position": 5
          }
        ],
        "videos": [
          {
            "src": null,
            "altText": "",
            "position": 6,
            "sources": [
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.HD-1080p-7.2Mbps-26899201.mp4",
                "mimeType": "video/mp4"
              },
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.m3u8",
                "mimeType": "application/x-mpegURL"
              },
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.HD-720p-4.5Mbps-26899201.mp4",
                "mimeType": "video/mp4"
              },
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.SD-480p-1.5Mbps-26899201.mp4",
                "mimeType": "video/mp4"
              }
            ],
            "poster": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/preview_images/20240313-DSC00438.jpg?v=1712589214"
          }
        ],
        "models": [],
        "externalVideos": []
      },
      "variants": [
        {
          "id": "gid://shopify/ProductVariant/45299961856240",
          "title": "NAVY / XS",
          "sku": "AUR2230200158XS",
          "availableForSale": true,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY"
            },
            {
              "name": "Size",
              "value": "XS"
            }
          ]
        },
        {
          "id": "gid://shopify/ProductVariant/45299961889008",
          "title": "NAVY / S",
          "sku": "AUR2230200158S",
          "availableForSale": false,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY"
            },
            {
              "name": "Size",
              "value": "S"
            }
          ]
        },
        {
          "id": "gid://shopify/ProductVariant/45299961921776",
          "title": "NAVY / M",
          "sku": "AUR2230200158M",
          "availableForSale": false,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY"
            },
            {
              "name": "Size",
              "value": "M"
            }
          ]
        },
        {
          "id": "gid://shopify/ProductVariant/45299961954544",
          "title": "NAVY / L",
          "sku": "AUR2230200158L",
          "availableForSale": true,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY"
            },
            {
              "name": "Size",
              "value": "L"
            }
          ]
        }
      ],
      "optionsByType": {
        "Color": [
          "NAVY"
        ],
        "Size": [
          "XS",
          "S",
          "M",
          "L"
        ]
      }
    },
    "namespace": "luxe.product"
  },
  "image": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00438.jpg?v=1712271502"
}
*/

var jsonRender = require("../../uat/src/interface/jsonRender.js");
var interface = require("../../uat/src/interface/interface.js");
var conformPrice = require("../../uam/functions/conformCurrencyEcommerce.js").function;

var define = {
    namespace: "luxe.product.preview",
    title: "Luxe Leveles Product Preview",
    description: "Display's a product as a result of a search.",
    fields: {
        product: {
            type: "object",
            description: "The simplified content of the product as provided by shopify (without the edges).",
            addtouae: true
        },
        productid: {
            type: "string",
            description: "The id of the product. Can be used in lue of product infromation.",
            addtouae: true
        },
        type: {
            type: "string",
            items: [
                "grid",
                "list"
            ],
            description: "The type of display to output"
        }
    }//,
    // supportServer: false
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
async function render(options) {


    var previewid = interface.generateRandomID(36);

   /**
    * The main container for the product.
    */
   var product = options.product;

   var firstImage = false;

   //is their a image
    if (product.media.images) {
        if (product.media.images.length > 0) {
            firstImage = product.media.images[0];
       }
    }

    // console.log("First Image", firstImage);

   var mainPreview = {};

   //is their a product video?
    // if (product.media.videos && product.media.videos.length > 0) {
        // var vid = product.media.videos[0];
        // mainPreview = await CreateVideoPlayer(vid);

    // } else
     if (firstImage) {
        mainPreview = {
            n: "img",
            src: firstImage.src,
            alt: product.title,
            class: "img-fluid"
        };
    } else {
        mainPreview = {
            n: "div",
            c: "bg-secondary",
            i: "This product is a mystery."
        };
    }

    //overide video with image for preview
    mainPreview = {
      n: "img",
      src: firstImage.src,
      alt: product.title,
      class: "img-fluid"
  };

     

  var mainClass = "col-6 col-md-3 d-inline-block";
  var imageClass = "col-12";
  var textClass = "col-12";

  // if ("type" in options) {
  //     if (options.type == "list") {
  //       mainClass = "col-12";
  //       imageClass = "col-4";
  //       textClass = "col-8 text-center";
  //     }
  // }

  var productVendor = {
    "n": "p",
    "c": "p-0 m-0 designer-name",
    "data-goto": "/product/" + product.handle,
    i: product.vendor 
  };

  if (product.vendor == "Luxe Levels") {
    productVendor = {
      "n": "p",
      "c": "p-0 m-0 designer-name",
      "data-goto": "/product/" + product.handle,
      i: "&nbsp;"
    };
  }

  var priceing = product.startingPrice;

  //if I have the tag "the-outlet" then I want to show orginal price slashed through and the new price of 30% off next to it
  if (product.tags.includes("The Outlet")) {

    var discountPricing = product.startingPrice.replace("$", "");
    discountPricing = parseFloat(discountPricing);
    discountPricing = discountPricing - (discountPricing * .30);
    discountPricing = conformPrice(discountPricing);

    priceing = {
      n: "span",
      c: "on-sale-pricing",
      i: [{
        n: "span",
        c: "p-0 m-0 text-decoration-line-through sale-old-price",
        i: product.startingPrice
      }, "&nbsp;", {
        n: "span",
        c: "p-0 m-0 sale-new-price",
        i: discountPricing
      }] 
    };
  }


   var ele = [{
    "n": "product",
    "class": mainClass + " p-1 bg-og-75-secondary-hover rounded", // pt-3 pb-3",
    "data-product-id": product.id,
    "data-goto": "/product/" + product.handle,
    "data": JSON.stringify(product),
    "i":
        [
        {
            "n": "div",
            "data-goto": "/product/" + product.handle,
            "c": imageClass + " p-0 shop-product-preview-image",
            i: [{
                n: "div",
                c: "fill-area blur",
                "data-goto": "/product/" + product.handle,
                s: "background-image: url(" + firstImage.src + ");"
            }, {
              n: "div",
              c: "fill-area-holster",
              "data-goto": "/product/" + product.handle,
              s: "background-image: url(" + firstImage.src + ");",
              i: ""
            }]
            // border-2 border-bottom border-primary",
            // "s": "width:100%; background-image: url(" + firstImage.src + ");",
        },
        {
          "n": "div",
          "data-goto": "/product/" + product.handle,
        
          "c": textClass + " shop-text-preview",
          "i": [productVendor, {
            "n": "p",
            "c": "luxe-product-preview-title",
            "data-goto": "/product/" + product.handle,
            i: product.title
          }, {
            "n": "p",
            "c": "luxe-product-preview-price",
            "data-goto": "/product/" + product.handle,
            i: priceing
          }, {
            n: "a",
            c: "btn button border-0 btn-text-black bg-black bg-100-white-hover text-white text-black-hover d-none",
            href: `../../../../../../../../product/${product.handle}.html#add-to-card`,
            i: [{ 
                n: "i",
                c: "bi bi-cart-check",
                i: "&nbsp;"
            }, "Add to Cart"]
        }]
      }]
    }];

    if (options.type == "list") {
      ele = {
        n: "div",
        c: "row",
        i: [{
          n: "div",
          c: "col-4 col-md-4",
          i: {
            "n": "div",
            "data-goto": "/product/" + product.handle,
            "c": imageClass + " p-0 shop-product-preview-image-list",
            i: [{
                n: "div",
                c: "fill-area blur",
                s: "background-image: url(" + firstImage.src + ");"
            }, {
              n: "div",
              c: "fill-area-holster",
              s: "background-image: url(" + firstImage.src + ");",
              i: ""
            }]
            // border-2 border-bottom border-primary",
            // "s": "width:100%; background-image: url(" + firstImage.src + ");",
          }
        }, {
          n: "div",
          c: "col-8 col-md-8",
          i: [{
            "n": "h3",
            "c": "fs-5",
            i: product.vendor
          }, {
            n: "p",
            c: "fs-6",
            i: product.title 
          }]
        }]
      }


      //if inner add it after title
      if (options.inner) {
        ele.i[1].i.push(options.inner);
      }

    }

    // console.log("The Luxe Product Preview Rendered: ", ele);

    return ele;

} module.exports.render = render;
