/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.accordion";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Reveal Collapsable Information",
  description: "Uses bootstrap's accordion to provide a collapsable section of information, like a frequently asked questions list.",
  fields: {
    "items": {
      type: "list", 
      properties: {
        "header":  {
          type: "list",
          description: "The header of the item.",
          properties: {
            "inner": {
              type: "inner",
              description: "The content of the header."
            }, 
            "class": {
              type: "class",
              description: "The classes of the header."
            }, 
            "style": {
              type: "style",
              description: "The style attributes of the header."
            }
          }
        },
        "inner": {
          type: "inner",
          description: "The content of the item."
        }, 
        "class": {
          type: "class",
          description: "The classes of the item."
        }, 
        "style": {
          type: "style",
          description: "The style attributes of the item."
        }, 
        "titleClass": {
          type: "class",
          description: "The classes of the title."
        },
        "titleStyle": {
          type: "style",
          description: "The style attributes of the title."
        }
      },
      description: "The inner items of the collapsable."
    },
    "show-first": {
      type: "boolean",
      description: "If true, the first item will be shown by default."
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = json;
  var accordionId = uai.generateRandomID(8);
  
  var accordion = {
    namespace: "div",
    class: "accordion accordion-flush",
    id: accordionId,
    inner: []
  };

  for (var i = 0; i < options.items.length; i++) {
    
    var item = options.items[i];
    var contextId = uai.generateRandomID(8);
    var headerID = uai.generateRandomID(8);

    var header = {
      namespace: "h2",
      class: "accordion-header",
      id: headerID,
      inner: [
        {
          namespace: "button",
          class: "accordion-button collapsed ",// + item.titleClass,
          // style: item.titleStyle,
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#" + contextId,
          "aria-expanded": "false",
          "aria-controls": contextId,
          inner: item.header.inner
        }
      ]
    };

    if ("class" in item.header) {
      header.inner[0].class += " " + item.header.class;
    }

    if ("style" in item.header) {
      header.inner[0].style = item.header.style;
    }

    var context = {
      namespace: "div",
      class: "accordion-collapse collapse",
      id: contextId,
      "aria-labelledby": headerID,
      "data-bs-parent": "#" + accordionId,
      inner: [
        {
          namespace: "div",
          class: "accordion-body",
          inner: item.inner
        }
      ]
    };

    var accordionItem = {
      namespace: "div",
      class: "accordion-item",
      inner: [
        header,
        context
      ]
    };

    if ("class" in item) {
      accordionItem.class += " " + item.class;
    }

    if ("style" in item) {
      accordionItem.style = item.style;
    }

    if ("show-first" in options) {
      if (options["show-first"] && i == 0) {
        accordionItem.inner[1].class += " show";

        //remove the collapsed class from the button
        header.inner[0].class = header.inner[0].class.replace("collapsed", "");

      }
    }

    accordion.inner.push(accordionItem);

  }

  // console.log("Accordion: ", accordion);

  return await jsonRender.convertJSONToDOM(accordion, {
    PassthroughOptions: options
  });

}

/** 
 * 
 * 
 * 
 * 

<div class="accordion accordion-flush" id="{accordionId}">
  <div class="accordion-item">
    <h2 class="accordion-header" id="{headerID}">
      <button class="accordion-button collapsed {item.header.class}" style="{item.header.style}" type="button" data-bs-toggle="collapse" data-bs-target="#{contextId}" aria-expanded="false" aria-controls="{contextId}">
        {item.header.inner}
      </button>
    </h2>
    <div id="{contextId}" class="accordion-collapse collapse {item.class}" style="{item.style}" aria-labelledby="{headerID}" data-bs-parent="#{accordionId}">
      <div class="accordion-body">{item.inner}</div>
    </div>
  </div>
  
  ...

</div>


 * 
*/


var output = {
  n: 'div',
  c: 'accordion',
  id: 'gUKCFyrB',
  i: [
    {
      n: 'div',
      c: 'card',
      id: 'npbzdmZx',
      i: [
        {
          n: 'div',
          c: 'card-header',
          i: [
            {
              n: 'h5',
              c: 'mb-0',
              i: [
                {
                  n: 'button',
                  c: 'btn btn-link',
                  type: 'button',
                  'data-toggle': 'collapse',
                  'data-target': '#lrdqXGQX',
                  'aria-expanded': 'true',
                  'aria-controls': 'lrdqXGQX',
                  i: [ 'What Can I Expect When I Visit?' ]
                }
              ]
            }
          ]
        },
        {
          n: 'div',
          c: 'collapse',
          id: 'lrdqXGQX',
          'aria-labelledby': 'npbzdmZx',
          'data-parent': '#gUKCFyrB',
          i: [
            {
              n: 'div',
              c: 'card-body',
              i: [
                {
                  namespace: 'p',
                  class: 'p-3',
                  inner: 'The environment at The Family is a warm, friendly, and welcoming one. You will feel at home while experiencing an uplifting, engaging, and dynamic service. In addition, we will not put you on the spot by asking you to stand or speak. During the service, you can relax and enjoy uplifting, contemporary music followed by a practical and powerful message.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      n: 'div',
      c: 'card',
      id: 'Mk7uX91D',
      i: [
        {
          n: 'div',
          c: 'card-header',
          i: [
            {
              n: 'h5',
              c: 'mb-0',
              i: [
                {
                  n: 'button',
                  c: 'btn btn-link',
                  type: 'button',
                  'data-toggle': 'collapse',
                  'data-target': '#hF1_xgST',
                  'aria-expanded': 'true',
                  'aria-controls': 'hF1_xgST',
                  i: [ 'Do You Have Separate Services For Children?' ]
                }
              ]
            }
          ]
        },
        {
          n: 'div',
          c: 'collapse',
          id: 'hF1_xgST',
          'aria-labelledby': 'Mk7uX91D',
          'data-parent': '#gUKCFyrB',
          i: [
            {
              n: 'div',
              c: 'card-body',
              i: [
                {
                  namespace: 'p',
                  class: 'p-3',
                  inner: [
                    'Yes, we have separate services for children during Sunday service, divided by age group (from 6 months to high school). During this time, the children can experience a safe and fun-filled environment where they can grow and develop by learning the Word of God. ',
                    {
                      href: 'http://192.168.0.240:8517/ministries',
                      namespace: 'a',
                      inner: 'Learn more about our ministries.'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      n: 'div',
      c: 'card',
      id: 'p47MNt8l',
      i: [
        {
          n: 'div',
          c: 'card-header',
          i: [
            {
              n: 'h5',
              c: 'mb-0',
              i: [
                {
                  n: 'button',
                  c: 'btn btn-link',
                  type: 'button',
                  'data-toggle': 'collapse',
                  'data-target': '#Gatb_087',
                  'aria-expanded': 'true',
                  'aria-controls': 'Gatb_087',
                  i: [ 'What Is Your Order of Service?' ]
                }
              ]
            }
          ]
        },
        {
          n: 'div',
          c: 'collapse',
          id: 'Gatb_087',
          'aria-labelledby': 'p47MNt8l',
          'data-parent': '#gUKCFyrB',
          i: [
            {
              n: 'div',
              c: 'card-body',
              i: [
                {
                  namespace: 'p',
                  class: 'p-3 ',
                  inner: [
                    {
                      namespace: 'ol',
                      inner: [
                        {
                          namespace: 'li',
                          inner: 'Communication with God through prayer.'
                        },
                        {
                          namespace: 'li',
                          inner: 'Celebration and expression of our adoration to God through worship.'
                        },
                        {
                          namespace: 'li',
                          inner: 'Casually greeting those around us with a quick "hello."'
                        },
                        {
                          namespace: 'li',
                          inner: "Getting informed about what's happening through Family News announcements."
                        },
                        {
                          namespace: 'li',
                          inner: "Learning about God's way of giving back."
                        },
                        {
                          namespace: 'li',
                          inner: 'Experiencing a life-changing and inspirational Bible-based message.'
                        },
                        {
                          namespace: 'li',
                          inner: 'Invitation to become a follower of Christ at the end of service if you so desire.'
                        }
                      ]
                    },
                    {
                      title: 'How Can I Connect at IFWC?',
                      inner: {
                        namespace: 'p',
                        class: 'p-3',
                        inner: [
                          'One of the best ways to connect is by becoming a partner. You can also connect by taking growth classes, joining a life group, or serving on a team. These opportunities will help you get to know others and grow on your journey with God.'
                        ]
                      }
                    },
                    {
                      title: 'When & Where Are Your Services?',
                      inner: {
                        namespace: 'p',
                        class: 'p-3',
                        inner: [
                          'Our services are on Sundays. Times may change and are listed below for your convenience. '
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      n: 'div',
      c: 'card',
      id: 'SLDAKRkB',
      i: [
        {
          n: 'div',
          c: 'card-header',
          i: [
            {
              n: 'h5',
              c: 'mb-0',
              i: [
                {
                  n: 'button',
                  c: 'btn btn-link',
                  type: 'button',
                  'data-toggle': 'collapse',
                  'data-target': '#6AYB3DeM',
                  'aria-expanded': 'true',
                  'aria-controls': '6AYB3DeM',
                  i: [ 'How Can I Grow at IFWC?' ]
                }
              ]
            }
          ]
        },
        {
          n: 'div',
          c: 'collapse',
          id: '6AYB3DeM',
          'aria-labelledby': 'SLDAKRkB',
          'data-parent': '#gUKCFyrB',
          i: [
            {
              n: 'div',
              c: 'card-body',
              i: [
                [
                  {
                    namespace: 'p',
                    class: 'p-3',
                    inner: 'There are several ways for growth here:'
                  },
                  {
                    namespace: 'ol',
                    inner: [
                      {
                        namespace: 'li',
                        inner: 'Attend our Sunday services.'
                      },
                      {
                        namespace: 'li',
                        inner: [
                          'Get involved with one of our many Life Groups for continued growth on your journey. Groups include men, women, youth, married couples, freedom, finance, parenting, blended family, and more.',
                          {
                            href: 'http://192.168.0.240:8517/ministries',
                            namespace: 'a',
                            inner: 'Learn more.'
                          }
                        ]
                      }
                    ]
                  }
                ]
              ]
            }
          ]
        }
      ]
    },
    {
      n: 'div',
      c: 'card',
      id: 'cJiG3Z_o',
      i: [
        {
          n: 'div',
          c: 'card-header',
          i: [
            {
              n: 'h5',
              c: 'mb-0',
              i: [
                {
                  n: 'button',
                  c: 'btn btn-link',
                  type: 'button',
                  'data-toggle': 'collapse',
                  'data-target': '#xWEcixVg',
                  'aria-expanded': 'true',
                  'aria-controls': 'xWEcixVg',
                  i: [ 'When & Where Are Your Services?' ]
                }
              ]
            }
          ]
        },
        {
          n: 'div',
          c: 'collapse',
          id: 'xWEcixVg',
          'aria-labelledby': 'cJiG3Z_o',
          'data-parent': '#gUKCFyrB',
          i: [
            {
              n: 'div',
              c: 'card-body',
              i: [
                [
                  {
                    namespace: 'p',
                    class: 'p-3',
                    inner: [
                      'While many people at The Family dress in casual or business-casual attire, we encourage and welcome you to dress in a way that best fits your style and preference.  When you visit, our goal is to make you feel at home, no matter who you are or what you may be wearing.'
                    ]
                  },
                  {
                    address: '327 Huntington St. New London, CT 06320',
                    namespace: 'ua.getdirections',
                    class: 'btn-info'
                  }
                ]
              ]
            }
          ]
        }
      ]
    },
    {
      n: 'div',
      c: 'card',
      id: 'mHu2qQ93',
      i: [
        {
          n: 'div',
          c: 'card-header',
          i: [
            {
              n: 'h5',
              c: 'mb-0',
              i: [
                {
                  n: 'button',
                  c: 'btn btn-link',
                  type: 'button',
                  'data-toggle': 'collapse',
                  'data-target': '#TJAwECXW',
                  'aria-expanded': 'true',
                  'aria-controls': 'TJAwECXW',
                  i: [ 'Looking For A Place To Serve?' ]
                }
              ]
            }
          ]
        },
        {
          n: 'div',
          c: 'collapse',
          id: 'TJAwECXW',
          'aria-labelledby': 'mHu2qQ93',
          'data-parent': '#gUKCFyrB',
          i: [
            {
              n: 'div',
              c: 'card-body',
              i: [
                {
                  namespace: 'p',
                  class: 'p-3',
                  inner: "We believe God created you with unique abilities and gifts to help contribute to His work. No matter who you are, there is a place for you to serve based on your passions, gifts, and talents. When you serve in the area God has designed for you, it's both rewarding and beneficial to those you serve. We want you to find the most fulfilling place to serve. Take the PLACE assessment in the Growth Track to find that special place of belonging. We invite you to partner with us and join the team as we work together to accomplish God's vision for IFWC."
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};