/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Stores and handles configuration variables.
 * 
 * It should be noted that access to this object should be closely guarded.
 * 
 *  * Required Files
 * config.json 
 * settings object data will be imported.
 * 
 *  * Required Directories
 * dump
 * Just store the history here... exclude from git and clean
 * 
 */

var _ = require("./module.js")(module.exports, {
    namespace: "config",
    // May never add a config property in this object (circular reference)
    errors: {
        "invalidDefineObj": {
            title: "Invalid Defination Object",
            description: "The defination object is invalid either because it does not have a config property or namespace."
        }
    }
})

var namespace = "config";
var objectify = require("./functions/objectifyProperty.js").function;

var fs = require("fs");
var validate = require('jsonschema').validate;

var resolve = require("./functions/resolve.js").function;

var fm = require("./filemanager.js");

var configFilename = "config.json";
var configPath = resolve(`@!/${configFilename}`);
var configDefineFilename = "config.define";
// var configDefinePath = resolve(`@!/${configDefineFilename}`);

// var jckConsole = require("@jumpcutking/console");
var GenerateStacktrace = require("./functions/generateStacktrace.js").function;
var GenerateSafeError = require("./functions/generateSafeError.js").function;

// var dumpFolder = resolve("@!/.log/history");

 var $ = require("./scafolding");
 $.config = {};

 //The config varables are stored here
 var storage = {};
 var objectified = [];

 //The definitions for config varables are stored here.
 var definitions = {};

/**
 * Saves the config state to config.json
 */
var save = async () => {

    // console.info(`Saving config to ${configPath}, the definitions are automatically saved in log at ${configDefineFilename} as created.`);

    var repo = {
        success: true,
        revision: true
    }

    fm.SaveBackupOf(configPath, "config");

    // var dump = `${dumpFolder}/`;

    // if (!(fs.existsSync(dump))) {

    //     $.log.add("Save.history",
    //             `Dump folder doesn't exsist, so adding it.`,
    //             dumpFolder, 2, namespace);

    //     fs.mkdirSync(dump);
    // }
    
    // } else {

        //save a revision history
        // if (fs.existsSync(configPath)) {
        
        //     try {
                
        //         var now = new Date();
        //         //../dump/20220725 10001000.config.json
        //         var historyPath = `${dump}${now.getFullYear()}${pad(now.getMonth(),2)}${pad(now.getDay(),2)}${pad(now.getHours(),2)}${pad(now.getMinutes(),2)} ${pad(now.getMilliseconds(),4)}.config.json`;
        //         fs.copyFileSync(configPath, historyPath);
            
        //     } catch (error) {

        //         $.log.add("Save.history",
        //             `Saving history failed.`,
        //             error, 2, namespace);
                
        //         repo.revision = false;
        //     }
        
        // }
    // }

    try {

        //clone storage before working with it
        // var c = {...storage};
        //c = stripFromConfig(c); //we don't need to strip now that they are stored in definitions

        var c = {};
        var i = 0;
        while (Object.keys(storage).length > i) {
            var key = Object.keys(storage)[i];
            var value = _get(key);
            // console.log("save", {key: key, value: value});
            objectifyStandard(key, value, c);
            i = i + 1;
        }

        //count the items in storage
        // var i = 0;
        // while (Object.keys(storage).length > i) {
        
        // console.log("c", c);

        //load previous config
        // var prevConf = {};
        // if (fs.existsSync(configPath)) {
        //     prevConf = JSON.parse(fs.readFileSync(configPath, 'utf8').toString());
        // }

        // // overide or add all new config values recursively
        // var i = 0;
        // while (Object.keys(c).length > i) {
        //     var key = Object.keys(c)[i];
        //     var value = c[key];
        //     // console.log("save", {key: key, value: value});
        //     objectifyStandard(key, value, prevConf);
        //     i = i + 1;
        // }

        //store only app specific config, not defualts
        //fs.writeFileSync(configPath, JSON.stringify(c, null, 2)) // spacing level = 2)
        
        fm.saveJSON(configPath, c);

        //store config definations for developers to read and use
        //var d = {...definitions};
        //fs.writeFileSync(configDefinePath, JSON.stringify(d, null, 2)) // spacing level = 2)
        
    } catch (error) {

        console.error(`Could not save the config file. ${error.toString()}`, {
            error: GenerateSafeError(error)
        })

        // $.log.add("Save",
        //     `Saving config failed.`,
        //     error, 2, namespace);

        repo.success = false;
        repo.error = error;
    
    }

    return repo;

}
module.exports.save = save;

// /**
//  * Padd Zeros to wrap the numbers.
//  * @param {*} n The number
//  * @param {*} width Expected Places
//  * @param {*} z Pad zero character replacement (or string)
//  * @returns 
//  */
// function pad(n, width, z) {
//     z = z || '0';
//     n = n + '';
//     return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
//   }

/**
 * Loads/resets the config from an object
 * @param {*} c 
 */
function LoadFromObject(c) {

    //if I'm loading from an object I need to convert {this:{that:{propery: value}}} to "this.that.property"
    
    // console.log("LoadFromObject", {
    //     c: c,
    //     type: typeof c
    // });

    var arr = $.f.flattenObject(c);
    // console.error("LoadFromObject", arr);

    
    //for each key in config add/change
    for (key in arr) {
        setter(key, arr[key]); //set the value through the same function
    }

    return c;

}
module.exports.LoadFromObject = LoadFromObject;


/**
 * Loads the config.json file
 */
var load = async (path = "") => {

    //if path = "" than use the default
    if (path == "") {
        path = configPath;
    }

    //path = fs.resolve(path);

    // console.info

    // $.log.add("Load", `Loading config from ${path}`, null, 1, namespace);

    //load the defintions
    var d = {};
    try {
        d = fm.LoadLogJSON(configDefineFilename);
    } catch (error) {
      
        console.warn(`Loading config definitions failed. ${error.toString()}`, {
            error: GenerateSafeError(error)
        });

        // $.log.add("Load",
        // `Loading config definitions failed.`,
        // error, 2, namespace);
    }

    try {
        
        for (key in d) {
            addDefinition(d[key]);
        }

    } catch (error) {
        console.warn(`Loading defintion for ${key} failed.`, {
            error: GenerateSafeError(error)
        });
    }


    try {
        //load the config
        var c = JSON.parse(fs.readFileSync(path, 'utf8').toString());  
        // console.log("Load config", c);
        LoadFromObject(c);
              
    } catch (error) {

        console.error(`Could not load the config file. ${error.toString()}`, {
            error: GenerateSafeError(error)
        })


        // $.log.add("Save",
        // `Saving config failed.`,
        // error, 2, namespace);
    }

    // console.info("Loaded configs", {
    //     config: c,
    //     definitions: d
    // });

}
module.exports.load = load;

/**
 * Reports the state of the config
 */
function report() {
    return {
        definitions: {...definitions},
        config: {...storage}
    };
}
module.exports.report = report;

/**
 * Allows a module to define at runtime, it's config varables
 * @param {*} d The defintion object or array.
 */
function define(d) {

    if (Array.isArray(d)) {
        
        var i = 0;
        while (d.length > i) {
            // console.log("this thang", d[i]);
            addDefinition(d[i]);
            i = i + 1;
        }

    } else {

        addDefinition(d);
    }
    
 
}
module.exports.define = define;

/**
 * Allows a module to define at runtime, it's config varables
 * A config property must be contained in the object, only properties of config will be added as a definition.
 * @param {*} d The defintion object
 * @property d.namespace The namespace of the object. The object MUST have a namespace.
 * @property d.config The config object.
 * @property d.config[].title The title of the setting.
 * @property d.config[].description The description of the setting.
 * @property d.config[].default The default value of the setting.
 * @property d.config[].type The type of the setting.
 * @property d.config[].namespace The namespace of the setting.
 * @property d.config[].from The stacktrace of the setting.
 * @property d.config[].from.file The file of the stacktrace.
 * @property d.config[].from.line The line of the stacktrace.
 * @property d.config[].from.column The column of the stacktrace.
 * @property d.config[].from.function The function of the stacktrace.
 */
function useDefine(d) {

    if (!("namespace" in d)) {
        _.errors.invalidDefineObj.throw(d);
    }

    if ("config" in d) {
        for (key in d.config) {
            d.config[key].namespace = d.namespace + "." + key;
            addDefinition(d.config[key]);
        }
    } else {
        _.errors.invalidDefineObj.throw(d);
    }

} module.exports.useDefine = useDefine;

/**
 * Adds a defined config varable - object only - to the storage.
 * Deifnitions add safely, they do not throw exceptions (but to the log).
 * @param {*} define 
 */
function addDefinition(define) {

    // $.log.add("addDefinition", `Atempting to add a config definition.`, define, 4, namespace);

    // console.info(`Attempting to add a config definition.`, define);

    var valid = validate(define, schema.define);
    if (valid.valid == false) {

         //throw new Error("The object failed validation."); 

        // $.log.add("addDefinition",
        //     `A config definition could not be added.`, {
        //         definition: define,
        //         errors: valid.errors,
        //         valid: valid
        //     }, 3, namespace);

        console.error(`A config definition could not be added.`, {
            definition: define,
            errors: valid.errors,
            valid: valid
        });

        throw new error(`A config definition could not be added.`);

        // console.error("addDefinition", {
        //     definition: define,
        //     errors: valid.errors,
        //     valid: valid
        // });

        //throw new Error("The object failed validation."); 
        //SimpleError("NamespaceSchemaFailed", "The document failed to pass the namespace schema [" + $document.meta.ns + "].", valid.errors, $document);
    } else {

        //add a stacktrace
        define.from = GenerateStacktrace(2)[0];

        definitions[`${define.namespace}`] = define;
        objectifyFull(define.namespace);

        saveDefine(define); //async only

        // $.log.add("addDefinition", `A config definition was added.`, {
        //     define: define
        // }, 4, namespace);

    }
}

/**
 * Loads the previously saved config.define.json file and adds the definitions to the config only if there is a change.
 */
async function saveDefine(defin) {

    var curDefines = {}
    //get json 
    try {
        curDefines = fm.LoadLogJSON(configDefineFilename);
    } catch (error) {
        
    }
    // var curDefines = fm.loadJSON(configDefinePath);
    var oriDefines = {...curDefines};

    //is the key in cur define?

    var curDefine = curDefines[defin.namespace];

    if (curDefine == undefined) {
        //add it
        curDefines[defin.namespace] = defin;
    } else {

        //compare the two
        var hasChanges = false;
        var i = 0;
        while (Object.keys(defin).length > i) {
            var key = Object.keys(defin)[i];
            if (curDefine[key] != defin[key]) {
                hasChanges = true;
            }
            i = i + 1;
        }

        if (hasChanges) {
            //add it
            curDefines[defin.namespace] = defin;
        }

    }

    // curDefines = CompareDefinitions(curDefines);
    
    //save json
    // fm.BackupJSON(configDefineFilename, oriDefines, "config"); // to log folder by filename and defin and a folder name
    // fm.saveJSON(configDefinePath, curDefines); // save the definition file
    fm.SaveLogJSON(configDefineFilename, curDefines);
}

/**
 * check if config.define.json (configDefineFilename) and if it has more items than curDefines
 * if it does, add the missing ones from the config.define.json (configDefineFilename) to curDefines
 * @param {*} cur The current definitions to compare to.
 */
function CompareDefinitions(cur) {
    var ori = fm.LoadLogJSON(configDefineFilename);

    var i = 0;
    while (Object.keys(ori).length > i) {
        var key = Object.keys(ori)[i];
        if (!(key in cur)) {
            cur[key] = ori[key];
        }
        i = i + 1;
    }

    return cur;

} module.exports.CompareDefinitions = CompareDefinitions;

// module.exports.addDefinition = addDefinition;

/**
 * To prevent recursion, this is the getter for the config varable.
 * @param {*} key 
 */
function getter(key) {
    var repo = null;

    // console.log(`Config: Getting ${key}`, {
    //     store: storage[key],
    //     storage: storage
    // });

    // $.log.add("Get", `Atempting to get a config value.`, key, 4, namespace);
    
    // console.log("Getter", {
    //     key: key,
    //     storage: storage
    // });

    //get the app set value
    if (key in storage) {

        // console.log(`Found ${key} in storage.`);

        // $.log.add("Get", `Key is in storage.`, {
        //     key: key//,
        //     // storage: storage
        // }, 4, namespace);

        // console.log(`Getting ${key} from storage.`, {
        //     store: storage[key],
        // });
        return storage[key]

    } else {

        // console.log(`Getting ${key} defintion.`);

        // $.log.add("Get", `Is key in definition?`, {
        //     key: key//,
        //     // storage: storage
        // }, 4, namespace);

        //If the app doesn't set a value
        // check for definitions

        if (key in definitions) {


            // console.log(`Found ${key} in definition.`);

            // $.log.add("Get", `Key is in definition.`, key, 4, namespace);
            // console.log(`Getting ${key} from defintion.`, {
            //     store: storage[key],
            // });

            var myDefin = definitions[key];

            //check if there is a default value or not
            if ("default" in myDefin) {
                //return default
               
                // console.log(`${key} has a default.`, {
                //     default: myDefin.default
                // });

                // $.log.add("Get",
                //     `A defualt was requested by the definition (no value).`, {
                //         key: key,
                //         default: myDefin.default
                //     }, 4, namespace);

                return myDefin.default;

            } else {


                // console.log(`${key} does not have a default provided in it's definition.`);

                // $.log.add("Get",
                //     `A config value was not found and a defualt was not provided by the definition.`, 
                //     key, 3, namespace);

            }

        } else {

            console.warn(`The key ${key} was not found in the config or not defined.`);

            // $.log.add("Get",
            //     `A config value was not found and a definiton was not set.`, 
            //     {
            //         key: key,
            //         definitions: definitions
            //     }, 3, namespace);

        }
  
    }

}
module.exports.getter = getter;

// function _get(key) {
//     return getter(key);
// }


function _get(namespace) {
    // console.log("_get", namespace);

    //this isn't a real thing - it's a theory
    if (typeof namespace !== "string") {
        //iterate through the namespace object {this:{that:{propery: value}}} and get the complete name
        var i = 0;
        var key = "";
        var myNamespace = {...namespace};
        while (Object.keys(myNamespace).length > i) {
            var k = Object.keys(myNamespace)[i];
            key = `${key}.${k}`;
            myNamespace = myNamespace[k];
            i = i + 1;
        }

        // console.log(`${namespace} was converted to a string.`);

        // console.log("_get:return", {namespace: namespace, key: key});
        // $.log.add(`${namespace}_get`, `The namespace was converted to a string.`, {
        //     namespace: namespace,
        //     key: key
        //     }, 1, namespace);

        return getter(key);

    } else {
        //this is 100% of the calls
        return getter(namespace);

    }

    // return getter(`${namespace}`);
}

// function _set(key, value) {
//     setter(key, value);
// }

function _set(namespace, value) {

    //iterate through the namespace object {this:{that:{propery: value}}} and get the complete name and 
    // console.log("_set", {namespace: namespace, value: value});
    setter(`${namespace}`, value);
}

/**
 * Converts a namespace to a config object using the provided config & setter object.
 * @param {*} namespace The namespace to objectify
 */
function objectifyFull(namespace) {

    //check if the namespace has not been objectified yet
    if (!(namespace in objectified)) {
        objectify(namespace, $.config, _get, _set);
        objectified.push(namespace);

        // $.log.add("objectifyFull", `The namespace was objectified.`, {
        //     namespace: namespace
        //     }, 4, namespace);

    } else {

        // console.log(`The namespace has already been objectified.`, {
        //     namespace: namespace
        // });
        // $.log.add("objectifyFull", `The namespace has already been objectified.`, {
        //     namespace: namespace
        //     }, 2, namespace);
    }

}

/**
 * To prevent recursion, this is the setter for the config object.
 * @param {*} namespace namespace.property
 * @param {*} value 
 */
function setter(namespace, value) {

    storage[namespace] = value;
    objectifyFull(namespace)

} module.exports.setter = setter;

//Import my schema into one file
var schema = {
};

schema.define = {
    "$schema": "https://json-schema.org/draft/2019-09/schema",
    "$id": "http://example.com/example.json",
    "type": "object",
    "default": {},
    "title": "Define Schema",
    "required": [
        "namespace", "title", "description", "default", "type"
    ],
    "properties": {
        "namespace": {
            "type": "string",
            "default": "",
            "title": "The namespace Schema",
            "examples": [
                "universe.object"
            ]
        },
        "type": {
            "type": "string",
            "default": "",
            "title": "The type Schema",
            "description": "The type of property which can be a datatype namespace. Designed to make it easier to create a setting UI Interface.",
            "examples": [
                "object", 
                "string",
                "file",
                "number",
                "html.color.hex",
                "universe.userid"
            ]
        },
        "default": {
            "type": ["string", "number", "boolean", "object", "array", "integer"],
            "default": "",
            "title": "The default Schema",
            "examples": [
                "except"
            ]
        },
        "title": {
            "type": "string",
            "default": "",
            "title": "The title of the setting.",
            "examples": [
                "Object Setting"
            ]
        },
        "description": {
            "type": "string",
            "default": "",
            "title": "The description of the setting.",
            "examples": [
                "A test config setting for an object"
            ]
        }
    },
    "examples": [{
        "namespace": "universe.object.test",
        "title": "Object Setting Test",
        "description": "A test config setting for an object",
        "default": "except"
    }]
}

module.exports.schema = schema;

/**
 * List's the names of all the registered config varables.
 * @returns The list of namespaces already converted to objects.
 */
function list() {
    return objectified;
}

module.exports.list = list;

/**
 * Gets all the properties of a namespace.
 * @param {*} namespace The namespace (not the property);
 */
function GetFull(namespace) {
    //get all properties of the namespace

    // console.log("storage", {
    //     storage: storage,
    //     keys: Object.keys(storage),
    //     keyLength: Object.keys(storage).length
    // });

    //get all the namespaces in the storage that start with namespace and a dot
    var repo = {};
    var storageI = 0;

    var keys = Object.keys(storage);

    while (keys.length > storageI) {

        // console.log("GetFull", {
        //     storageI: storageI
        // });

        var key = keys[storageI] + "";
        var value = storage[key];

        if (key.startsWith(namespace + ".")) {
            // repo[key] = storage[key];

            var keySplit = key.replace(`${namespace}.`, "").split(".");
            var keySplitI = 0;
            var objAdd = repo;
            
            while (keySplit.length > keySplitI) {

                var currentKey = keySplit[keySplitI];
                if (currentKey in objAdd) {
                    objAdd = objAdd[currentKey];
                } else {
                    if (keySplitI == keySplit.length - 1) {
                        objAdd[currentKey] = value;
                    } else {
                        objAdd[currentKey] = {};
                        objAdd = objAdd[currentKey];
                    }
                }

                keySplitI = keySplitI + 1;

            }

        }

        storageI = storageI + 1;
    }

    // while(storage.length > i) {
    // console.log("GetFull", {
    //     namespace: namespace,
    //     repo: repo
    // });

    return repo;

} module.exports.GetFull = GetFull;


/**
 * The vaule based objectification function.
 */
var objectifyStandard = require("./functions/objectify.js").function;

/**
 * Reports all the config varables and their values, as objects.
 * Returns default values.
 */
function reportConfigValues() {
    
        var report = {};
        var i = 0;
        while(objectified.length > i) {
            var key = objectified[i];
            var value = _get(key);
            objectifyStandard(key, value, report);
            i = i + 1;
        }
    
        return report;
}
module.exports.reportConfigValues = reportConfigValues