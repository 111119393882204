

// var interface = require("./interface.js");

// var IntersectionObserver = IntersectionObserver;
// var IntersectionObserverEntry = IntersectionObserverEntry;

// if (window.UATisServer) {

//   i = require("./PollyFill.IntersectionObserver.js");

//   console.log("Yes it's true!", {
//     window: window,
//     UATisServer: window.UATisServer,
//     IntersectionObserver: window.IntersectionObserver,
//     IntersectionObserverEntry: document.IntersectionObserverEntry
//   });

//   IntersectionObserver = i.IntersectionObserver;
//   IntersectionObserverEntry = i.IntersectionObserverEntry;

// }

try {
  
/**
 * Observes if an element is visible on the page.
 * Using the data-ua-observe attribute, a observer-visible class is added to the element when it is visible.
 * When an element is not visible, the class observer-invisible is added.
 * Your animation code can use these classes to determine if the element is visible or not.
 */
var observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
        if (entry.isIntersecting) {
        // Element is now visible in the viewport

        if (entry.target.classList.contains("observer-visible")) {
            //do nothing
        } else {
            entry.target.classList.add("observer-visible");
            entry.target.classList.remove("observer-invisible");
        }

        // console.log('Element is visible!', entry.target);
      
    } else {
      // Element is not visible in the viewport

        if (entry.target.classList.contains("observer-invisible")) {
            //do nothing
        } else {
            entry.target.classList.add("observer-invisible");
            entry.target.classList.remove("observer-visible");
        }
        // console.log('Element is not visible!', entry.target);
     
    }
  });
});
} catch (error) {
  console.info(`UAT is running in a server environment. The IntersectionObserver is not available. ${error}`);
}


  
  function observe(selector) {
      try {
          var elements = document.querySelectorAll(selector);

          elements.forEach((element) => {
            try {
              observer.observe(element);
            } catch (error) {
              console.log(`The Observer could not be attached to the provided elements.`, error, element);
            }
          });
      } catch (error) {
          console.log(`The observer selector may be invalid.`, selector, element);
      }
  } module.exports.observe = observe;

//   on("AfterRender", );


