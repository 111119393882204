/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "padZeros";

/**
 * Automatically padd zeros to the left of the string to make it the specified length.
 * @param {String} string The string to pad zeros to.
 * @param {Number} length The length of the string to pad zeros to.
 */
function padZeros(string, length) {

    //if it's a number it will force it to a string
    string = string.toString();

    while (string.length < length) {
        string = "0" + string;
    }
    return string;
}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: padZeros,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            //test padZeros

            var string = "1";
            var length = 3;
            var result = padZeros(string, length);

            if (result == "001") {
                return true;
            } else {
                return false;
            }

        }
    }]
};