/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.dialog.getdirections";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");


var required = ["address"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "A directions dialog box",
  description: "A a dialog showing directions through supported map apps.",
  fields: {
    "address": {
      type: "string", 
      description: "The address to give directions to."
    },
    "title": {
      type: "string",
      default: "",
      description: "The title of the dialog box."
    },
    "inner": {
      type: "inner", 
      description: "The contents of the dialog box.",
      default: "Which map app would you like to use?"
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  if ("UATisServer" in window && window.UATisServer) {
    options.allowclose = false;
  }

  var title, inner, footer = false;

  if ("title" in options) {
    title = options.title;
  } else {
    title = "";
  }

  if ("inner" in options) {
    inner = options.inner;
  } else {
    inner = "Which map app would you like to use?";
  }

  if ("footer" in options) {
    footer = options.footer;
  } else {
    footer = "";
  }

    var address = options.address;
    var encodedAddress = encodeURIComponent(address);
    var modal = {
      "n": "ua.dialog",
      "c": "text-white",
      "title": title,
      "inner": [{
        "n": "p",
        "c": "fs-3",
        "i": inner
      }, {
        "n": "ua.list",
        "c": "bg-75-black",
        "i": [{
            "n": "ua.list.item",
            c: "p-3 fs-4 text-white",
            "href": "https://maps.apple.com/?address=" + encodedAddress,
            "target": "_blank",
            "i": [{
              n: "i",
              c: "bi bi-apple"
            }, " Apple Maps"]
          }, {
            "n": "ua.list.item",
            c: "p-3 fs-4 text-white",
            "href": "https://www.google.com/maps/dir/?api=1&destination=" + encodedAddress,
            "target": "_blank",
            "i": [{
              n: "i",
              c: "bi bi-google"
            }, " Google Maps"]
          }, {
            "n": "ua.list.item",
            c: "p-3 fs-4 text-white",
            "href": "https://waze.com/ul?q=" + encodedAddress,
            "target": "_blank",
            "i": [{
              n: "i",
              c: "bi bi-map"
            }, " Waze"]
          }]
        }],
      "footer": footer
    };


    return await jsonRender.render(modal, {
      PassthroughOptions: options
    });

}

/** 
 * 
 * 
 * 
 * <a href="https://www.google.com/maps/place/16001+Needles+Rd,+Johnstown,+OH+43031" target="_blank">
                    <img src="../imgs/google-maps.png" alt="Google Maps"> Google Maps
                </a>

                <a href="https://maps.apple.com/?address=16001%20Needles%20Drive,+Johnstown,+OH+43031" target="_blank">
                    <img src="../imgs/apple-maps.png" alt="Apple Maps"> Apple Maps
                </a>
 * 
 *
*/
