var validate = require('jsonschema').validate;
var log = require('./log.js');
// var fs = require('fs');
// var path = require('path');

exports.replaceBreaksWithParagraphs = function(input) {
    input = exports.filterEmpty(input.split('\n')).join('</p><p>');
    return '<p>' + input + '</p>';
}

exports.filterEmpty = function (arr) {
    var new_arr = [];

    for (var i = arr.length-1; i >= 0; i--)
    {
        if (arr[i] != "")
            new_arr.push(arr.pop());
        else
            arr.pop();
    }

    return new_arr.reverse();
};


/**
 * Validates a object or string to JSON.
 * Returns false if the var can not be converted.
 * @param  {*} v [the object]
 * @return {*}   [object or false]
 */
exports.ProduceJSON = function(v) {
  try {
    if (typeof v == "object") {
      return v;
    } else if (typeof v == "string") {
      return JSON.parse(v);
    } else {
      return false;
    }
  } catch (e) {
    return false;
  } finally {

  }
}


/**
 * Used for checking server side provided HTML.
 * @param {*} obj 
 * @returns If the object is a node list.
 */
function isNodeList(obj) {
  return Object.prototype.toString.call(obj) === '[object NodeList]';
}

/**
 * Detects if a object is a dom object.
 * @param  {*} obj [description]
 * @return {*}     [description]
 */
exports.isDomElement = function(obj) {
  if (!obj) return false; // Check if the object exists

  // console.log(`Object Dom Testing`, {
  //   obj: obj,
  //   type: typeof obj,
  //   from: GenerateStacktrace(0)
  // });

  //am I an object
  if (typeof obj !== "object") {
    return false;
  }

  if ("nodeType" in obj) {
    return true;
  } else {
    return false;
  }

  //out of date

  // if (window.UATisServer) {
  //   //I'm running UAT server side.

  //   return isNodeList(obj);

  //   // //is it a node list?
  //   // if (isNodeList(obj)) {
  //   //   return true;
  //   // }


  //   // console.log("isDomElement",{
  //   //   obj: obj,
  //   //   type: typeof obj,
  //   //   prototype: Object.prototype.toString.call(obj),
  //   //   isNodeList: isNodeList(obj)
  //   // });

  //   // return false;
  // }

  // return obj instanceof Node;

  // // Check if the object is a DOM element
  // if (obj.nodeType === 1) {
  //   return true;
  // }

  // // Check if the object is a NodeList
  // if (typeof obj.length === 'number' && typeof obj.item === 'function') {
  //   // Verify that all items in the NodeList are DOM elements
  //   for (let i = 0; i < obj.length; i++) {
  //     if (obj[i].nodeType !== 1) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  // return false;
};

exports.ValidateJSON = function (schema, json) {
  var $testdocument = JSON.parse(JSON.stringify(json));
  //delete $testdocument.meta;

  valid = validate($testdocument, schema);
  if (valid.valid == false) {
    return {
      success: false,
      fault: "json invalid by schema",
      message: "The provided json failed to validate to the schema",
      pure: valid
    }
  } else {
    return {
      success: true
    };
  }
}

//The RegExpr for testing a Hex Code to be 6 characters - e-mail compliant.
var HexTester = /^#[0-9A-F]{6}$/i;

/**
 * Checks that the supplied code is a HEX code (for web colors) (e-mail compliant 6 characters).
 * @type {*}
 */
exports.ValidateHEX = function (hex) {
  if (HexTester.test(hex)) {
    return true;
  } else {
    return false;
  }
}


exports.extend = function(out) {
  out = out || {};

  for (var i = 1; i < arguments.length; i++) {
    var obj = arguments[i];

    if (!obj)
      continue;

    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object'){
          if(obj[key] instanceof Array == true)
            out[key] = obj[key].slice(0);
          else
            out[key] = deepExtend(out[key], obj[key]);
        }
        else
          out[key] = obj[key];
      }
    }
  }

  return out;
};

exports.parseHTML = function(str) {
  var tmp = document.createElement("div");
  tmp.innerHTML = str;
  return tmp.children;
};

exports.createResponse = function(str) {
  var tmp = document.createElement("response");
  tmp.innerHTML = str;
  return tmp;
}

//list the months
exports.uaMonthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

//get the current timezone
exports.getTimezoneName = function(today) {
  var short = today.toLocaleDateString(undefined);
  var full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

  // Trying to remove date from the string in a locale-agnostic way
  var shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
}

//Safely append an item into the registry based on their namespace
exports.SafelyRegisterNamespace =  function(registry, item) {
  var issafe = false;

  if (registry.length < 1) {
    //I have no items in my registry, so I'm safe.
    issafe = true;
  } else {
    //Check to be sure the namespace isn't already added
    for (var i = 0; i < registry.length; i++) {
      if (item.namespace == registry[i].namespace)  {
        var msg = "The namespace " + item.namespace + " is already registered in the registry.";
        throw msg;
        //throw msg;
      } else {
        issafe = true;
      }
    }
  }
  //add outside of for so this new element isn't counted
  if (issafe) {
    registry.push(item);
  }
}

/**
 * Loads all the scripts in a folder into a namespace.
 * This is not recursive.
 * @param {*} registry The Registry to add the scripts to.
 * @param {*} folderPath The path of the folder to load.
 */
function RegisterScriptsToRegistry(registry, folderPath) {
  //get a list of files in the folder that are .js
  var files = fs.readdirSync(folderPath);
  for (var i = 0; i < files.length; i++) {
    var file = files[i];
    if (path.extname(file) == ".js") {
      //load the file
      var item = require(folderPath + "/" + file);
      //add it to the registry
      exports.SafelyRegisterNamespace(registry, item);
    }
  }

} module.exports.RegisterScriptsToRegistry = RegisterScriptsToRegistry;


//pass atributes from one to another
exports.PassAtributes = function(nodefrom, nodeto, except) {
  //pass all attributes to the input field
  for (var att, i = 0, atts = nodefrom.attributes, n = atts.length; i < n; i++){
    att = atts[i];
    //ignore this attributes

    if (!(except.includes(att.nodeName))) {
      nodeto.setAttribute(att.nodeName, att.nodeValue)
    }
  }
}

/**
 * Pass the options into a node.
 * It will automaitcally combine classes and styles.
 * Ignores objects but will include arrays.
 * @param  {*} PassthroughOptions A JSON object.
 * @param  {*} domElement  The node to append the options to.
 * @param  {Array} except  Don't pass these keys.
 * @return {*} Returns the node with the options appended. 
 */
exports.PassOptions = function(PassthroughOptions, domElement, except = []) {

  //is the domelement a fragment?
  if (domElement.nodeType == 11) {
    //I'm a fragment
    //loop through the children
    for (var i = 0; i < domElement.children.length; i++) {
      //pass the options to the child
      exports.PassOptions(PassthroughOptions, domElement.children[i], except);
    }

    return domElement;

  }

  
  for (var key in PassthroughOptions) {
    if (key === "namespace" || key === "inner") {
        continue;
    }
    
    if (except.includes(key)) {
      continue;
    }

    //if the key is in the Passthrough Options

    var value = PassthroughOptions[key];

    if ((typeof value === "object") && !(Array.isArray(value))) {
        // Recursively convert nested JSON objects
        // value = await convertJSONToDOM_Recursion(value, null, context);
        continue;
    }

    //if the key is ID
    if (key == "id") {
      domElement.id = value;
    }
  
    

    // Pass the class and style attributes

      if (key == "class") {
        
        //is the className empty?
        if (domElement.className == "") {
          domElement.className = value;
          continue;
        } else {
          //combine the classes
          domElement.className = domElement.className + " " + value;
          continue;
        }

      }

      if (key == "style") {
        //combine the styles

        if (domElement.style.cssText == "") {
          domElement.style.cssText = value;
          continue;
        }

        domElement.style.cssText = domElement.style.cssText + ";" + value;
      
      }

        //if the key is not in the json object
        domElement.setAttribute(key, value);

    // }


  }

  return domElement;

}



/**
 * Generates a unqiue DOM ID to append like "-f2jh4q4f3".
 * From: https://gist.github.com/gordonbrander/2230317
 * Generate unique IDs for use as pseudo-private/protected names.
 * Similar in concept to
 * <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
 * 
 * The goals of this function are twofold:
 * 
 *  * Provide a way to generate a string guaranteed to be unique when compared
 *    to other strings generated by this function.
 *  * Make the string complex enough that it is highly unlikely to be
 *   accidentally duplicated by hand (this is key if you're using `ID`
 *  as a private/protected name on an object).
 * @param {*} len The length of the id, max limit 34.
 */
exports.UniqueID = function(len = 9) {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '-' + Math.random().toString(36).substr(2, 9);
};

/**
 * Get's the full URI location of the current server's root directory.
 * With leading slash.
 * https://localhost:8080/
 */
exports.FullUrl = function () {
  return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+"/";
}

var GenerateSafeError = require("../../../uam/functions/generateSafeError.js").function;
module.exports.GenerateSafeError = GenerateSafeError;

var ParseStackTrace = require("../../../uam/functions/parseStackTrace.js").function;
module.exports.ParseStackTrace = ParseStackTrace;

var GenerateStacktrace = require("../../../uam/functions/generateStacktrace.js").function;
module.exports.GenerateStacktrace = GenerateStacktrace;