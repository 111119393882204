/*!
 * (C) 2024. Justin K Kazmierczak. All Rights Reserved.
 *
 * Build for The Universe.
 * Subject to The Universe Terms of Service and Privacy Policy.
 * 
 * Built for Universe App Tools.
 * 
 * This module marks a placeholder for the ua element.
 * 
 */

var f = require("../scripts/f.js");
var jsonRender = require("../interface/jsonRender.js");

// var prependToId = "_uae__";

var define = {
    namespace: "ua.none",
    title: "A Nonrenderable Object",
    description: "For passing data between UATools where an item must be appeneded to the body and information needs to be passed back to the requesting element",
    editable: false,
    fields: {
      
    }
  }; module.exports.define = define;


  /**
   * Must be called directly, render may fail if used as part of a template.
   * @param {*} options The jsonObject to render.
   * @returns The rendered DOM object.
   */
  exports.render = async function (options) {

    return [""];

  }