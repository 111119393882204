/*!** STOP ***

  This element is purely for editing and e-mail.

  If you are a developer, writing your pages (and not building them in database)
  try to use the HTML <p> tag and not an <element> tag.

  The element tag is designed for database stored tags, e-mail, and editing them.

*** STOP ***/

var f = require("../scripts/f.js");
var log = require('../scripts/log.js');
var uae = require("./templates/ua.element.js");

exports.namespace = "ua.e.listgroup";

var required = ["inner"];
var listitemRequired = ["title"];

var example = 
  {order: 0,
   title: "entity",
   id: "some-id",
   icon: "u-namesapce",
   desc: "The entity namesapce.",
   color: "primary",
   // badge: {
   //   color: primary,
   //   text: 9,
   //   icon: "bi-square"
   // }],
   ua: {
     "uri": "http://do-something",
     "method": "post",
     "disableWhileLoading": true,
     "data": {},
     "uaversion": "0.8.0"
   }
  };

exports.render = function (ele) {

  var options = uae.GetOptions(ele, required);
  var list = document.createElement("div");
  list.classList.add("list-group");

  if ("flush" in options) {
    if (options.flush) {
      list.classList.add("list-group-flush");
    }
  }

  if (options.inner.length > 0) {

    //sort by order
    if ("order" in options.inner[0]) {
      options.inner.sort((a, b) => a.order - b.order);
    }

    log.info("Rendering items", typeof options.inner, options.inner);
    options.inner.forEach((item, i) => {
      
      var listitem = false;

      if ("ua" in item) {
        listitem = document.createElement("button");
        listitem.setAttribute("type", "button");
        listitem.classList.add("list-group-item-action");
      } else {
        listitem = document.createElement("div");
      }

      listitem.classList.add("list-group-item");

      if ("look" in item) {
        listitem.classList.add("list-group-item-" + item.look);
      }

      if ("title" in item) {
        listitem.innerHTML = `<p>${item.title}</p>`;
      }

      if ("desc" in item) {
        listitem.innerHTML = `${listitem.innerHTML}<small>${item.desc}</small>`;
      }

      listitem.setAttribute("myid", item.id);

      list.appendChild(listitem);

    });
  }

  return list;

};

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Creates the object that will be used to render the e-mail
 * @param  {*} $my   [The e-mail config]
 * @param  {*} brand [The brand colors and style]
 * @param  {*} element   [The element]
 * @return {*}       [description]
 */
exports.renderEmail = function($my, brand, element) {

  var options = uae.ValidateOptions(element, required);
  if (options.success == false) {
    return options;
  } else {
    options = options.options;
  }

  var tablerow = document.createElement("tr");
  tablerow.setAttribute("border", 0);
  tablerow.setAttribute("cellpadding", 0);
  tablerow.setAttribute("cellpadding", 0);

  var tablecell = document.createElement("td");
  tablecell.setAttribute("style", `${$my.style.defaultcell} ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};`);

  var p = document.createElement("p");
  p.setAttribute("style", `${$my.style.padding} margin:0px; ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};`);
  p.innerHTML = options.inner;

  tablecell.appendChild(p);
  tablerow.appendChild(tablecell);

  return {
    success: true,
    orginal: uae,
    row: tablerow
  };

  // return {
  //   success: false,
  //   fault: "pending",
  //   error: "This part of the function is stll being written",
  //   original: uae
  // }

}
