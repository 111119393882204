var f = require("../scripts/f.js");
var uac = require("./templates/ua.control.js");

var formGroupShell = require("./templates/ua.formgroup.js");
var namespace = "ua.c.fg.newpassword";
var confirmPwd = "confirm";
exports.namespace = namespace;

exports.render = function (ele) {
 var options = uac.GetOptions(ele, []);

 //already appended
 if (!(options.uarendered === true)) {

  var input = document.createElement("input");
  //input.id = options.name;
  input.setAttribute("name", options.name);
  input.setAttribute("autocomplete", "new-password");
  input.setAttribute("type", "password");
  input.setAttribute("uaignore", "true");
  input.classList.add("form-control");
  input.classList.add("mb-3");

  if ("value" in options) {
    input.value = options.value;
  }

  input.willValidate = true;

  var input2 = document.createElement("input");
  //input2.id = options.name + "Confirm";
  input2.setAttribute("name", options.name + confirmPwd);
  input2.setAttribute("autocomplete", "new-password");
  input2.setAttribute("type", "password");
  input2.setAttribute("uaignore", "true");
  input2.classList.add("form-control");

  if ("value" in options) {
    input2.value = options.value;
  }

  input2.willValidate = true;

  var div = document.createElement("div");
  div.appendChild(input);
  div.appendChild(input2);

  formGroupShell.PassAtributes(ele, input);
  formGroupShell.PassAtributes(ele, input2);

  if ("placeholder" in options) {
    input2.setAttribute("placeholder", "Confirm: " + options.placeholder);
  } else {
    input2.setAttribute("placeholder", "Confirm");
  }

  formgroup = formGroupShell.CreateShell(options, div)

  input.addEventListener("input", ValidateInput);
  input2.addEventListener("input", ValidateInput);

  // ele.setAttribute("uarendered", true);
  // ele.appendChild(formgroup);
  formgroup.addEventListener("prepare", Prepare);
  return uac.Render_FinalStep(formgroup);

 }

}

function ValidateInput(event) {
  var control = this.closest("control");
  var options = uac.GetOptions(control);

  var input = control.querySelector("input[name='" + options.name + "']");
  var input2 = control.querySelector("input[name='" + options.name + confirmPwd + "']")

  if (!(input.value == input2.value)) {
    message = "Confirm your password. (confirm-mismatch)";
    input2.setCustomValidity(message);
  } else {
    input2.setCustomValidity("");
  }

  $jsonexport = formGroupShell.PrepareOutput(control);

}

function Prepare(event) {

  var control = this;
  var options = uac.GetOptions(control);

  var input = control.querySelector("input[name='" + options.name + "']");
  var jsonexport = formGroupShell.PrepareOutput(control, input.value);
  uac.StoreValue(control, jsonexport);

}
