/*!
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * (C) 2022-2024 Justin K Kazmierczak, The Universe, The EGT Universe, LLC. All Rights Reserved.
 * 
 * This notice may not be removed.
 * 
 * Subject to The Universe Terms of Service and Privacy Policy.
 * https://egtuniverse.com/legal
 * 
 * This is licensed to members in good standing of The Universe App Artisan Program.
 * 
 * Source code access and modifcation is subject to the terms of the license
 * and may not be shared or distributed without the express written consent of
 * The Universe. 
 * 
 * All instances of this software must be registered with The Universe.
 * Domains (and subdomains or wildcard domain) must be registered with The Universe.
 * 
 * Use source code responsibly.
 * 
 */


window.storage = [];

//load the log interface first
var log = require('./scripts/log.js');

//Console log args : _throwErrors, _useConsole, _silent = false, 
//  _useutil = true, _storage = true, _showtrace = false
log.init(true, true, false, false, false, false);

//get my config
var config = require("./config.js");
// log.info("Got config.", config);

// log.info("The Universe App Tools!", config.version);

//adds the ability to call the server as part of a single page API
var action = require("./interface/action.js");

//creates the module for handling the interface, elements and controls
var uaInterface = require("./interface/interface.js");

//Adds delgats for handling forms - automatically
var form = require("./scripts/formvalidation.js");

//Add the functions module
var f = require("./scripts/f.js");

//great the user
var hello = require('./license.js');

import {Alert, Button, Carousel, Collapse,
  Dropdown, Modal, Offcanvas, Tooltip, Toast,
  Popover, ScrollSpy, Tab } from 'bootstrap';

  window.bootstrap = {
    Alert, Button, Carousel, Collapse,
    Dropdown, Modal, Offcanvas, Tooltip, Toast,
    Popover, ScrollSpy, Tab
  };

  // console.log("The Bootstap report is as follows:", {
  //   Alert, Button, Carousel, Collapse,
  //   Dropdown, Modal, Offcanvas, Tooltip, Toast,
  //   Popover, ScrollSpy, Tab
  // });

//import 'bootstrap'; //imports current version module
// import '../node_modules/bootstrap/js/src/alert.js';npm inst
// import '../node_modules/bootstrap/js/src/button.js';
// import '../node_modules/bootstrap/js/src/carousel.js';
// import '../node_modules/bootstrap/js/src/collapse.js';
// import '../node_modules/bootstrap/js/src/dropdown.js';
// import '../node_modules/bootstrap/js/src/modal.js';
// import '../node_modules/bootstrap/js/src/popover.js';
// import '../node_modules/bootstrap/js/src/scrollspy.js';
// import '../node_modules/bootstrap/js/src/tab.js';
// import '../node_modules/bootstrap/js/src/toast.js';
// import '../node_modules/bootstrap/js/src/tooltip.js';
// import '../node_modules/bootstrap/js/src/util.js';

// function ua_tools_version() {
//   //starts the process for the ua_tools
//   log.info("The Universe App Tools Version:", config.version);
// }

//create the registry of interface items
var InterfaceRegistry = require('./registry.js');

action.Init();
// uaInterface.Init(InterfaceRegistry);

// var jsonRender = require('./interface/jsonRender.js');
// jsonRender.init(InterfaceRegistry);

var render = require('./interface/interface.js');
render.init(InterfaceRegistry);

var uae = require("./interface/element.js"); 

var jsonRender = require('./interface/jsonRender.js');
// render.test(); //test to make sure the JSON converstions are working...

var loading = require("./interface/loading.js");

//console.log("The log is as follows:", log.GetEntries());
//Add support for indexDB
// var indexDB = require('./indexdb.js'); //not finished so not included

//Build new window object
window.UATools = {
  PerformResponse: uaInterface.PerformResponse,
  PerformUAAction: action.PerformUAAction,
  ShowErrorMessage: uaInterface.ShowErrorMessage,
  cDom: jsonRender.convertDOM,
  c: convertStringToDomToJSON,
  render: jsonRender.render,
  after: uaInterface.AfterRender,
  resolve: uaInterface.resolveURL,
  element: uae,
  get: uaInterface.getObject,
  events: require("../../uam/errors.js"),
  loading: loading
  // RenderInterface: uaInterface.RenderInterface
};

/**
 * Converts a HTML string to a JSON object.
 * @param {*} htmlString The html string to convert to a JSON object.
 * @returns The JSON object.
 */
function convertStringToDomToJSON (htmlString) {
  var div = document.createElement('div');
  div.setAttribute("response", true);
  div.innerHTML = htmlString.trim();
  return jsonRender.convertDOM(div);
}

// window.UATools = uat;
// window.uat = uat;

/**
 * Becuase Apple's WebKit and Safari may have diffrent behaviors
 * than Google Chrome, we add a class to the body to
 * indicate that the browser is Safari. This will allow us to use
 * CSS to make corrections issues.
 */

if (navigator.userAgent.indexOf('AppleWebKit') != -1) {

  //does navigator contain "chrome"?
  if (navigator.userAgent.indexOf('Chrome') != -1) {
    // console.warn("Chrome detected. Running Chrome on ios may cause issues.")
  } else {
    document.body.classList.add('is-applewebkit');
  }
}

var loading = require('./interface/loading.js');

// console.log(navigator.userAgent); 

// start the loading
// loading.init();