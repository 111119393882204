/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

var jsonRender = require("../../uat/src/interface/jsonRender.js");
var uai = require("../../uat/src/interface/interface.js");
var events = require("../../uam/events.js");
var shopifySearch = require("../../uam/shopify.search.js");
var shopifyCart = require("../../uam/shopify.cart.js");
var sleep = require("../../uam/functions/sleep.js").function;
 
var define = {  
  namespace: "luxe.cart",  
  title: "Shows the cart and provides inertaction with it.",
  description: "Displays the results of a search.",
  fields: {
  },
  supportServer: false
}; module.exports.define = define; 
 
/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
async function render(options) {

  return [""];

} module.exports.render = render;   

async function Start() {
  // console.log("It's a cart!");

  // await sleep(500);

  //rebuild search object by getting uae [namespace="luxe.search"] and getting the values of the fields
  var uae = document.querySelector(`uae [namespace="${define.namespace}"]`);

  if (uae) { 

    var toRender = {};
    
    try {
      // if ("cart" in options) {
      //   return await RenderCart(options.cart);
      // } else {
        toRender = await RenderCart(await shopifyCart.get());
      // }
    } catch (error) {
      console.error("Error rendering cart: ", error);
      toRender = {
        n: "luxe.error",
        error: error,
        type: "cart.error"
      }
    }

    //render the object
    var rendered = await jsonRender.render(toRender);
    uae.innerHTML = "";
    uae.appendChild(rendered);

  }
}


var _onTry = 0;
/**
 * Start the application.
 * Waits for the UATools to load.
 */
async function Startup() {

  try {
    void 0 === window.UATools;

    if (void 0 === window.UATools) {
        if (30 < ++_onTry) {
            console.error("Could not load UATools");
        } else {
            setTimeout(async function () {
                Startup();
            }, 1e3);
        }

        _onTry++;

    } else {
        // console.log("UATools Loaded");
        // window.UATools.loading.manual();
        
        // window.UATools.loading.show();
        //does the start function exist
        try {
          await Start();
        } catch (error) {
          
        }

        // window.UATools.loading.hide();

    }
  } catch (error) {

  }

} 

if (!(uai.server())) {
  Startup();
}

async function RenderCart(cart) { 
 
  console.log("Rendering Cart:", cart);

  if (cart.lines.edges.length === 0) {
    return {
      n: "luxe.error",
      type: "cart.empty"
    }
  };

  var checkoutBtn = {
    n: "div",
    c: "d-block text-end p-3",
    i: {
      n: "a",
      c: "btn btn-outline-primary bg-black bg-og-background-hover text-white text-black-hover col-12 col-md-6 col-lg-4",
      href: cart.checkoutUrl,
      i: [{ 
          n: "i",
          c: "bi bi-bag-check-fill",
          i: "&nbsp;"
      }, "Continue to Checkout"]
    }
  };

  var cost = {
    n: "div",
    c: "d-block row m-3 p-3 rounded bg-white text-black bg-og-black-hover text-white-hover",
    i: []
  };

  var costArr = [];

  //for each cart.cost property
  for (var key in cart.cost) {

    try {

      var title = key;

      console.log(`Amount of ${key}: `, cart.cost[key], cart.cost[key].amount, shopifySearch.conformPrice(cart.cost[key].amount));
      
       //change key
      switch (key) {
        case "subtotalAmount":
          title = "Subtotal";
          break;
        case "taxAmount":
          title = "Tax";
          break;
        case "totalTaxAmount":
          title = "Total Tax";
          break;
        case "shippingAmount":
          title = "Shipping";
          break; 
        case "dutyAmount":
          title = "Duty";
          break;
        case "discountAmount":
          title = "Discount";
          break;
        case "totalAmount":
          title = "Total";
          break;
      }

      costArr.push({
        key: key,
        title: title,
        amount: shopifySearch.conformPrice(cart.cost[key].amount)
      });

    } catch (error) {
      console.error("Error with cost: ", error);
    }
  };

  //output the costArr so that subtotal is first and total is last
  costArr = costArr.sort(function (a, b) {
    if (a.key === "subtotalAmount") {
      return -1;
    } else if (b.key === "subtotalAmount") {
      return 1;
    } else if (a.key === "totalAmount") {
      return 1;
    } else if (b.key === "totalAmount") {
      return -1;
    } else {
      return 0;
    }
  });

  for (var i = 0; i < costArr.length; i++) {
    cost.i.push({
      n: "div",
      c: "col-6 text-start d-inline-block",
      i: costArr[i].title
    }, {
      n: "div",
      c: "col-6 text-end d-inline-block",
      i: costArr[i].amount
    });
  }

  var altclasses = [
    {
      itemHolster: "bg-black text-white",
      itemSplit: "border-white",
      button: "btn-outline-white bg-white text-black"
    }, {
      itemHolster: "bg-white text-black",
      itemSplit: "border-black",
      button: "btn-outline-primary bg-black text-white"
    }
  ]

  var alt = 0;

  //for each result wrap it in a object with n: "lux.product.preview" and product: result
  var items = cart.lines.edges.map(function (lineItem) {

    alt = alt === 0 ? 1 : 0;

    var product = shopifySearch.simplifyProductData(lineItem.node.merchandise.product);
    console.log("Product: ", product);

    var deleteBtn = [{
      n: "div",
      c: "d-block text-end",
      i: {
        n: "button",
        "data-remove-item": lineItem.node.id,
        c: `btn ${altclasses[alt].button} col-4 col-lg-2 bg-og-secondary-hover border-white-hover text-white-hover`,
        i: [{ 
            n: "i",
            c: "bi bi-trash",
            "data-remove-item": lineItem.node.id,
            i: ""
        }]
      }
    }];

    var VariantString = [];

    var varLineItemSKU = [{
      n: "strong",
      i: "SKU: "
    }, lineItem.node.merchandise.sku + " "];
    VariantString.push(varLineItemSKU);

    for (var i = 0; i < lineItem.node.merchandise.selectedOptions.length; i++) {
      var varLineItem = [{
        n: "strong",
        i: lineItem.node.merchandise.selectedOptions[i].name + ": "
      }, lineItem.node.merchandise.selectedOptions[i].value + " "];

      VariantString.push(varLineItem);
    }


    var costz = lineItem.node.cost;
    

    return [{
      n: "div",
      c: `rounded col-12 cart-item-holster`,
      i: {
        n: "div",
        c: `rounded m-2 p-2 cart-item-hi ${altclasses[alt].itemHolster}`,
        i: [deleteBtn, 
          {
            n: "luxe.product.preview",
            product: product,
            type: "list",
            i: {
              n: "div",
              c: `row d-block border-top border-2 ${altclasses[alt].itemSplit}`,
              i: [{
                n: "p", 
                c: "text-center m-0 p-1",
                i: VariantString
              }, {
                n: "div",
                c: "row",
                i: [{
                  n: "div",
                  c: "col text-end p-0 m-0",
                  i: shopifySearch.conformPrice(costz.amountPerQuantity.amount)
                }, {
                  n: "div",
                  c: "col-2 text-center p-0 m-0 fw-bold",
                  i: "" + lineItem.node.quantity
                }, {
                  n: "div",
                  c: "col col-md-4 text-end p-0 m-0",
                  i: shopifySearch.conformPrice(costz.totalAmount.amount)
                }]
              }]
            }  
          }]
      }
    }];

  }); 

  var holster = [checkoutBtn, {
    n: "div",
    c: "row text-center p-1", 
    i: items
  }, cost, checkoutBtn]

  holster = {
    n: "div",
    c: "col-12 d-block text-center",
    i: {
      n: "div",
      c: "col-12 col-xl-8 mx-auto",
      i: holster
    }
  };

  console.log("The Luxe Cart ", holster);

  return holster;
}

//add an event listner to the remove item button
document.addEventListener("click", async function (event) {
  if (event.target.matches("[data-remove-item]")) {

    console.log("Remove Item: ", event.target.querySelector("[data-remove-item]"));

    var removeItem = event.target.getAttribute("data-remove-item");
    console.log("Remove Item: ", removeItem);

    try {
      var cart = await shopifyCart.remove(removeItem);
      var toRender = await RenderCart(cart);

      var uae = document.querySelector(`uae [namespace="${define.namespace}"]`);
      uae.innerHTML = "";
      uae.appendChild(await jsonRender.render(toRender));

    } catch (error) {
      console.error("Error removing item: ", error);
      HandleErrors(error);
    }
  }
});

function GenerateErrorMessage(title, message, showOtherProducts = true) {

  var err = {
    n: "div",
    c: "row text-center d-block",
    i: {
      n: "div",
      c: "tiny-outline m-3 p-3 bg-og-25-white text-black border-primary border-2 border rounded col-10 col-md-6 d-inline-block",
      i: [{
        n: "h1",
        c: "text-start ps-3 pe-3",
        i: title
      }, {
        n: "p",
        c: "text-start ps-3 pe-3",
        i: message
      }]
    }
  }

  return err;

}

