/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "validatePhoneNumber";
// var validateRegEx = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}/;

const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

/**
 * Validates a phone number.
 * Tests for specific formatting for use numbers of +1 (XXX) XXX-XXXX
 * and any country code of +XX XXXXXXXXXX
 * @param {*} phoneNumber The phone number to validate. 
 * @returns A boolean indicating if the phone number is valid.
 */
function validatePhoneNumber(phoneNumber) {
    // Regular expression to match any phone number
    // example sucess: 1 1234567890
    
    // Regular expression to match the US phone number format
    // const usPhoneNumberRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
    
    // Check if it's a valid phone number (any country)
    const isValidPhoneNumber = phoneNumberRegex.test(phoneNumber);
    
    // Check if it starts with '+1' and matches the US phone number format
    // const isUSPhoneNumber = phoneNumber.startsWith('+1') && usPhoneNumberRegex.test(phoneNumber);
    
    //if it's a valid phone number or a valid US phone number, return true
    if (isValidPhoneNumber) {
        return true;
    } else {
        return false;
    }

}


module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: validatePhoneNumber,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            var validPhones = ["+11234567890"];
            var invalidPhones = ["+1 not a phone"]
            var success = true;

            for(var i in validPhones) {
                if (!(validatePhoneNumber(validPhones[i]))) {
                    console.log("Failed (isValid)?", validPhones[i]);
                    success = false;
                }
            }

            for(var i in invalidPhones) {
                if (validatePhoneNumber(invalidPhones[i])) {
                    console.log("Failed (not valid?)", invalidPhones[i]);
                    success = false;
                }
            }

            // console.log("Success", success);
            return success;

        }
    }]
};