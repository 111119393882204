var f = require("../scripts/f.js");
var uac = require("./templates/ua.control.js");

var formGroupShell = require("./templates/ua.formgroup.js");
var namespace = "ua.c.fg.password";
exports.namespace = namespace;

exports.render = function (ele) {
 var options = uac.GetOptions(ele, []);

 //already appended
 if (!(options.uarendered === true)) {

  var input = document.createElement("input");
  //input.id = options.name;
  input.setAttribute("name", options.name);
  input.setAttribute("autocomplete", "current-password");
  input.setAttribute("type", "password");
  input.setAttribute("uaignore", "true");
  input.classList.add("form-control");

  if ("value" in options) {
    input.value = options.value;
  }

  input.willValidate = true;

  formGroupShell.PassAtributes(ele, input)
  formgroup = formGroupShell.CreateShell(options, input)

  // ele.setAttribute("uarendered", true);
  // ele.appendChild(formgroup);
  formgroup.addEventListener("prepare", Prepare);
  return uac.Render_FinalStep(formgroup);

 }

}

function Prepare(event) {
  var control = this;
  var jsonexport = formGroupShell.PrepareOutput(control);
  uac.StoreValue(control, jsonexport);
}

/*
  Provides the required options
 */
function GetOptions(ele) {
  rtn = {
    //the name of the input field
    name: ele.getAttribute("name"),
    title: ele.getAttribute("title"),
    value: ele.getAttribute("value"),
    placeholder: ele.getAttribute("placeholder"),
    //Have I already been rendered
    uarendered: ele.getAttribute("uarendered")
  }

  //required items for the control or die
  if (
    (rtn.name === null) ||
    (rtn.title === null) ||
    (rtn.autocomplete === null)
  ) {
    throw namespace + ": Missing required attribut.";
  }

  //console.log(namespace + ": Options: ", rtn, ele);
  return rtn;
}

function GetValidityMessage(input) {
  return input.validationMessage;
}
