var f = require("../scripts/f.js");
var uac = require("./templates/ua.control.js");

var formGroupShell = require("./templates/ua.formgroup.js");
var namespace = "ua.fg.address";
var confirmPwd = "confirm";
exports.namespace = namespace;

exports.render = function (ele) {
 var options = uac.GetOptions(ele, []);

 //already appended
 if (!(options.uarendered === true)) {

  var holster = document.createElement("control");

  var maintitle = document.createElement("p");
  maintitle.classList.add("p-2");
  maintitle.classList.add("control-title");
  maintitle.innerText = options.title;
  formGroupShell.PassAtributes(ele, maintitle);
  holster.appendChild(maintitle);

  var quickname = document.createElement("input");
  var quickname_options = {
    "title": "Address Nickname",
    "name": "quickname",
    "placeholder": "My home or My office",
    "autocomplete": "off",
    "min": 3,
    "required": true,
    "uaignore": true,
    "type": "text",
    "icon": "bi-person-lines-fill"
  };

  uac.PassOptions(quickname_options, quickname);
  quickname.classList.add("form-control");
  quickname.classList.add("mb-3");
  quickname.willValidate = true;

  if ("quickname" in options) {
    quickname_options.value = options.quickname;
  }

  var quickname_formgroup = formGroupShell.CreateShell(quickname_options, quickname);
  holster.appendChild(quickname_formgroup);

  var address = document.createElement("textarea");
  var address_options = {
    "title": "Street Address: Line 1 - 4",
    "name": "streetaddress",
    "placeholder": "To the office of&#10;123 street str",
    "autocomplete": "street-address",
    "min": 3,
    "required": true,
    "uaignore": true
  };
  uac.PassOptions(address_options, address);
  address.classList.add("form-control");
  address.classList.add("mb-3");
  address.willValidate = true;

  var formgroup = formGroupShell.CreateShell(address_options, address);
  holster.appendChild(formgroup);
  formgroup.addEventListener("prepare", Prepare);
  
  return uac.Render_FinalStep(holster);

  }
};

function ValidateInput(event) {
  var control = this.closest("control");
  var options = uac.GetOptions(control);

  var input = control.querySelector("input[name='" + options.name + "']");
  var input2 = control.querySelector("input[name='" + options.name + confirmPwd + "']");

  if (input.value !== input2.value) {
    message = "Password confirmation failed.";
    input.setCustomValidity(message);
  } else {
    input.setCustomValidity("");
  }

  $jsonexport = formGroupShell.PrepareOutput(control);

}

function Prepare(event) {

  var control = this;
  var options = uac.GetOptions(control);

  var input = control.querySelector("input[name='" + options.name + "']");
  var jsonexport = formGroupShell.PrepareOutput(control, input.value);
  uac.StoreValue(control, jsonexport);

}

exports.help = {
  namesapce: namespace,
  title: "Address",
  template: "formgroup",
  description: "A physical address",
  ...formGroupShell.helpTemplate
}