/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Recursively converts short hand object name to complete object name.
 * 
 */

var namespace = "combineObjects";

/**
 * Combines the two objects (recursively), letting the second object override the first.
 * Combines arrays in the object.
 * @param {*} obj1 The first object. (byref)
 * @param {*} obj2 The second object. (byref)
 * @returns {*} The combined object.
 */
function combineObjects(obj1, obj2) {

    var newObj = {...obj1};

    for (var key in obj2) {
        if (Array.isArray(obj2[key])) {
            //combine arrays
            if (key in newObj) {

                //for each item in the array, does it already exist in the new object?
                for (var i = 0; i < obj2[key].length; i++) {
                    if (!newObj[key].includes(obj2[key][i])) {
                        newObj[key].push(obj2[key][i]);
                    }
                }

                // newObj[key] = [...obj2[key], ...newObj[key]];
            } else {
                newObj[key] = obj2[key];
            }

        } else if (typeof obj2[key] == "object") {
            if (key in newObj) {
                newObj[key] = combineObjects(newObj[key], obj2[key]);
            } else {
                newObj[key] = obj2[key];
            }
        } else {
            newObj[key] = obj2[key];
        }
    }

    return newObj;

}


module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: combineObjects,
    tests: [{
        namespace: `${namespace}.test`,
        must: true,
        run: async () => {
            
            var test1 = {
                a: 1,
                b: 2,
                c: {
                    d: 3,
                    e: 4
                }
            };

            var test2 = {
                a: 5,
                c: {
                    d: 7,
                    f: {
                        g: 9
                    }
                }
            };

            var combineObj = combineObjects(test1, test2);

            var isCombined = true;

            if (combineObj.a != 5) {
                isCombined = false;
            }

            if (combineObj.b != 2) {
                isCombined = false;
            }

            if (combineObj.c.d != 7) {
                isCombined = false;
            }

            if (combineObj.c.e != 4) {
                isCombined = false;
            }   

            if (combineObj.c.f.g != 9) {
                isCombined = false;
            }

            return isCombined;

        }
    }] 
};