var namespace = "shopify.api";
var uai = require("../uat/src/interface/interface.js");
var shuffleArray = require("../uam/functions/shuffleArray.js").function;


var myFetch = fetch;
// if (uai.server()) {
//   myFetch = require("node-fetch");
// } else {
//   myFetch = fetch;
// }

/* no leading "/" */
// var local = "https://accounts.egtuniverse.com";
// local = "http://10.5.0.7:8518";

// var $ = require("./scafolding");
// var webapp = require("./webapp.js");
// var simple = require("./simpleCrypto.js");
var validate = require("./functions/validate.js").function;

var _searchable = {
  collections: [],
  designers: [],
  types: [],
  tags: [],
  knownProductTypes: []
};

/**
 * Search's the searchable collections for a collection by name.
 * @param {*} name The name of the collection.
 * @returns The collection.
 */
function FindCollection(name) {
  return _searchable.collections.find(item => item.title === name);
} module.exports.FindCollection = FindCollection;

/**
 * Search's the searchable designers for a designer by name.
 * @param {*} name The name of the designer.
 * @returns The designer.
 */
function FindDesigner(name) {
  return _searchable.designers.find(item => item.name === name);
} module.exports.FindDesigner = FindDesigner;

/**
 * Search's the searchable types for a type by name.
 * @param {*} name The name of the type.
 * @returns The type.
 */
function FindType(name) {
  return _searchable.types.find(item => item.name === name);
} module.exports.FindType = FindType;

/**
 * Search's the searchable types for a type by handle.
 * @param {*} handle The handle of the type.
 * @returns The type.
 */
function FindTypeByHandle(handle) {
  return _searchable.types.find(item => item.handle === handle);
} module.exports.FindTypeByHandle = FindTypeByHandle;

/**
 * Search's the searchable tags for a tag by handle.
 * @param {*} handle The handle of the tag.
 * @returns The tag.
 */
function FindTagByHandle(handle) {
  return _searchable.tags.find(item => item.handle === handle);
} module.exports.FindTagByHandle = FindTagByHandle;

/**
 * Search's the searchable tags for a tag by name.
 * @param {*} name The name of the tag.
 * @returns The tag.
 */
function FindTag(name) {
  //strip quotes
  // name = name.replace(/['"]+/g, '');
  return _searchable.tags.find(item => item.name === name);
} module.exports.FindTag = FindTag;

try {
  _searchable = {
    collections: require("../.store/searchable/collections.json"),
    designers: require("../.store/searchable/designers.json"),
    types: require("../.store/searchable/types.json"),
    tags: require("../.store/searchable/tags.json"),
    knownProductTypes: require("../.store/known-product-types.json")
  };
  // console.log("Searchables", searchable);
} catch (error) {
  console.log("Unable to add searchable JSON.", error);
}

// "Variable $searchableFields of type [SearchableField!] was provided invalid value for 0 (Expected "title" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 1 (Expected "product_type" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 2 (Expected "vendor" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 3 (Expected "tag" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 4 (Expected "body" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 5 (Expected "tag" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 6 (Expected "variants_barcode" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR), 7 (Expected "variants_sku" to be one of: AUTHOR, BODY, PRODUCT_TYPE, TAG, TITLE, VARIANTS_BARCODE, VARIANTS_SKU, VARIANTS_TITLE, VENDOR)"

var textSearchableFields = [
  "TITLE",
  "PRODUCT_TYPE",
  "VENDOR",
  "TAG",
  "BODY",
  "TAG",
  "VARIANTS_BARCODE",
  "VARIANTS_SKU",
  "VARIANTS_TITLE"
];

// var keys = require("./keys.js");
// keys.add({
//     namespace: `${namespace}`,
// });

var errors = require("./errors.js").create(namespace);
errors.addErrors([{
    id: "unknownError",
    title: "An API Call failed.",
    description: "Unable to work with shopify (api error)."
}, {
  id: "noResults",
  title: "No Results",
  description: "No results were found for your search. Try expanding your search and try again."
}, {
  id: "unauthorized",
  title: "Unauthorized",
  description: "This application does not have permission to access the requested resource. If the problem is due to normal operation, please contact the app's owner or The Universe."
}, {
  id: "forbidden",
  title: "Forbidden",
  description: "You do not have permission to access this resource. It's possible you have been throttled, or your using a VPN that may not have access to this resource. If your using Apple Relay, you can toggle your wi-fi to connect to a new server or disable it and try again."
}, {
  id: "notFound",
  title: "Not Found",
  description: "The requested resource was not found."
}, {
  id: "serverError",
  title: "Server Error",
  description: "The shopify servers encountered an error while processing your request."
}, {
  id: "badRequest",
  title: "Invalid Request",
  description: "The server responded with some errors in your request. Contact support or revise your search and try again."
}, {
  id: "relevantProductsError",
  title: "I couldn't find additional styles",
  description: "Our inventory system failed to collect additional styles based on recommendations. Either the system encountered an error, or all relevant products have sold out."
}, {
  id: "unknownTag",
  title: "Unknown Tag",
  description: "Our inventory system failed to locate additional styles based on the request tag. Either the system encountered an error, or all relevant products have sold out."
}]);

// var $ = require("./scafolding");
var infoAPI = require("../.keys/shopify.api.json");

// /** 
//  * Initialize the Shopify API and loads its files.
//  * @param {*} _$ The Scafolding object.
//  */
// function init(_$) {
//   $ = _$;
//   keys.init($);

//   // infoAPI = requir

//   console.log("Shopify API Info", infoAPI);
// } module.exports.init = init;

/**
 * Returns the GraphQL endpoint for the Shopify Storefront API.
 * Supports API Version 2021-10.
 * @returns The GraphQL endpoint. https://shopify.dev/docs/storefront-api/reference
 */
function getUri() {
  return `https://${infoAPI.storefront.domain}/api/2021-10/graphql.json`;
} module.exports.getUri = getUri;

var conformPrice = require("../uam/functions/conformCurrencyEcommerce.js").function;
module.exports.conformPrice = conformPrice;

// /**
//  * Conforms a price to a standard format.
//  * @param {*} price The price to conform.
//  * @returns The price in a standard format.
//  */
// function conformPrice(price) {
//   //make price a $ 0.00 amount

//   var price = parseFloat(price);
//   //add commas to the price
//   price = price.toFixed(2);
//   return `$ ${price}`;
// } module.exports.conformPrice = conformPrice;

/**
 * Conforms a shopify product to a simplified format, for easy templating
 * @param {*} product The product to simplify.
 * @returns The simplified product.
 */
function simplifyProductData(product) {
  const mediaImages = [];
  const mediaVideos = [];
  const mediaModels = [];
  const mediaExternalVideos = [];


  try {
    product.media.edges.forEach((edge, index) => {
      
      try {
        const media = edge.node;
      let mediaItem = {
          src: media.image ? media.image.src : null,
          altText: media.altText || '',
          position: index // Maintain the order with a position index
      };

      switch (media.__typename) {
          case 'MediaImage':
              mediaImages.push(mediaItem);
              break;
          case 'Video':
              mediaVideos.push({
                  ...mediaItem,
                  sources: media.sources.map(source => ({
                      url: source.url,
                      mimeType: source.mimeType
                  })),
                  poster: media.previewImage ? media.previewImage.src : null // Add thumbnail image
              });
              break;
          case 'Model3d':
              mediaModels.push({
                  ...mediaItem,
                  sources: media.sources.map(source => ({
                      url: source.url,
                      mimeType: source.mimeType
                  }))
              });
              break;
          case 'ExternalVideo':
              mediaExternalVideos.push({
                  ...mediaItem,
                  embeddedUrl: media.embeddedUrl,
                  previewImage: media.previewImage ? media.previewImage.src : null // Add thumbnail image
              });
              break;
      }
      } catch (error) {
        
      }

  });
  } catch (error) {
    // console.log("No Media Found", error);
  }



  const simplifiedVariants = product.variants.edges.map(edge => edge.node);
  const simplifiedCollections = product.collections.edges.map(edge => edge.node.title);

  // Get unique types
  const optionsByType = {};

  var skus = [];

  for (var i = 0; i < simplifiedVariants.length; i++) {
      var variant = simplifiedVariants[i];
      // console.log("Variant", variant);

      if (!(variant.availableForSale)) {
          continue;
      }

      variant.selectedOptions.forEach(option => {
          optionsByType[option.name] = optionsByType[option.name] || new Set();
          optionsByType[option.name].add(option.value);
      });

      skus.push(variant.sku);

  }

  // simplifiedVariants.forEach(variant => {
  //     variant.selectedOptions.forEach(option => {

  //         if (!(option.availableForSale)) {
  //             return;
  //         }

  //         optionsByType[option.name] = optionsByType[option.name] || new Set();
  //         optionsByType[option.name].add(option.value);

  //     });
  // });

  // get the lowest price

  try {
    
      const lowestPrice = simplifiedVariants.reduce((lowest, variant) => {
        const price = parseFloat(variant.priceV2.amount);
        return price < lowest ? price : lowest;
    }, Infinity);

    // Add the lowest price to the product
    product.startingPrice = conformPrice(lowestPrice);
    product.normalPrice = lowestPrice;
  } catch (error) {
    // console.log("No Price Found", error);

    product.startingPrice = "$0.00";
    product.normalPrice = 0.00;
    
  }

  // const lowestPrice = simplifiedVariants.reduce((lowest, variant) => {
  //     const price = parseFloat(variant.priceV2.amount);
  //     return price < lowest ? price : lowest;
  // }, Infinity);

  // // Add the lowest price to the product
  // product.startingPrice = conformPrice(lowestPrice);
  // product.normalPrice = lowestPrice;

  // Add the skus to the product
  product.skus = skus;

  //conform the title
  product.orginalTitle = product.title;

  //remove the last word from the title
  product.title = product.title.split(" ").slice(0, -1).join(" ");

  //check if the designer is in the title, if it is check if "by " is before it
  var designer = FindDesigner(product.vendor);
  if (designer) {
    if (product.title.includes(designer.name)) {
      if (product.title.includes("by ")) {
        product.title = product.title.replace("by ", "");
      }
    }
  }

  // Convert Set to array for each option type
  const optionsByTypeArray = {};
  for (const [key, value] of Object.entries(optionsByType)) {
      optionsByTypeArray[key] = Array.from(value);
  }

  const simplifiedMedia = {
      images: mediaImages,
      videos: mediaVideos,
      models: mediaModels,
      externalVideos: mediaExternalVideos
  };

  var addlProps = {
    ageGroup: false,
    gender: false,
    category: false
  }

  //find the known product type
  var type = _searchable.knownProductTypes.find(item => item.name === product.productType);

  if (type) {
    if ("ageGroup" in type) {
      addlProps.ageGroup = type.ageGroup;
    }

    if ("gender" in type) {
      addlProps.gender = type.gender;
    }

    if ("category" in type) {
      addlProps.category = type.category;
    }

  }

  //if any addlProps are true add them to the product
  for (const [key, value] of Object.entries(addlProps)) {
    if (value) {

      if (!("google" in product)) {
        product.google = {};
      }

      product.google[key] = value;

      // product[key] = value;
    }
  }


  return {
      ...product,
      media: simplifiedMedia,
      variants: simplifiedVariants,
      collections: simplifiedCollections,
      optionsByType: optionsByTypeArray // Use the array-converted options
  };
} module.exports.simplifyProductData = simplifyProductData;


var varDefaultFields = `
id
title
handle
description
descriptionHtml
productType
publishedAt
updatedAt
availableForSale
createdAt
publishedAt
totalInventory
vendor
tags
trackingParameters
seo {
  title
  description
}
priceRange {
  minVariantPrice {
    amount
    currencyCode
  }
  maxVariantPrice {
    amount
    currencyCode
  }
}
collections(first: 250) {
  edges {
    node {
      title
      handle
      id
      description
      descriptionHtml
      image {
        src
        altText
      }
      trackingParameters
      updatedAt
    }
  }
}
options(first: 250) {
  id
  name
  values
}
media(first: 250) {
  edges {
    node {
      __typename
      ... on MediaImage {
        image {
          src
          altText
        }
      }
      ... on Video {
        sources {
          url
          mimeType
        }
        previewImage {
          src
          altText
        }
      }
      ... on Model3d {
        sources {
          url
          mimeType
        }
      }
      ... on ExternalVideo {
        embeddedUrl
        previewImage {
          src
          altText
        }
      }
    }
  }
}
variants(first: 250) {
  edges {
    node {
      id
      title
      sku
      availableForSale
      priceV2 {
        amount
        currencyCode
      }
      selectedOptions {
        name
        value
      }
    }
  }
}`; module.exports.varDefaultFields = varDefaultFields;
module.exports.productVars = varDefaultFields;

/**
 * Returns the default fields for a product.
 * @returns The default fields for a product.
 */
function productDefaultFields() {
  return varDefaultFields;
} module.exports.productDefaultFields = productDefaultFields;

/**
 * Returns all the products and vendors in your shopify store using the Shopify Storefront API.
 * @returns An object with all the products and vendors.
 */
function searchable() {
  return _searchable;
} module.exports.searchable = searchable;

//create a productcollectionsortkeys
var productCollectionSortKeys = {
  COLLECTION_DEFAULT: "COLLECTION_DEFAULT",
  PRICE: "PRICE",
  TITLE: "TITLE",
  BEST_SELLING: "BEST_SELLING",
  CREATED_AT: "CREATED_AT",
  RELEVANT: "RELEVANT"
};

// function SetCollectionsForQuery(collections) {

//   var rtn = "";

//   //if collection is not an array make it one
//   if (!Array.isArray(collections)) {
//     collections = [collections];
//   }

//   //for each collection find it's id from _searchable.collections
//   for (let i = 0; i < collections.length; i++) {
//     //find the collection
//     var collection = _searchable.collections.find(item => item.handle === collections[i]);
//     if (collection) {
//       if (i === 0) {
//         rtn = `collection_id:${collection.id}`;
//       } else {
//         rtn += ` AND collection_id:${collection.id}`;
//       }
//     }
//   }
  
//   rtn = `${rtn}`;
//   return rtn;

// }

/**
 * Returns all the collections in your shopify store using the Shopify Storefront API.
 * @param {*} type The query field to search.
 * @param {boolean} addByAnd The operator will be AND if true or OR if false.
 * @param {*} values The string (or array) of values to search for.
 */
function SetForQuery(type, addByAnd, values) {

  var rtn = "";

  //if values is not an array make it one
  if (!Array.isArray(values)) {
    values = [values];
  }

  //for each collection find it's id from _searchable.collections
  for (let i = 0; i < values.length; i++) {
    //find the collection
    var value = values[i];
    if (value) {
      if (i === 0) {
        rtn = `${type}:${value}`;
      } else {
        rtn += ` ${addByAnd} ${type}:${value}`;
      }
    }
  }

  if (addByAnd) {
    rtn = `${rtn} AND`;
  } else {
    rtn = `${rtn} OR`;
  }

  // rtn = `(${rtn})`;
  return rtn;

}


// var generateHash = require("./functions/generateHash.js").function;
// module.exports.generateHash = generateHash;
// /**
//  * Generates a hash from an object.
//  * @param {*} product The object to hash.
//  * @returns The hash.
//  */
// async function generateHash(product) {
//   var toHash = JSON.stringify(product).trim();
//   return await hashString(toHash);
// } module.exports.generateHash = generateHash;

// /**
//  * Generates a hash from a string.
//  * @param {*} str The string to hash.
//  * @returns The hash.
//  */
// async function hashString(str) {
//     let hash = 0;

//     if (str.length === 0) return hash;

//     for (let i = 0; i < str.length; i++) {
//         const char = str.charCodeAt(i);
//         hash = ((hash << 5) - hash) + char;
//         hash = hash & hash; // Convert to 32bit integer
//     }

//     return hash;
// } module.exports.hashString = hashString;

// var collections = require("../.store/searchable/collections.json");

/**
 * Searches all the products in your shopify store using the Shopify Storefront API.
 * Warning: if you have a product - it will only return that product.
 * Warning: if you have a collection - it will only return products in that collection.
 * In that order.
 * @param {*} search An object of search parameters.
 * @property {String} search.text A text search query.
 * @property {Array} search.collection An array of collections, infoAPI.
 * @property {Array} search.vendor An array of vendor names, infoAPI.
 * @property {Array} search.productType A array of product types, infoAPI.
 * @property {Array} search.type A array of product types, infoAPI as productType.
 * @property {Array} search.tag An array of product tags, infoAPI.
 * @property {String} search.product The product id.
 * @property {String} search.relevantproducts The product id to find relevant products for.
 * @property {Number} search.limit The number of products to limit to default is 250.
 * @property {Number} search.show The number of products to show. 
 * @property {Boolean} search.hideNoMedia If true, hides products with no media. default is true.
 * @property {String} search.allowCursor If true, allows pagination. default is true.
 * @property {String} search.sort A sort order. Must be a productCollectionSortKeys. default is: 'newest'
 * @property {Array|String} search.excludeTags An array of tags to exclude.
 * available options are: 'price', 'newest', 'older', 'title', 'vendor', 'best_selling', 'relevant', 'random', 'randomize'.
 * @property {String} search.sortType A sort order. Options are 'asc', 'desc'. default is 'asc'.
 * @returns An array of products.
 */
async function search(search) {

  // console.log("Searching for...", search);

  if ("excludeTags" in search) {
    //if it is a string make it an array
    if (!(Array.isArray(search.excludeTags))) {
      search.excludeTags = [search.excludeTags];
    }
  }

  // let hasNextPage = true;
  let allProducts = [];

  var mySearch = {
    hasNextPage: true,
    sort: {
      key: "CREATED_AT",
      reverse: true
    },
    queryable: ["available_for_sale:true"],
    limit: 250,
    show: 250,
    allowCursor: true,
    cursor: null,
    hideNoMedia: true,
    randomize: false,
    filterQuery: "",
  };

  if ("limit" in search) {
    mySearch.limit = search.limit;
  }

  if ("randomize" in search) {
    mySearch.randomize = search.randomize;
  }

  if ("text" in search) {
    mySearch.text = search.text;
  }

  if ("show" in search) {
    mySearch.show = search.show;
    mySearch.allowCursor = false;
  }

  if ("hideNoMedia" in search) {
    mySearch.hideNoMedia = search.hideNoMedia;
  }

  if ("collection" in search) {
    mySearch.collection = search.collection;
  }

  if ("designer" in search) {
    mySearch.queryable.push(SetForQuery("vendor", false, search.designer));
  }

  if ("vendor" in search) {
    mySearch.queryable.push(SetForQuery("vendor", false, search.vendor));
  }

  if ("type" in search) {

    //find type by handle
    var type = FindTypeByHandle(search.type);
    mySearch.queryable.push(SetForQuery("product_type", false, type.name));

  }

  if ("productType" in search) {

    //find a product by it

    mySearch.queryable.push(SetForQuery("product_type", false, search.productType));
  }

  if ("tag" in search) {


    //if it is a string make it an array
    if (!(Array.isArray(search.tag))) {
      search.tag = [search.tag];
    }

    for (let i = 0; i < search.tag.length; i++) {
      //find the tag by handle
      var tag = FindTagByHandle(search.tag[i]);

      try {
        mySearch.queryable.push(SetForQuery("tag", false, tag.name));
      } catch (error) {
        errors.unknownTag.throw(); 
      }
    }

  }

  // if ("excludeTag" in search) {
  //   //find the tag by handle
  //   for (let i = 0; i < search.excludeTag.length; i++) {
  //     var tag = FindTagByHandle(search.excludeTag[i]);
  //     mySearch.queryable.push(SetForQuery("tag", false, tag.name));
  //   }
  // }

  if ("product" in search) {
    mySearch.product = search.product;
  }

  if ("relevantproducts" in search) {
    mySearch.relevantproducts = search.relevantproducts;
  }

  //add sort
  if ("sort" in search) {
    
    //if no sort type
    if (!("sortType" in search)) {
      search.sortType = "asc";
    }

    if (search.sortType === 'desc') {
      mySearch.sort.reverse = true;
    }

    //make search.sort lowercase

    // Handle sorting
    switch (search.sort) {
      case 'price':
        mySearch.sort.key = 'PRICE';
        break;
      case 'newest':
        mySearch.sort.key = 'CREATED_AT';
        mySearch.sort.reverse = true;
        break;
      case 'older':
        mySearch.sort.key = 'CREATED_AT';
        mySearch.sort.reverse = false;
        break;
      case 'title':
        mySearch.sort.key = 'TITLE';
        break;
      case 'vendor':
        mySearch.sort.key = 'VENDOR';
        break;
      case 'best_selling': 
        mySearch.sort.key = 'BEST_SELLING'; 
        break; 
      case 'relevant': 
        mySearch.sort.key = 'RELEVANCE';
        break;
      case 'random':
      case 'randomize':
        mySearch.randomize = true;
        break;
    }

  }
 
  // console.log("Supported Search", mySearch);

  let query = ``;

  if ("text" in mySearch) {

    query = `
    query SearchQuery($query: String!, $first: Int, $cursor: String) {
      search(query: $query, first: $first, after: $cursor) {
        edges {
          cursor
          node {
            __typename
            ... on Product {
              ${varDefaultFields}
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
    `;

  //   query = `
  //    this should have worked but didn't
  //   query PredictiveSearchQuery($query: String!, $searchableFields: [SearchableField!]) {
  //     predictiveSearch(query: $query, searchableFields: $searchableFields) {
  //       products {
  //         ${varDefaultFields}
  //       }
  //     }
  //   }
       
  // `;

  } else if ("product" in mySearch) {

    //query by product id
    query = `
      query ProductById($productId: ID!) {
        product(id: $productId) {
          ${varDefaultFields}
        }
      }
    `;

  } else if ("relevantproducts" in mySearch) {
    query = `
    query RelevantProducts($productId: ID!) {
      productRecommendations(productId: $productId) {
         ${varDefaultFields}
        }
      }
    `;

  } else if ("collection" in mySearch) {
    query = `
    query ProductsByCollectionHandle($collectionHandle: String!, $cursor: String, $sortKey: ProductCollectionSortKeys, $reverse: Boolean) {
      collectionByHandle(handle: $collectionHandle) {
        products(first: ${mySearch.limit}, after: $cursor, sortKey: $sortKey, reverse: $reverse) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              ${varDefaultFields}
            }
          }
        }
      }
    }
  `;

    //convert ProductSortKeys to ProductCollectionSortKeys
    switch (mySearch.sort.key) {
      case "PRICE":
        mySearch.sort.key = "PRICE";
        break;
      case "TITLE":
        mySearch.sort.key = "TITLE";
        break;
      case "BEST_SELLING":
        mySearch.sort.key = "BEST_SELLING";
        break;
      case "CREATED_AT":
        mySearch.sort.key = "CREATED";
        break;
      case "RELEVANT":
        mySearch.sort.key = "RELEVANCE";
        break;
      default:
        mySearch.sort.key = "COLLECTION_DEFAULT";
        break;
    }

  } else {
    query = `
      query AllProducts($cursor: String, $sortKey: ProductSortKeys, $reverse: Boolean, $filterQuery: String) {
          products(first: ${mySearch.show}, after: $cursor, sortKey: $sortKey, reverse: $reverse, query: $filterQuery) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                ${varDefaultFields}
              }
            }
          }
      }
    `;

    //show only available products
    // mySearch.queryable.push("available:true");
  }

  //remove the last and or last or in the queryable
  if (mySearch.queryable.length > 0) {
    mySearch.filterQuery = mySearch.queryable.join(" ");
  }

  //remove the last "AND" or "OR" from the queryable
  if (mySearch.filterQuery) {
    mySearch.filterQuery = mySearch.filterQuery.replace(/(AND|OR)$/, "");
  }

  // console.log(`Query`, mySearch);


  while (mySearch.hasNextPage) {
    const req = {
      query, // This should be a string containing your GraphQL query
      variables: {
        cursor: mySearch.cursor,  // Pass the current cursor for pagination
        sortKey: mySearch.sort.key,  // The key to sort the products
        reverse: mySearch.sort.reverse,  // Boolean indicating the sort direction
        // join the queryable by AND
        filterQuery: mySearch.filterQuery
      }
    };

    if ("text" in mySearch) {
      req.variables = {
        query: mySearch.text,
        first: mySearch.show,
        cursor: mySearch.cursor,
        // searchableFields: textSearchableFields
      };
    } else if ("product" in mySearch) {
      req.variables = {
        productId: mySearch.product
      };
    } else if ("relevantproducts" in mySearch) {
      req.variables = {
        productId: mySearch.relevantproducts
      };
    } else if ("collection" in mySearch) {
      req.variables.collectionHandle = mySearch.collection;
    }


    try {
      window.lastQuery = req;
    } catch (error) {
      
    }

    // console.log("Request", req);

    const response = await RequestFrom(req);

    if ("product" in mySearch) {
      //if we are searching for a product and response.data.products is null, throw an error
      if (!(response.data.product)) {
        throw errors.noResults.throw();
      }
    }

    // console.log("Response", response);

    // var collectionz = response.data.collection;

    //if collection is null no results
    // if (collectionz === null) {
    //   throw errors.noResults.throw();
    // }

    // break;

    var edges = {};

    if ("text" in mySearch) {
      edges = response.data.search.edges;
      mySearch.hasNextPage = response.data.search.pageInfo.hasNextPage;
      mySearch.cursor = response.data.search.pageInfo.endCursor;
      allProducts = allProducts.concat(edges.map(edge => edge.node));
    } else if ("product" in mySearch) {
      edges = response.data.product;
      mySearch.hasNextPage = false;
      allProducts = edges;
    } else if ("relevantproducts" in mySearch) {
      edges = response.data.productRecommendations;
      mySearch.hasNextPage = false;
      allProducts = edges;
    } else if ("collection" in mySearch) {
      edges = response.data.collectionByHandle.products.edges;
      mySearch.hasNextPage = response.data.collectionByHandle.products.pageInfo.hasNextPage;
      mySearch.cursor = response.data.collectionByHandle.products.pageInfo.endCursor;
      allProducts = allProducts.concat(edges.map(edge => edge.node));
    } else {
      edges = response.data.products.edges;
      mySearch.hasNextPage = response.data.products.pageInfo.hasNextPage;
      mySearch.cursor = response.data.products.pageInfo.endCursor;
      allProducts = allProducts.concat(edges.map(edge => edge.node));
    }

    // const edges = response.data.products.edges;
    // allProducts = allProducts.concat(edges.map(edge => edge.node));
    

    // console.log("Continue search?", mySearch);


    // break;

    if (!(mySearch.allowCursor)) {
      break;
    }

  }

  //if all products is not an array make it one
  if (!Array.isArray(allProducts)) {
    allProducts = [allProducts];
  }

  // go through all products 

  //if randomize is true
  if (mySearch.randomize) {
    allProducts = shuffleArray(allProducts);
  };


  var rtn = allProducts.map(simplifyProductData);

  //if excludeTag is in search
  if ("excludeTags" in search) {
    var i = 0;
    while (i < rtn.length) {
      var product = rtn[i];
      var hasTag = false;
      for (let j = 0; j < search.excludeTags.length; j++) {
        if (product.tags.includes(search.excludeTags[j])) {
          hasTag = true;
        }
      }
      if (hasTag) {
        rtn.splice(i, 1);
      } else {
        i++;
      }
    }
  }

  //enforce the must have inventory or be available for sale
  var i = 0;
  while (i < rtn.length) {
    var product = rtn[i];
    if (product.totalInventory < 1) {
      rtn.splice(i, 1);
    } else {
      i++;
    }
  }

  
  var hidden = [];

  if (mySearch.hideNoMedia) {
    
    var i = 0;
    while (i < rtn.length) {
      if (rtn[i].media.images.length < 1) {
        hidden.push(rtn[i]);
        rtn.splice(i, 1);
      } else {
        i++;
      }
    }
  
  }

  // console.log("Items Hidden From Search...", hidden);

  if (rtn.length < 1) {
    throw errors.noResults.throw();
  }

  // console.log("Products return form search", rtn);

  //if the return length is greator than the show limit
  if (rtn.length > mySearch.show) {
    rtn = rtn.slice(0, mySearch.show);
  }

  return rtn;

} module.exports.search = search; 

  /**
 * Retrieves relevant product recommendations for a given product ID using Shopify's AI recommendations.
 * @param {String} productId The ID of the product to find recommendations for.
 * @returns {Promise<Object>} A promise that resolves with the recommended products or an error if the request fails.
 */
async function getRelevantProducts(productId) {
    const query = `
        query($productId: ID!) {
            productRecommendations(productId: $productId) {
                ${varDefaultFields}
            }
        }
    `;

    try {
        const response = await RequestFrom({
            query,
            variables: { productId }
        });
        return response.productRecommendations;  // Returns an array of recommended products
    } catch (error) {
        // console.error('Failed to fetch product recommendations:', error);
        errors.relevantProductsError.throw(error);
    }
} module.exports.getRelevantProducts = getRelevantProducts;
 
/**
 * Requests a query
 * @param {*} request The request to send.
 * @property {String} request.query The query to send.
 * @property {Object} request.variables The variables to send.  
 * @returns The response from the query.
 */
async function RequestFrom(request) {

  // console.log("Request", request);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': infoAPI.storefront.public
    },
    body: JSON.stringify(request)
  };

  // console.log("Request Options", requestOptions);

  try {
  
    const response = await myFetch(getUri(), requestOptions);

   try {

    //  console.log("Response", response);
     
     const jsonResponse = await response.json();
    //  console.log("JSON Response", jsonResponse);

     //if json response has an array of errors
     if ("errors" in jsonResponse) {
       console.log("Errors", jsonResponse.errors);
       throw errors.unknownError.throw(jsonResponse.errors);
     }

    //  console.log("JSON Response", jsonResponse);

     return jsonResponse;

    //  const edges = jsonResponse.data.products.edges;
    //  allProducts = allProducts.concat(edges.map(edge => edge.node));
    //  hasNextPage = jsonResponse.data.products.pageInfo.hasNextPage;
    //  cursor = jsonResponse.data.products.pageInfo.endCursor;

   } catch (error) {
     
     // if response is status 200
     if (response.status === 200) {
       //let's check response .size
       if (response.size === 0) {
         throw errors.noResults.throw(error);
       } else {
         throw errors.unknownError.throw(error); 
       }
     } else {

       switch (response.status) {
         case 401:
           throw errors.unauthorized.throw();
         case 403:
           throw errors.forbidden.throw();
         case 404:
           throw errors.notFound.throw();
         case 500:
           throw errors.serverError.throw();
         default:
           throw errors.unknownError.throw(error);
       };

     }

   }

 } catch (error) {
   if ("namespace" in error) {
     throw error;
   } else {
     throw errors.unknownError.throw(error);
   }
 }
} module.exports.RequestFrom = RequestFrom; 

/**
 * Returns a product by its id.
 * @param {*} productId The product id.
 * @returns The product.
 */
async function getProduct(productId) {
  return await search({
    product: productId
  });
} module.exports.getProduct = getProduct;

// try {
//   // window.Shopify = RequestFrom;
// } catch (error) {
  
// }


async function test() {

  var jckConsole = require("@jumpcutking/console");
  jckConsole.startup({
      reportToConsole: true,
      generateStacktrace: false,
      storeLogs: false,
      showFrom: true
  }); 


  // var $ = require("./scafolding");
  // var config = require("./config.js");
  // config.load();

  // init($)

  var searchObj = {
    collection: ["14k-gold-fine-jewelry"]
  };

  // console.log("Searching for", searchObj);

  var products = await search(searchObj);
  // console.log("Products", products);

}
// test();