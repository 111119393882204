/*!
 * Santization helps report common errors with a parameters.
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * all components that use modularity
 * 
 * Justin K Kazmierczak
 * All Rights Reserved.
 * 
 * May be subject to The Universe Terms of Service.
 **/
var namespace = "universe.module.sanitize";
// module.exports.namespace = namespace;

var report = true;
var convertFromAccountingDate = require("./functions/convertFromAccountingDate.js").function;
var verifyEmail = require("./functions/verifyEmail.js").function;
var verifyPhone = require("./functions/verifyPhone.js").function;

/**
 * Validates a value based on the typeof suppied.
 * Performs a "not null|undefined" test.
 * @param {*} must The typeof object to test for.
 * @param {*} value The value to test.
 */
function main(must, value) {
    var okay = false;
    okay = n(value); //am I null

    if (okay) {
        okay = s(must, value);
    }

    return okay
} module.exports.main = main;
module.exports = main;

/**
 * Validates a string value.
 * Checks if the value is a string, not null or undefined, and is within the min and max length.
 * @param {*} value The value to test.
 * @param {*} min The minimum length of the string. Default is 3.
 * @param {*} max The maximum length of the string. Default is false. If false, the max length is not checked.
 * @returns true if the value is a string, not null or undefined, and is within the min and max length.
 */
function validString(value, min = 3, max = false) {
    // var isValid = true;

    //check if value is a string and not null or undefined
    if (typeof value !== "string") return false;
    if (value == null) return false;
    if (value == undefined) return false;

    //ensure it's not a string of null or undefined
    if (value == "null") return false;
    if (value == "undefined") return false;

    // trim the value
    value = value.trim();
    
    //check if value is within the min and max, if max is false don't check the max
    if (value.length < min) return false;
    if (max && value.length > max) return false;

    return true;

} module.exports.validString = validString;
module.exports.string = validString;

/**
 * Validates an email address.
 * @param {*} value The email address to test.
 * @returns true if the value is a string, not null or undefined, and is a valid email address.
 */
function email(value) {
    //check if it's a string
    if (typeof value !== "string") return false;

    //check if it's not null or undefined
    if (value == null) return false;
    if (value == undefined) return false;

    //trim the value
    value = value.trim();

    //check if it's a valid email
    if (!verifyEmail(value)) return false;

    return true;
} module.exports.email = email;

/**
 * Validates a phone number.
 * @param {*} value The phone number to test.
 * @returns true if the value is a string, not null or undefined, and is a valid phone number.
 */
function phone(value) {
    //check if it's a string
    if (typeof value !== "string") return false;

    //check if it's not null or undefined
    if (value == null) return false;
    if (value == undefined) return false;

    //trim the value
    value = value.trim();

    //check if it's a valid phone number
    if (!verifyPhone(value)) return false;

    return true;
} module.exports.phone = phone;

var states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida",
    "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine",
    "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
    "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma",
    "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
    "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "Other Territories"
];

function address(addr) {

    /*
    {
        "line1": "P.O. Box 272", minium 2 characters
        "line2": "", optional
        "city": "Brice", minium 3 characters
        "state": "ohio", must be a state from a list
        "country": "United States", minium 3 characters
        "postalcode": "43109" minium 4 
    }
    */

    //check if it's an object
    if (typeof addr !== "object") return false;

    //check if it's not null or undefined
    if (addr == null) return false;
    if (addr == undefined) return false;

    //check if it has the required fields
    if (!("line1" in addr)) return false;
    if (!("city" in addr)) return false;
    if (!("state" in addr)) return false;
    if (!("country" in addr)) return false;
    if (!("postalcode" in addr)) return false;

    //check if the fields are strings
    if (!(validString(addr.line1, 2))) return false;
    if (!(validString(addr.city, 3))) return false;
    if (!(validString(addr.state, 2))) return false;
    if (!(validString(addr.country, 3))) return false;
    if (!(validString(addr.postalcode, 4))) return false;

    return true;

} module.exports.address = address;

/**
 * Validates a phone number.
 * @param {*} value The phone number to test.
 * @returns true if the value is a string, not null or undefined, and is a valid phone number.
 */
function phone_US(value) {

    // console.log("Phone", value);

    //check if it's a string
    if (typeof value !== "string") return false;

    //check if it's not null or undefined
    if (value == null) return false;
    if (value == undefined) return false;

    //trim the value
    value = value.trim();

    // console.log("Phone 2 ", value);

    //check if it's a valid phone number
    if (!verifyPhone(value)) return false;

    //if the length of the string is not equal to "+17167594348" (12)

    // console.log("Length", value.length);
    if (value.length != 12) return false;

    return true;
} module.exports.phone_US = phone_US;


/**
 * Checks if it's a valid year.
 * @param {*} value The value to test.
 * @param {*} min The minimum year. Default is 1990.
 * @param {*} max The maximum year. Default is false. If false, the max year is not checked.
 * @param {*} lessThanNow Should the year be less than now? Default is false.
 * @returns true if the value is a number, not null or undefined, and is within the min and max year.
 */
function validYear(value, min = 1990, max = false, lessThanNow = false) {
    //first check it's not null or undefined
    if (value == null) return false;
    if (value == undefined) return false;

    //next if its a string try to convert it to a number
    if (typeof value === "string") {
        value = parseInt(value);

        //if it's not a number return false
        if (isNaN(value)) return false;
    }

    //check if it's a number
    if (typeof value !== "number") return false;

    //check if it's within the min and max
    if (value < min) return false;
    if (max && value > max) return false;

    //check if it's less than now
    if (lessThanNow) {
        if (value > new Date().getFullYear()) return false;
    }

    return true;

}  module.exports.validYear = validYear;
module.exports.year = validYear;

/**
 * Validates a date.
 * Checks if the value is a date, not null or undefined, and is within the min and max date.
 * Checks if the value is an accounting date if the isAccountingDate is true.
 * If lessThanNow is true, the date must be less than now.
 * @param {*} value The value to check
 * @param {Boolean} lessThanNow Should the date be less than today? Default is true.
 * @param {Date} min Should the date be greater than min? Default is false.
 * @param {Date} max Should the date be less than max? Default is false.
 * @param {Boolean} isAccountingDate Is the date an accounting date? Default is false. As described in the accounting date function.
 * @returns true if the date is valid.
 * @throws Error if min or max is not a Date object.
 */
function validDate(value, lessThanNow = true, min = false, max = false, isAccountingDate = false) {

    //first check it's not null or undefined
    if (value == null) return false;
    if (value == undefined) return false;

    //next check if it's a string and convert it to a date
    var date = false;

    //if it's an accounting date
    if (isAccountingDate) {
        // check to make sure it's a string or a number
        if (typeof value === "number") {
         //   date = new Date(value);
        } else if (typeof value === "string") {
            //if each character is a number
            for (var i = 0; i < value.length; i++) {
                if (isNaN(value[i])) return false;
            }

            value = convertFromAccountingDate(value);

        } else {
            return false;
        }

        
    } else {
        if (typeof value === "string") {
            
            date = new Date(value);
            // did the conversion fail?
            if (date == "Invalid Date") return false;

        } else 

        //is it a number?
        if (typeof value === "number") {
            date = new Date(value);

            // did the conversion fail?
            if (date == "Invalid Date") return false;
        } else

        //is it a date?
        if (value instanceof Date) {
            date = value;
        } else {
            return false;
        }
    }


    //start tests

    //if it's less than now good, but only if lessThanNow is true
    if (lessThanNow) {
        if (date > new Date()) return false;
    }

    //if min is set, check if it's greater than min - min must be a date or throw an error
    if (min) {
        if (min instanceof Date) {
            if (date < min) return false;
        } else {
            throw new Error("The min date must be a Date object.");
        }''
    }

    //if max is set, check if it's less than max - max must be a date or throw an error
    if (max) {
        if (max instanceof Date) {
            if (date > max) return false;
        } else {
            throw new Error("The max date must be a Date object.");
        }
    }

    return true;


} module.exports.validDate = validDate;
module.exports.date = validDate;

/**
 * Validates that a value is of specified type.
 * You may also test for an array
 * @param {*} must The typeof objct to test for.
 */
function s(must, value) {
    if (must === "array") {
        if (Array.isArray(value)) {
            if (report) {console.log("I am a safe array");}
            return true;
        } else {
            if (report) {console.log("I'm not a safe array");}
            return false;
        }
    } else if (typeof value === must) {
       // console.log(`I'm ${typeof value}`);
        return true;
    } else {
        //console.log(`I'm not ${must} instead I am ${typeof value}`);
        return false;
    }
} module.exports.s = s;
module.exports.type = s;


/**
 * Validates a number.
 * @param {*} value The value to test.
 * @param {*} min The minimum value.
 * @param {*} max The maximum value. Default is false. If false, the max value is not checked.
 * @returns true if the value is a number, not null or undefined, and is within the min and max value.
 */
function validNumber(value, min = 0, max = false) {

    // console.log("value is", {
    //     value: value,
    //     min: min,
    //     max: max
    // });

    //attempt string conversion
    if (typeof value === "string") {
        value = parseInt(value);
        if (isNaN(value)) return false;
    }

    if (typeof value !== "number") return false;
    if (value == null) return false;
    if (value == undefined) return false;
    if (isNaN(value)) return false;
    if (value < min) return false;
    if (max && value > max) return false;
    return true;
} module.exports.validNumber = validNumber;
module.exports.number = validNumber;

/**
 * Verifies an object is not null and undefined.
 * @param {*} value The valye to test.
 */
function n(value) {
    
    var okay = false;
    if (typeof value === undefined) {
        if (report) {console.log("my type is compared to undefined");}
    } else if(value == null) {
        if (report) {console.log("I am null")}
    } else {
        okay = true;
    }

    return okay;
} module.exports.n = n;
module.exports.notnull = n;

/**
 * Validates a value to a string, and conforms it to lowercase, and trims
 * @param {*} value 
 */
function l(value, _trim = true) {
    var okay = false;
    okay = main("string", value);

    //console.log("ok is", okay);

    if (okay) {
        //trim item
        if (_trim) {
            return value.trim().toLowerCase();
        } else {
            return value.toLowerCase();
        }

    } else {
        throw new Error("The provided string is invalid.");
    }
} module.exports.l = l;
module.exports.lower = l;

/**
 * Test's the santization santize.main() but reports it's status to the logger.
 * This is unsafe as the logger may throw an exception.
 * @param {*} log The Logger object. @deprecated
 * @param {*} must a string to test typeof (can also test for array)
 * @param {*} value the value to perform the tests against
 * @returns true if the value passes the tests, false if it fails.
 */
function TestSantizationWithLogger(log, must, value) {
    // console.log("logger is", log);
    // log.i(this._proc, this._st, "start", `Testing object with type of ${typeof value} for type of ${must}`, must, value);
    if (!main(must, value)) {
        console.error(`The paramater failed sanitization typeof ${must}, instead was typeof ${typeof value} (simple).`, {
            must: must,
            value: value
        });
        // log.error(this._proc, this._st, "Santize", `The paramater failed sanitization typeof ${must}, instead was typeof ${typeof value} (simple).`, value); 
        return false;
    } else {
        return true;
    }
}


// module.exports.s = s;
// module.exports.n = n;
// module.exports.l = l;
module.exports.log = TestSantizationWithLogger;

module.exports.tests = [{
        namespace: `${namespace}.string.valid`,
        must: true,
        run: async () => {
            
            return validString("Hello World!", 3, 12);
            
        }
    }, {
        namespace: `${namespace}.string.invalid`,
        must: false,
        run: async () => {
            
            return validString("Hi", 3, 12);
            
        }
    }, {
        namespace: `${namespace}.string.undefined`,
        must: false,
        run: async () => {   

            return validString(undefined);
            
        }
    }, {
        namespace: `${namespace}.string.null`,
        must: false,
        run: async () => {
            
            return validString(null);
            
        }
    }, {
        namespace: `${namespace}.string.nullstring`,
        must: false,
        run: async () => {
            
            return validString("null");
            
        }
    }, {
        namespace: `${namespace}.string.undefinedstring`,
        must: false,
        run: async () => {
            
            return validString("undefined");
            
        }
    }, {
        namespace: `${namespace}.year.valid`,
        must: true,
        run: async () => {
            
            return validYear(2021, 1990, 2022);
            
        }
    }, {
        namespace: `${namespace}.year.invalid`,
        must: false,
        run: async () => {
            
            return validYear(2021, 1990, 2020);
            
        }
    }, {
        namespace: `${namespace}.year.undefined`,
        must: false,
        run: async () => {
            
            return validYear(undefined);
            
        }
}, {
        namespace: `${namespace}.year.null`,
        must: false,
        run: async () => {
            
            return validYear(null);
            
        }
}, {
        namespace: `${namespace}.year.string`,
        must: true,
        run: async () => {
            
            return validYear("2021");
            
        }
}, {
        namespace: `${namespace}.year.stringinvalid`,
        must: false,
        run: async () => {
            
            return validYear("Hello");
            
        }
}, {
        namespace: `${namespace}.year.maxfail`,
        must: false,
        run: async () => {
            
            return validYear(2021, 1990, 2020);
            
        }
}, {
        namespace: `${namespace}.year.lessfail`,
        must: false,
        run: async () => {
            
            return validYear(1989, 1990, 2022, true);
            
        }
}, {
        namespace: `${namespace}.date.valid`,
        must: true,
        run: async () => {
            
            return validDate(new Date(), false);
            
        }
}, {
        namespace: `${namespace}.date.accounting`,
        must: true,
        run: async () => {
            
            return validDate(20210101, false, false, false, true);
            
    }
}, {
        namespace: `${namespace}.date.invalid`,
        must: false,
        run: async () => {
            
            return validDate("Hello", false);
            
        }
    }, {
        namespace: `${namespace}.date.lessthannow`,
        must: false,
        run: async () => {
            
            return validDate(new Date("9999-01-01"), true);
            
        }
    }, {
        namespace: `${namespace}.date.accounting.lessthannow`,
        must: false,
        run: async () => {
            
            return validDate(99990101, true, false, false, true);
            
        }
    }, {
        namespace: `${namespace}.date.minfail`,
        must: false,
        run: async () => {
            
            return validDate(new Date("2020-01-01"), false, new Date("2021-01-01"));
            
        }
    }, {
        namespace: `${namespace}.date.accounting.minfail`,
        must: false,
        run: async () => {
            
            return validDate(20210101, false, new Date("2022-01-01"), false, true);
            
        }
    }, {
        namespace: `${namespace}.date.maxfail`,
        must: false,
        run: async () => {
            
            return validDate(new Date(), false, false, new Date("2021-01-01"));
            
        }
    }, {
        namespace: `${namespace}.date.accounting.maxfail`,
        must: false,
        run: async () => {
            
            return validDate(20210101, false, false, new Date("2020-01-01"), true);
            
        }
    }, {
        namespace: `${namespace}.date.invalid`,
        must: false,
        run: async () => {
            
            return validDate("Hello", false);
            
        }
    }, {
        namespace: `${namespace}.date.lessthannow`,
        must: false,
        run: async () => {
            
            return validDate(new Date("9999-01-01"), true);
            
        }
    }, {
        namespace: `${namespace}.date.accounting.lessthannow`,
        must: false,
        run: async () => {
            
            return validDate(99990101, true, false, false, true);
            
        }
    }
    ];