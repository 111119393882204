/*
  This is the version that you will tell the server you support.
 */

var combineObj = require("../../uam/functions/combineObjects.js").function;
const build = require("../config.json");
var uatConfig = {};

try {
    uatConfig = require("../../uat.config.json");    
} catch (error) {
    console.error(`The App does not have a uat.config.json file.`);
}


var config = {
    version: build.version,
    location: "../../../../../../../../../../uat/",
    email: {
        version: 1,
        style: {
            fonts: "font-family: Arial, sans-serif;",
            fontsize: "font-size: 24px;",
            h1: "font-size: 32px;",
            h2: "font-size: 28px;",
            h3: "font-size: 28px;",
            h4: "font-size: 28px;",
            h5: "font-size: 28px;",
            padding: "padding: 15px;",
            holstertable: "width:980px; padding:0px; margin:0px;",
            maxwidth: "980px",
            holsterTableBackground: "background-repeate:repeat; background-position: center center;",
            glocktable: "width:100%; padding:0px; margin:0px;",
            defaultcell: "text-align: center; padding:0px; margin:0px;",
            em: "font-style: italic;"
        },
        schema: {
            "$id": "http://example.com/example.json",
            "$schema": "http://json-schema.org/draft-07/schema",
            "default": {},
            "description": "An e-mail message document",
            "examples": [
                {
                    "title": "The Subject of the e-mail.",
                    "version": 2,
                    "body": [
                        {
                            "name": "logo header",
                            "type": "image",
                            "order": 1,
                                "image": "$[logo]"
                        },
                        {
                            "name": "paragraph",
                            "type": "paragraph",
                            "order": 2,
                                "inner": "This is your favorite e-mail messsage paragraph."
                        }
                    ]
                }
            ],
            "required": [
                "title",
                "version",
                "body"
            ],
            "title": "The root schema",
            "type": "object",
            "properties": {
                "title": {
                    "$id": "#/properties/title",
                    "default": "",
                    "description": "The Subject of the message.",
                    "examples": [
                        "The title of the message."
                    ],
                    "title": "The Title Subject schema",
                    "type": "string"
                },
                "version": {
                    "$id": "#/properties/version",
                    "default": 0,
                    "description": "Used to inturpret the message.",
                    "examples": [
                        1
                    ],
                    "title": "The version schema",
                    "type": "integer"
                },
                "body": {
                    "$id": "#/properties/body",
                    "default": [],
                    "description": "The body of the message, and it's details.",
                    "examples": [
                        [
                            {
                                "name": "logo header",
                                "type": "image",
                                "order": 1,
                                    "image": "$[logo]"
                            },
                            {
                                "name": "paragraph",
                                "type": "paragraph",
                                "order": 2,
                                    "inner": "This is your favorite e-mail messsage paragraph."
                            }
                        ]
                    ],
                    "title": "The body schema",
                    "type": "array",
                    "additionalItems": true,
                    "items": {
                        "$id": "#/properties/body/items",
                        "anyOf": [
                            {
                                "$id": "#/properties/body/items/anyOf/0",
                                "type": "object",
                                "title": "The first anyOf schema",
                                "description": "An explanation about the purpose of this instance.",
                                "default": {},
                                "examples": [
                                    {
                                        "name": "logo header",
                                        "type": "image",
                                        "order": 1,
                                        "image": "$[logo]"
                                    }
                                ],
                                "required": [
                                    "name",
                                    "type",
                                    "_order"
                                ],
                                "properties": {
                                    "name": {
                                        "$id": "#/properties/body/items/anyOf/0/properties/name",
                                        "type": "string",
                                        "title": "The name schema",
                                        "description": "An explanation about the purpose of this instance.",
                                        "default": "",
                                        "examples": [
                                            "logo header"
                                        ]
                                    },
                                    "type": {
                                        "$id": "#/properties/body/items/anyOf/0/properties/type",
                                        "type": "string",
                                        "title": "The type schema",
                                        "description": "An explanation about the purpose of this instance.",
                                        "default": "",
                                        "examples": [
                                            "image"
                                        ]
                                    },
                                    "_order": {
                                        "$id": "#/properties/body/items/anyOf/0/properties/order",
                                        "type": "integer",
                                        "title": "The order schema",
                                        "description": "An explanation about the purpose of this instance.",
                                        "default": 0,
                                        "examples": [
                                            1
                                        ]
                                    }
                                },
                                "additionalProperties": true
                            }
                        ]
                    }
                }
            },
            "additionalProperties": true
        }
    },
    main: "main",
    test: {
        port: 8180,
        dir: "test"
    },
    uatools: {
        name: "Universe App Tools",
        quickname: "UATools",
        windowname: "uat"
    },
    uaServerSideNotSupportedPath: "../src/elements/ua.server.notSupported.js"
};   

// console.log("Config before combination...", config);
config = combineObj(config, uatConfig);

// console.log("Config after combination...", config);

module.exports = config;