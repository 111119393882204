/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

// var fm = require("../../../uam/filemanager.js");

var namespace = "ua.source";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
var element = require("../interface/element.js");
var f = require("../scripts/f.js");
const { data } = require("jquery");
// var uae = require("./templates/ua.element.js");

var define = {
  namespace: namespace,
  title: "UA Source File",
  description: "Retireves a file from the server and places it inline.",
  fields: {
    "type": {
      type: "list",
      items: ["html", "json", "text"],
      description: "HTML and Text types will retrieve the information as text and process it here. If not provided, the element will try to decode as JSON, treat as HTML, or treat as text. Text will be encoded, HTML will be written native. JSON will be parsed and rendered as a JSON object.",
    }, "rewrap": {
      type: "boolean",
      description: "Rewrap the JSON object in a namespace, with a new namespace.",
    }, "override": {
      type: "object",
      description: "override the values for that JSON object.",
    }, "passthrough": {
      type: "object|array",
      description: "Passthrough the options to the JSON object.",
    }, "inner": {
      type: "string",
      description: "The inner code to render."
    }, "src": {
      type: "string",
      description: "The source file to retrieve."
    }
  },
  // required: required//,
  // supportClient: false,
  // supportServer: true
}; module.exports.define = define;

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // console.log(`Retrieving ${options.src} from server...`, {
  //   options: options
  // });

  var src = options.src;
  var type = "json";

  if ("type" in options) {
    type = options.type;
  } else {

    //see if the src is a html file
    if (src.endsWith(".html")) {
      type = "html";
    }
    // } else if (src.endsWith(".json")) {
    //   type = "json";
    // }

  }

  // var result = false;
  
  var dateFromSource = false;
  try {
    dateFromSource = await uai.getObject(options.src, type);
    // console.log(`Recieved ${type} from ${options.src}.`, {
    //   src: options.src,
    //   type: type,
    //   dateFromSource: dateFromSource
    // });
  } catch (err) {

    return await element.CreateError(namespace, err, options);
  }

  // console.log(`Recieved ${type} from ${options.src}.`, {
  //   src: options.src,
  //   type: type,
  //   dateFromSource: dateFromSource
  // });

  if (type == "json") {
      return await ReturnJSON(dateFromSource, options);


  } else if (type == "html") {
    return await ReturnHTML(dateFromSource);
  } else if (type == "text") {
    return await ReturnText(dateFromSource);
  } else {

    //Attempt to parse it as json or html if type is not defined

    try {
      return await ReturnJSON(dateFromSource, options);
    } catch (err) {
      try {
        return await ReturnHTML(dateFromSource);
      } catch (error) {
        return await ReturnText(dateFromSource);
      }
    }


  }
};

async function ReturnHTML(dateFromSource) {
  var rtn = document.createElement("partial");
  rtn.innerHTML = dateFromSource;
  return rtn;
}

async function ReturnText(dateFromSource) {
  return document.createTextNode(dateFromSource);
}

async function ReturnJSON(json, options) {
  // var json = dateFromSource;
    
    //if the json is a string, parse it.
    if (typeof json == "string") {
      json = JSON.parse(json);
    }

    // JSON.parse(dateFromSource);
    json = jsonRender.convertSH(json);

    if ("rewrap" in options) {

      // console.log(`Rewraping ${options.src} to ${options.rewrap}.`, {
      //   src: options.src,
      //   rewrap: options.rewrap,
      //   passthrough: options.passthrough
      // });

      //if json is an array
      if (Array.isArray(json)) {

        json = {
          "namespace": options.rewrap,
          "inner": json
        }

      } else if (typeof json == "object") {

        //if json is an object
        json.namespace = options.rewrap;

      }

    }

    // var passthrough = {};
    if ("passthrough" in options) {
      json = await jsonRender.pass(options.passthrough, json);
    }

    if ("override" in options) {
      //take any value in the override and replace it in the json

      for (var key in options.override) {
        json[key] = options.override[key];
      }

    }

    // var rtn = await jsonRender.render(json);
    // console.log(`Attempting render of ${options.src}...`, {
    //   src: options.src,
    //   json: json,
    //   rtn: rtn
    // });

    return await jsonRender.render(json);
}

/** 
<article class="container-fluid vh-100 d-flex">
<div class="row m-auto">
    <div class="col" data-ua-observe>
        <h1 class="text-center">Welcome to The Universe</h1>
    </div>
</div>
</article> */
