/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
{
  "n": "pages.page",
  "title": "LUXE LEVELS - NAVY SILK DRESS BY AUR2230200158",
  "useBackgroundImage": false,
  "on": {
    "published": "20231114101740",
    "processed": "20240416183445",
    "describePublished": "November 14, 2023"
  },
  "url": "http://localhost:8517/shop/items/navy-silk-dress-by-aur2230200158.html",
  "i": {
    "product": {
      "id": "gid://shopify/Product/8553279127792",
      "title": "NAVY SILK DRESS BY AUR2230200158", 
      "handle": "navy-silk-dress-by-aur2230200158",
      "description": "",
      "descriptionHtml": "",
      "productType": "Women's Dresses",
      "vendor": "AMANDA UPRICHARD",
      "tags": [
        "women clothing",
        "women's dresses"
      ],
      "collections": [
        "Women's Clothing",
        "Women's Dresses"
      ],
      "media": {
        "images": [
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00438.jpg?v=1712271502",
            "altText": "",
            "position": 0
          }, 
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00425.jpg?v=1712271502",
            "altText": "",
            "position": 1  
          }, 
          {  
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00428.jpg?v=1712271502",
            "altText": "",
            "position": 2 
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00431.jpg?v=1712271502",
            "altText": "",
            "position": 3
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00441.jpg?v=1712271478",
            "altText": "",
            "position": 4
          },
          {
            "src": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00465.jpg?v=1712271478",
            "altText": "",
            "position": 5
          }
        ],
        "videos": [
          {
            "src": null,
            "altText": "",
            "position": 6,
            "sources": [
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.HD-1080p-7.2Mbps-26899201.mp4",
                "mimeType": "video/mp4"
              },
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.m3u8",
                "mimeType": "application/x-mpegURL"
              },
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.HD-720p-4.5Mbps-26899201.mp4",
                "mimeType": "video/mp4"
              },
              {
                "url": "https://cdn.shopify.com/videos/c/vp/2dae68e49a7041148b7d8f61855afa8a/2dae68e49a7041148b7d8f61855afa8a.SD-480p-1.5Mbps-26899201.mp4",
                "mimeType": "video/mp4"
              }
            ],
            "poster": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/preview_images/20240313-DSC00438.jpg?v=1712589214"
          }
        ],
        "models": [],
        "externalVideos": []
      },
      "variants": [ 
        {
          "id": "gid://shopify/ProductVariant/45299961856240",
          "title": "NAVY / XS",
          "sku": "AUR2230200158XS",
          "availableForSale": true,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          }, 
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY" 
            },
            {
              "name": "Size",
              "value": "XS"
            }
          ]
        },
        {
          "id": "gid://shopify/ProductVariant/45299961889008",
          "title": "NAVY / S",
          "sku": "AUR2230200158S",
          "availableForSale": false,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            { 
              "name": "Color", 
              "value": "NAVY"
            },
            {
              "name": "Size",
              "value": "S"
            }
          ]
        },
        {
          "id": "gid://shopify/ProductVariant/45299961921776",
          "title": "NAVY / M",
          "sku": "AUR2230200158M",
          "availableForSale": false,
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY"
            },   
            {
              "name": "Size",
              "value": "M"
            }  
          ]
        },
        { 
          "id": "gid://shopify/ProductVariant/45299961954544",
          "title": "NAVY / L",
          "sku": "AUR2230200158L",
          "availableForSale": true, 
          "priceV2": {
            "amount": "389.0",
            "currencyCode": "USD"
          },
          "selectedOptions": [
            {
              "name": "Color",
              "value": "NAVY"
            },
            {
              "name": "Size",
              "value": "L" 
            }
          ]
        }
      ],
      "optionsByType": {
        "Color": [
          "NAVY"
        ],
        "Size": [
          "XS",
          "S",
          "M",
          "L"
        ]
      }
    },
    "namespace": "luxe.product"
  },
  "image": "https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240313-DSC00438.jpg?v=1712271502"
} 
*/  

var jsonRender = require("../../uat/src/interface/jsonRender.js");
var uai = require("../../uat/src/interface/interface.js");
var events = require("../../uam/events.js");
var shopifySearch = require("../../uam/shopify.search.js");
 
var define = {  
  namespace: "luxe.carousel",  
  title: "Add's a carousel to the page.",
  description: "Displays the results of a search.",
  fields: {
    collection: {
      type: "array", 
      description: "The name of the collection.",
      addtouae: true
    },
    designer: {
      type: "string",
      description: "The name of the designer.",
      addtouae: true
    },
    tag: {
      type: "string",
      description: "The tag of the product.",
      addtouae: true
    },
    type: {
      type: "string",
      description: "The type of the product.",
      addtouae: true
    },
    relevantproducts: {
      type: "string",
      description: "The type of the product.",
      addtouae: true
    }, 
    sort: {
      type: "string",
      items: ['price', 'newest', 'older', 'title', 'vendor', 'best_selling', 'relevant'],
      description: "The sort order of the search results.",
      addtouae: true 
    },
    sortType: {
      type: "string",
      items: ["asc", "desc"],
      description: "The sort type of the search results.",
      addtouae: true
    },
    show: {
      type: "number",
      description: "The number of results to show.",
      addtouae: true
    },
    allowCursor: {
      type: "boolean",
      description: "Allow the cursor to be shown.",
      addtouae: true
    }
  },
  supportServer: false
}; module.exports.define = define; 
 

/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
async function render(options) {

  var searchObj = {};

  if ("sort" in options) {
    searchObj.sort = options.sort;
  }

  if ("sortType" in options) {
    searchObj.sort = options.sortType;
  }

  if ("show" in options) {
    searchObj.show = options.show;
  }

  if ("allowCursor" in options) {
    searchObj.allowCursor = options.allowCursor;
  }

  if ("collection" in options) { 
    searchObj.collection = options.collection;
  }

  if ("designer" in options) {
    searchObj.designer = options.designer; 
  }

  // if ("vendor" in options) {
  //   searchObj.vendor = options.vendor;
  // } 

  if ("tag" in options) {
    searchObj.tag = options.tag;
  }
  
  if ("type" in options) {   
    searchObj.type = options.type;
  } 
  
  if ("relevantproducts" in options) {
    searchObj.relevantproducts = options.relevantproducts;
  }

  return await SearchResults(searchObj); 

} module.exports.render = render;   

events.on("interface.afterrender", async function () {

  //is the search element prerendered - update it!
  if (!(uai.server)) {
 
    // console.log("It's a pre")

    //rebuild search object by getting uae [namespace="luxe.search"] and getting the values of the fields
    var uae = document.querySelector(`uae [namespace="${define.namespace}"]`);

    if (uae) { 
      var searchObj = {};

      if (uae.collection) {
        searchObj.collection = uae.getAttribute("collection");
      }

      if (uae.designer) {
        searchObj.designer = uae.getAttribute("designer");
      }

      if (uae.tag) { 
        searchObj.tag = uae.getAttribute("tag");
      }

      if (uae.type) {
        searchObj.type = uae.getAttribute("type");
      } 

      if (uae.relevantproducts) {
        searchObj.relevantproducts = uae.getAttribute("relevantproducts");
      }

      //perform the search
      await PerformSearch(searchObj);
    }
  }

});

/**
 * 
 *Carosel Item
{
        "i": {
          "n": "div",
          "c": "home-carousel",
          "s": "background-image: url(https://cdn.shopify.com/s/files/1/0517/6220/0753/files/20240423-DSC02673_90abfd31-5bc3-409e-8fcc-710a1daf22ea.jpg?v=1715347935); background-size: cover; background-position: top center;",
          "i": {
            "n": "div",
            "c": "hc-item",
            "i": [{
              "n": "h1",
              "c": "text-white",
              "i": "FRENCH ONE SHOULDER PLEATED DRESS BY LORIANE"
            }, {
              "n": "a",
              "href": "http://10.5.0.7:8557/product/french-one-shoulder-pleated-dress-by-loriane-lr55021-4",
              "c": "btn btn-outline-primary rounded-0 border-0 bg-100-black-hover bg-white text-black text-white-hover col-6 col-lg-3",
              "i": ["Shop Now"]
            }]
          }
        }
      }
 */

/**
 * Render the results of the search
 * @param {*} results The results of the search
 * @returns The renderable object
 */
async function RenderResults(results) { 
 
  console.log("Result:", results[0]);

  //for each result wrap it in a object with n: "lux.product.preview" and product: result
  var items = results.map(function (product) {


    // console.log("Walking Product:", product);

    var image = "../../../../../default-image.jpg"

    // check if media exists
    if ("media" in product) {
      if ("images" in product.media) {
        if (product.media.images.length > 0) {
          image = product.media.images[0].src;
        }
      }
    }

    return {
      "n": "div",
      "c": "home-carousel-holster",
      "i": [
          {
            "n": "div",
            "c": "home-carousel",
            "data-goto": "/product/" + product.handle,
            "s": `background-image: url(${image}); background-size: cover; background-position: top center;`
          },
          {
            "n": "div",
            "data-goto": "/product/" + product.handle,
            "c": "home-carousel-description",
            "i": {
                "n": "div",
                "c": "holstered",
                "i": [{
                  "n": "h1",
                  "data-goto": "/product/" + product.handle,
                  "i": product.title 
                }, {
                  "n": "a",
                  "data-goto": "/product/" + product.handle,
                  "href": "../../../../../../../product/" + product.handle,
                  "c": "home-carousel-btn col-6 col-lg-3",
                  "i": ["Shop Now"]
              }]
            }
          }
        ]
      };

  });  

  var holster = {
    "n": "ua.carousel",
    "hidearrows": true,
    "i": items
  }  

  console.log("The Luxe Carousel Rendered: ", holster);

  return holster;
}

async function SearchResults(searchObj) { 
  
  try {  
    
    var results = {};
    results = await shopifySearch.search(searchObj);
    return await RenderResults(results);


  } catch (error) {

    return await HandleErrors(error);

  }

}

async function QueryResults(query) { 
 
  try {  
    
    var results = await shopifySearch.RequestFrom({
      query: query 
    });

    const edges = results.data.products.edges;
    const allProducts = edges.map(edge => edge.node);
    return await RenderResults(allProducts);

    // return await RenderResults(results);

  } catch (error) {

    return await HandleErrors(error);

  }

}

async function QueryResultsObj(query) {
  try {  
    var results = await shopifySearch.RequestFrom(query);

    const edges = results.data.products.edges;
    const allProducts = edges.map(edge => edge.node);
    return await RenderResults(allProducts);
    
  } catch (error) {
    return await HandleErrors(error);
  } 
} 

async function HandleErrors(error) {
  console.log("Search Failed...", error); 

    if ("namespace" in error) {  

      switch (error.namespace) {
        case "shopify.api.noresults":
          return GenerateErrorMessage("We couldn't find that style,", "The style you're looking for is sold out or currently unavailable.");
        default:
          var errMsg = error.message;
          if ("description" in error) {
            errMsg = `${errMsg}: ${error.description}`;
          }
          // console.log("Product Search Error", error);
          return GenerateErrorMessage("We couldn't find that style,", `Our inventory system encountered an error of ${error.namespace}. ${errMsg}`);
      }
 
    } else { 
      console.log("Product Search Error", error);
      return GenerateErrorMessage("A slight problem,", error.message);
    }
}

async function PerformSearch(searchObj) {
  //get uae namespace=[define.namespace]
  var uaePlaceholder = document.querySelector(`uae [namespace="${define.namespace}"]`);

  //if the uae element exists
  if (uaePlaceholder) {
    uaePlaceholder.innerHTML = "";
    // var div = document.createElement("div");
    uaePlaceholder.appendChild(await jsonRender.render(await SearchResults(searchObj)));

  } else {
    uaePlaceholder.appendChild(await GenerateErrorMessage("A slight problem,", "The search element is missing from the page. Your search results could not be shown.",  false));
  }

}

// try {
//   window.shopify = {
//     search: PerformSearch,
//     query: QueryResults,
//     results: RenderResults,
//     queryObj: QueryResultsObj
//   }
// } catch (error) {
   
// }

 
function GenerateErrorMessage(title, message, showOtherProducts = true) {

  var err = { 
    n: "div",
    c: "row text-center d-block",
    i: {
      n: "div",
      c: "tiny-outline m-3 p-3 bg-og-25-white text-black border-primary border-2 border rounded col-10 col-md-6 d-inline-block",
      i: [{
        n: "h1",
        c: "text-start ps-3 pe-3",
        i: title
      }, {
        n: "p",
        c: "text-start ps-3 pe-3",
        i: message
      }]
    }
  }

  return err;

}

