var f = require("../scripts/f.js");
var uac = require("./templates/ua.control.js");

// var formGroupShell = require("./templates/ua.formgroup.js");
var namespace = "ua.c.text";
exports.namespace = namespace;

var except = ["name", "id", "hidelabel", "icon", "textgroup", "icongroup", "uarendered", "class", "style"];

exports.render = function (ele) {
 var options = uac.GetOptions(ele, []);

 //already appended
 if (!(options.uarendered === true)) {

  var input = document.createElement("input");
  input.id = options.name;

  input.setAttribute("name", options.name);
  input.setAttribute("type", "text");
  input.setAttribute("uaignore", "true");
  input.classList.add("form-control");

  if ("value" in options) {
    input.value = options.value;
  }


  input.willValidate = true;

  //formGroupShell.PassAtributes(ele, input)
 
  // //attach the input to the form group
  // formgroup = formGroupShell.CreateShell(options, input);

  // // ele.setAttribute("uarendered", true);
  // // ele.appendChild(formgroup);
  // //formgroup.addEventListener("prepare", Prepare);

  input = uac.PassOptions(options, input, except);
  ele.addEventListener("prepare", Prepare);
  return input;

 }

}

function Prepare(event) {
  var control = this;
  var jsonexport = uac.PrepareOutput(control);
  uac.StoreValue(control, jsonexport);
}
