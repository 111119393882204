exports.invalidmain = {
  title: "Response,",
  body: `
  <p>The application detected an error while rendering the response.</p>
  <p>The main DOM object was not found, or multiple objects were found. Unfortunately, this means the universe can not render your response. f you're a developer, please check the console.</p>
  `
};

exports.unsetresponse = {
  title: "Unset Response,",
  body: `
  <p>The object returned was not recognized as a valid object. Unfortunately, we could not render the response. If you're a developer, please check the console.</p>
  `
};


exports.requestfailed = {
  title: "Request,",
  body: `
  <p>The action request failed, it looks like something happened with your connection or a firewall prevented the communication.</p>
  <h2>Steps to fix your connection,</h2>
  <ol>
    <li>Check that your network connection is stable.</li>
    <li>Refresh the client (browser).</li>
    <li>Reboot your machine, firewall, router, or modem.</li>
    <li>Your browser may not be compatible with the Fetch API, try using a different one.</li>
  </ol>
  <p>If you're a developer, please check the console.</p>`
};
