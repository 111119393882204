/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.list";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "List Group",
  description: "",
  fields: {
    "inner": {
      type: "array of list.item", 
      description: "The inner items of the listgroup."
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = json;

  //for each item add the namespace
  var _list = {
    n: "div",
    c: "list-group",
    i: options.inner
  };

  // for (var i = 0; i < options.inner.length; i++) {
    
  //   /** an item doesn't have to have sub properties, it can be a direct copy */
  //   if ("not-an-item" in options.inner[i]) {
  //     console.warn("Not an item", options.inner[i]);
  //     var item = {
  //       "n": "ua.list.item",
  //       "inner": options.inner[i]
  //     };
  //     _list.i.push(item);
  //     continue;
  //   }

  //   var item = options.inner[i];
  //   item.namespace = "ua.list.item";
  //   _list.i.push(item);

  // }

  // console.log("List", _list);
  //await 100 ms
  // await new Promise(resolve => setTimeout(resolve, 100));
  // process.exit(7);

  return await jsonRender.convertJSONToDOM(_list, {
    PassthroughOptions: options
  });

}

/** 
 * 
 * 
 * 
<div class="list-group">
  <a href="#" class="list-group-item list-group-item-action active" aria-current="true">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small>3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small>And some small print.</small>
  </a>
  <a href="#" class="list-group-item list-group-item-action">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small class="text-body-secondary">3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small class="text-body-secondary">And some muted small print.</small>
  </a>
  <a href="#" class="list-group-item list-group-item-action">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small class="text-body-secondary">3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small class="text-body-secondary">And some muted small print.</small>
  </a>
</div>
*/
