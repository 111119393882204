/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

 
var namespace = "ua.navbar";  
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var events = require("../../../uam/events.js");
var uae = require("../interface/element.js");
 
var uatNavBarItem = require("./ua.navbar.item.js");
var uaNavBarMenu = require("./ua.navbar.menu.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Enhanced Navigation Bar",
  description: "A beutiful navigation bar for your app. If you use the fixed-top class, it will automatically add margin to the top of the body (this may overide other settings) so it doesn't overlap the content.",
  fields: {
    "first": {
      type: "inner", 
      description: "The first items of the navigation bar, these items will exapnd from the left of the navbar.."
    }, "fixed": {
      type: "boolean",
      description: "If the navbar should be fixed to the top of the page.",
      default: true
    }, items: {
      type: "inner",
      namespaces: ["ua.navbar.items"],
      description: "The items to add to the navbar."
    }, "brand": {
      type: "boolean",
      description: "If the navbar should have add the default brand.",
      default: true
    }, "brandInner": {
      type: "inner",
      description: "The inner content of the brand (in addition to logo)."
    }, last: {
      type: "inner",
      description: "The last items of the navigation bar, these items will exapnd from the right of the navbar."
    }, "menuIconClass": {
      type: "string",
      description: "The class to use for the default menu icon."
    }, "fillContent": {
      type: "boolean",
      description: "If the content should fill the entire navbar.",
      default: true
    }, "navBarClass": {
      type: "class",
      description: "The class to add to the navbar."
    },
    showExpandedMenu: {
      type: "boolean",
      description: "Shows the expanded menu icon.",
      default: true
    },
    announcements: {
      type: "inner",
      // namespaces: ["ua.announcements"],
      description: "The announcements to add to the navbar."
    },
    type: {
      type: "list",
      description: "The type of navbar to display.",
      default: "minimal",
      items: ["minimal", "expanded", "compact"]
    },
    expandedBrandCenter: {
      type: "boolean",
      description: "If the brand should be centered in the expanded navbar.",
      default: true
    },
    sticky: {
      type: "boolean",
      description: "If the navbar should be sticky. You can use sticky-top for non compact menus.",
      default: true
    },
  },
  required: required,
  // supportServer:false
} 

/** 
 * If I have one fixed-top navbar, set the body margin-top to it's height so it doesn't overlap the content.
*/
events.on("interface.afterrender", async function() {

  var uae = document.querySelector(`uae[namespace='${namespace}']`)
  if (!uae) return;

  var nav = document.querySelector(".navbar.fixed-top");
  if (nav) {

    //get the current padding top of the body
    // if (!document.body.style.paddingTop) {
    //   document.body.style.paddingTop = "0px";
    // }

    if (!(nav.hasAttribute("do-not-margin-body"))) {

      var annouce = document.querySelector(".ua-navbar-menu-announcements");
      if (annouce) {
        nav.style.top = (annouce.offsetHeight - 1)  + "px";
        document.body.style.marginTop = (nav.offsetHeight + annouce.offsetHeight) + "px";
        // return;
      } else {
        document.body.style.marginTop = (nav.offsetHeight) + "px";
      }

    }
 
  }

});

/**
 * Creates the navigation items from an array or object.
 * @param {*} items 
 * @returns the navigation items.
 */
async function createNavItems(items) {

  // var menus = [];

  if (!Array.isArray(items)) {

    // if ("menu" in items) {
    //   var menu = uaNavBarItem.createNavMenu(items.items, items.inner);
    //   menus.push

    items = [items];

    // return createNavItem(items, items);
  } 

  var navItems = [];
  for (var i = 0; i < items.length; i++) {

    // console.log(`Creating nav item from ua.navbar.item:`, items[i]);

    if (items[i].namespace == "ua.navbar.item") {    
      navItems.push(createNavItem(await CreateLinkFromUaNavbarItem(items[i]), items[i]));
      continue;
    }

    navItems.push(createNavItem(items[i], items[i]));
  }

  return navItems;

}

/**
 * Create a navigation item for the navbar
 * @param {*} item The item to create the nav item for.
 * @returns JSON object of a nav item.
 */
function createNavItem(item, itemOptions) {
  var navItem = {
    ...itemOptions,
      n: "li",
      c: "nav-item",
      i: item
  }

  if ("navItemClass" in itemOptions) {
    navItem.c += ` ${itemOptions.navItemClass}`;
  }


  return navItem;

}

/**
 * Creates a navigation group for the navbar (ul).
 * @param {*} item The item to create the nav item for.
 * @returns JSON object of a nav item.
 */
function createNavGroup(item, options, type) {


  var navGroup = {
      n: "ul",
      c: "navbar-nav d-flex",
      i: item
  }

  // if (options.fillContent) {
    

  // } else {
  //   navGroup.c += " text-end justify-content-end";
  //   if (middle) {
  //     navGroup.c += " flex-fill";
  //   }
  // }

  switch (type) {
    case "first":
      navGroup.c += " text-start justify-content-start";
    break;
    case "middle":
      navGroup.c += " flex-fill text-center m-auto justify-content-around align-items-center";
    break;
    case "last":
      navGroup.c += " text-end justify-content-end";
    break;
  }

  return navGroup;

}


async function CreateLinkFromUaNavbarItem(uaNavBarItem) {

  var a = await uatNavBarItem.GetSimple(uaNavBarItem);

  // var itemEle = [];
  // if ("icon" in uaNavBarItem) {
  //   itemEle.push({
  //     n: "i",
  //     c: `bi bi-${uaNavBarItem.icon}`
  //   });

  //   //add the extra space for display purposes
  //   if (!("hideText" in uaNavBarItem && uaNavBarItem.hideText)) itemEle.push("");
  // }


  // if (!("hideText" in uaNavBarItem && uaNavBarItem.hideText)) {
  //   if ("quickName" in uaNavBarItem) {
  //     itemEle.push(uaNavBarItem.quickName);
  //   } else {
  //     itemEle.push(uaNavBarItem.title);
  //   }
  // }

  // var a =  {
  //   n: "a",
  //   c: "nav-link",
  //   t: uaNavBarItem.title,
  //   href: uaNavBarItem.src,
  //   i: itemEle
  // };

  // if ("target" in uaNavBarItem) {
  //   a.target = uaNavBarItem.target;
  // }

  // // if (!(options.type == "compact")) {
  //   if ("navBarClass" in uaNavBarItem) {
  //     a.c = ` ${uaNavBarItem.navBarClass}`;
  //   }
  // // }

  if ("menu" in uaNavBarItem) {

    if (!(typeof uaNavBarItem.menu == "object")) {
      uaNavBarMenu.errors.invalidMenu.throw({
        namespace: exports.namespace
      });
    }
 
    // var menu = await uaNavBarMenu.render(options.menu, );
    var menu = await uae.render({
      ...uaNavBarItem.menu,
      namespace: "ua.navbar.menu"
    }, namespace, { 
      doNotPrerender: true
    });

    a["ua-navbar-menu"] = menu.id;
    // a.i.push(menu);
    a.c += " ua-navbar-item-controls-menu";

    if (menu.type == "halfscreen") {
      a.c += " ua-navbar-item-menu-halfscreen-hover";
    }

    return [a, menu];

  }

  return a;

}

var lastClass = " justify-content-end";

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
async function render(options) {

  //defaults
  if (!("brand" in options)) {
    options.brand = true;
  }

  var menus = {};
  var nav = {};

  //create the navigation groups
  var inner = [];

  // var brand, first, last = "";
  var brand = "";
  var first = "";
  var last = "";

  var pinned = [];
  var unpinned = [];
  
  if ("brand" in options && options.brand) {
    brand =
    {
      "n": "a",
      "c": "navbar-brand mybrand d-inline-block pe-auto",
      // "style": "min-width:7rem; padding:0; margin:0;",
      "href": "/",
      "title": "home", 
      "inner": "&nbsp;"
    };

    if ("brandInner" in options) {
      brand.inner = options.brandInner;
    }
    // inner.push(brand);
  }


  //get all the pinned items
  if ("items" in options) {

    // console.log(`Items:`, options.items);
  
    for (var i = 0; i < options.items.length; i++) {
      var item = options.items[i];
      if ("pin" in item && item.pin) {
        pinned.push(item);
      } else {
        unpinned.push(item);
      }
    }

    // //if their are no pinned items, by default, pin all of them.
    // if (pinned.length == 0) {
    //   pinned = options.items;
    // }

    // console.log(`Pinned: ${pinned.length}, Unpinned: ${unpinned.length}`);

  }

  var centerMass = await createNavItems(pinned);
  centerMass = createNavGroup(centerMass, options, "middle");
  centerMass.c += " nav-centermass";
  // inner.push(centerMass);

  var menuItem = {
    n: "button",
    c: "d-md-none button btn btn-text-primary bg-100-background-hover ua-navbar-item-controls-menu ua-navbar-item-controls-mobile-menu-btn",
    i: {
      n: "i",
      c: "bi bi-list"
    }
  }

  if ("menuIconClass" in options) {
    menuItem.i.c = `${options.menuIconClass}`;
  }

  var menuOptions = {
    namespace: "ua.navbar.menu" 
  };

  if ("items" in options) {
    menuOptions.items = options.items;
  }

  if ("inner" in options) {
    menuOptions.inner = options.inner;
  }

  var menu = await uae.render(menuOptions, namespace, {
    doNotPrerender: true
  });

  menuItem["ua-navbar-menu"] = menu.id;

  if ("first" in options) {

    // if (options.type == "expanded") {
      // first = await createNavItems([menuItem, options.first]);
    // } else {
      first = await createNavItems(options.first);
    // }
    first = createNavGroup(first, options, "first");

    if (options.fillContent) {
      first.c += " flex-fill justify-content-around";
    }

    // inner.push(first);
  }

  if ("last" in options) { 

    //if options.last is not an array, make it an array
    if (!Array.isArray(options.last)) { 
      options.last = [options.last];
    }

    // var last = false;

    // if ((options.showExpandedMenu) && (options.type == "minimal")) {
      // last = await createNavItems([...options.last, menuItem]);
    // } else {
      last = await createNavItems(options.last);
    // }


    // var last = await createNavItems([...options.last, menuItem]);
    last = createNavGroup(last, options, "last");

    // if (expandable == "last") { 
      last.c += lastClass;
    // }

    // inner.push(last);
  }


  //append the navivigation items
  nav = {
    n: "nav",
    c: "ua-navbar navbar navbar-expand",
    "aria-label": "Navigation",
    i: []
  };

  if (options.type == "compact") {
    nav.c = "ua-navbar navbar ua-navbar-compact";
  }

  if (options.type == "minimal") {
    //add first, the brand, the centermass (), and the last if any of them are not false

    var layer1 = {
      n: "div",
      c: "nav-holster d-flex p-0 m-0",
      i: []
    };

    if (first) layer1.i.push(first);
  

    if (brand) layer1.i.push(brand);
    if (centerMass) layer1.i.push(centerMass);
    if (last) { 
      layer1.i.push(last)  
    } else {  
      // last = await createNavItems([menuItem]);
      // last = createNavGroup(last, options, "last");
      // last.c += lastClass;
      // layer1.i.push(last);
    };

    layer1.i.push(menuItem); 
    nav.i.push(layer1);

  } else if (options.type == "expanded") {

    nav = {
      n: "nav",
      c: "ua-navbar navbar",
      "aria-label": "Navigation",
      i: []
    };

    var layer1 = {
      n: "div",
      c: "nav-holster nav-expanded-layer p-0 m-0",
      i: []
    };

    var layer2 = {
      n: "div",
      c: "nav-holster nav-expanded-layer p-0 m-0 d-none d-md-flex",
      i: []
    };

    if (first) { 
      layer1.i.push([menuItem, first]) 
    } else {
      first = await createNavItems([menuItem]);
      first = createNavGroup(first, options, "first");
  
      if (options.fillContent) {
        first.c += " flex-fill justify-content-around";
      }

      layer1.i.push(first);
    } 


    if (options.expandedBrandCenter) {
      if (brand) layer1.i.push([{
        n: "div",
        c: "nav-brand pe-none",
        i: ""
      }, {
        n: "div",
        c: "nav-brand-force-center pe-none",
        i: brand
      }]);
    } else {
      if (brand) layer1.i.push({
        n: "div",
        c: "nav-brand pe-none ",
        i: brand
      });
    }
    
    if (last) layer1.i.push(last);
    if (centerMass) layer2.i.push(centerMass);

    nav.i.push({
      n: "div",
      c: "container-xl p-0",
      i: [layer1, layer2]
    });


    // nav.i.push([layer1, layer2]);

  } else if (options.type == "compact") { 

    //reset classes
    if (first) first.c = "navbar-nav" //d-flex text-start justify-content-start flex-fill justify-content-around"
    if (last) last.c = "navbar-nav" //d-flex text-end justify-content-end"
    if (centerMass) centerMass.c = "navbar-nav nav-centermass" //d-flex flex-fill text-center m-auto justify-content-around align-items-center"
    nav.i.push({
      n: "div",
      c: "ua-navbar-compact-holster " + (options.class || ""),
      i: [brand, first, centerMass, last]
    });

    // nav.i = await jsonRender.pass(options, nav.i, ["namespace", "items", "first", "last", "brand", "brandInner", "inner"]);

  }


  if (!(options.type == "compact")) {
    nav = await jsonRender.pass(options, nav, ["namespace", "items", "first", "last", "brand", "brandInner", "inner"]);
  }

  /**** Add the last groups */
  // console.log(`Nav:`, nav);

  if ("sticky" in options) {
    if (options.sticky) {

      if ("c" in nav) {
        nav.c += " position-sticky sticky-top";
      } else {
        nav.c = "position-sticky sticky-top";
      }
    }
  }


  if ("class" in options) {
    if ("c" in nav) {
      nav.c += ` ${options.class}`;
    } else {
      nav.c = options.class;
    }
  }

  if ("announcements" in options) {
    var announce = {
      n: "div",
      c: "ua-navbar-menu-announcements",
      i: options.announcements
    };

    if ("sticky" in options) {
      if (options.sticky) {
        return {
          n: "div",
          c: "position-sticky sticky-top ua-navbar-sticky",
          i: [announce, nav, menu]
        };
      }
    }

    return [announce, nav, menu];

  }


  return [nav, menu];

} module.exports.render = render;

/** 
 * 
 * <nav id="navMain" class="navbar navbar-expand navbar-dark bg-blur-dark fixed-top" aria-label="Navigation">
        <div class="container">
<ul class="navbar-nav">
    <a class="navbar-brand mybrand d-inline-block" style="width:4rem; height:4rem;" href="/" title="home"></a>
</ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">Home</a>
                </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#developers">Developers</a>
              </li>
<li class="nav-item">
    
</li>
            </ul>
<ul class="navbar-nav">
    <li class="nav-item"><button class="btn btn-sm btn-info btn-hover-white text-uppercase ls-1" type="submit">Join the Beta</button></li>
</ul>
            
            
            
            
        </div>
      </nav>
*/
