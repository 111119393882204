/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/**
 * Example
 * 
 * {
  "n": "pages.page",
  "title": "LUXE LEVELS - Artisan Made Jewelry",
  "useBackgroundImage": false,
  "on": {
    "published": "20231114101740",
    "processed": "20240406051056"
  },
  "url": "https://luxelevels.com/collection/artisan-made-jewelry.html",
  "i": {
    "n": "luxe.collection",
    "slug": "artisan-made-jewelry",
    "name": "Artisan Made Designer Jewelry"
  }
}
 */


var jsonRender = require("../../uat/src/interface/jsonRender.js");
var interface = require("../../uat/src/interface/interface.js");

var define = {
    namespace: "luxe.master.preview",
    title: "Luxe Leveles Collection Page",
    description: "Display's a collection and all it's products.",
    fields: {
        name: {
            type: "string",
            description: "The name of the collection."
        },
        slug: {
            type: "string",
            description: "The slug of the collection."
        },
        image: {
            type: "uri",
            description: "The image to display for the collection."
        },
        type: {
            type: "list",
            list: ["list", "grid"],
            required: true,
            addtouae: true
        },
        myType: {
            type: "list",
            list: ["collection", "type", "vendor", "designer", "tag"],
            required: true
        },
        foreground: {
            type: "string",
            description: "The image to display instead of txt."
        }
    }//,
    // supportServer: false
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    //clean the settings
    if ("foreground" in options) {
        if (options.foreground == "#{foreground}") {
            delete options.foreground;
        }
    }

    var preview = {};
    var goTo = `/${options.myType}/${options.handle}`;

    var name = options.name;

    if ("foreground" in options) {
        name = {
            n: "img",
            "data-goto": goTo,
            src: options.foreground,
            alt: options.name,
            c: "img-fluid border-0"
        } 
    }

    if (options.myType == "vendor") options.myType = "designer";
 
    if ("image" in options) {
        if (options.image == "#{image.src}") {
            delete options.image;
        }
    }

    switch (options.type) {
        case "mobile":
            preview = {
                n: "a",
                c: "d-inline-block col-6 button btn btn-text-primary bg-100-primary-hover text-background-hover text-uppercase rounded luxe-master-preview",
                href: `../../../../../../../${options.myType}/${options.handle}`, 
                i: name
            }
        break;
        case "list":
            preview = {
                n: "a",
                c: "d-inline-block luxe-master-preview-item button btn btn-text-primary bg-100-primary-hover text-background-hover text-uppercase rounded luxe-master-preview",
                href: `../../../../../../../${options.myType}/${options.handle}`, 
                i: name
            }
        break;
        case "grid":
        case "grid2":
            /**<div style="background: url(http://localhost:8517/images/jewelry-fashon.jpeg) no-repeat center center; background-size: cover;" class="col-12 col-lg-6 p-3 m-0 text-center vh-50 d-flex text-shadow text-hover-white text-shadow-hover  text-white"><div class="d-flex backdrop-blur-hover justify-content-center align-items-center flex-grow-1 m0 rounded "><h3 class="p-3">Jewelry</h3></div></div> */
            
            var mystyle = ``;
            var myclass = ``;

            if ("image" in options) {

                preview = {
                    n: "div",
                    "data-goto": goTo,
                    c: `col-md-6 col-lg-3 m-0 text-center master-preview-image-holster d-inline-block  text-uppercase fw-bold`,
                    s: `background: url(${options.image}) no-repeat center center; background-size: cover;`,
                    i: {
                        n: "div",
                        c: "master-preview-image-holster-inner p-3",
                        "data-goto": goTo,
                        i: {
                            n: "div",
                            "data-goto": goTo,
                            c: "",
                            i: {
                                n: "h3",
                                "data-goto": goTo,
                                c: "",
                                i: name
                            }
                        }
                    }
                }

                if (options.type == "grid2") {
                   preview.c += " col-6"
                } else {
                    preview.c += " col-12"
                }

            } else {

                preview = {
                    n: "a",
                    // "data-goto": goTo,
                    href: "../../../../../../.." + goTo,
                    c: `col-6 col-md-6 col-lg-3 p-3 text-uppercase fw-bold d-inline-block`,
                    i: {
                        n: "h3",
                        "data-goto": goTo,
                        c: "btn btn-outline-primary bg-black bg-og-background-hover text-white text-black-hover",
                        i: name
                    }
                };

            }

        break;
     }
    
    return preview;

} 