/*!
 * Copyright (C) 2022 Justin K Kazmierczak - All Rights Reserved.
 * The use of this code is subject to The Terms of Service.
 * To access these terms visit: https://egtuniverse.com/legal/terms
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * 
 * Universe App Scafolding Object
 * Turns a string into an object appended to a specificed object.
 * 
 */

// var $ = require("../modules/scafolding");
var namespace = "objectify";

var $ = require("../scafolding.js");

/**
 * Appends a prop to an object by crafting it's namespace.
 * Safe function, returns success as boolean.
 * 
 * NOTE: Only javscript varable names will work.
 * @param {*} _ns The namespace to convert to an object. 
 * @param {*} prop The property to assign to the object.
 * @param {*} obj The object to append the namespace to.
 * @param {boolean} overide Should I overide properties.
 * @returns Success true or false.
 */
function objectify(_ns, prop, obj, overide = true) {

    if ("log" in $) $.log.add("objectify", `Objectifying ${_ns}.`, {
        namespace: _ns,
        prop: prop,
        obj: obj,
        overide: overide
    }, 4, namespace);

    try {

        //split based off of "."
        //the | and : may not function here
        var ns = _ns.split(".");
        var cnt = 0;
        var lvl = obj;
        while (ns.length > cnt) {

            var stage = ns[cnt];
            
            //Am I the last one?
            if ((ns.length - 1) == cnt)  {

                if (!overide) {
                    //check for overiding
                    if (stage in lvl) {

                        // $.log.add("overide",
                        // `${_ns} requested an overide of a property that was blocked.`, {
                        //     namespace: _ns,
                        //     stage: stage,
                        //     overide: overide,
                        //     lvl: lvl,
                        //     error: error
                        // }, 2, namespace); 
                
                        return false;
                    }
                }

                lvl[stage] = prop;

            } else {

                try {
                    //check if the level exsists and than objectify
                    //if the lvl doesn't exsist, create it.
                    if (stage in lvl) {
                        if (lvl[stage] == null) lvl[stage] = {};
                        if (lvl[stage] == undefined) lvl[stage] = {};
                        // lvl = lvl[stage]
                    } else {
                        lvl[stage] = {};
                    }

                    lvl = lvl[stage];

                } catch (error) {

                    if ("log" in $) $.log.add("objectify", `Failed to objectify ${_ns}.`, {
                        namespace: _ns,
                        ns: ns,
                        stage: stage,
                        lvl: lvl,
                        error: error
                    }, 3, namespace);
                    
                    return false;
                    
                }

             
            }

            cnt = cnt + 1;
        }

    } catch (error) {

        if ("log" in $) $.log.add("objectify", `Failed to objectify ${_ns}.`, {
            namespace: _ns,
            prop: prop,
            obj: obj,
            overide: overide,
            error: error
        }, 3, namespace);
        
        return false;

    }


}

module.exports = {
    //name: "objectify",
    namespace: namespace,
    function: objectify,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            //test objectify
            var obj = {}
            var result = objectify("t.e.s.t", true, obj);

            if (obj.t.e.s.t) {
                return true;
            }

        }
    }]
};