var _ = require("../../../uam/module.js")(module.exports, {
    namespace: "ua.address.show",
    title: "Show Address",
    description: "Shows an address.",
    editable: true,
    fields: {
        "id": {
            type: "number",
            description: "The id of the user.",
            required: true
        },
        "type": {
            type: "string",
            items: ["","billing", "shipping", "both"],
            description: "The type of address.",
            default: ""
        },
        "firstname": {
            type: "string",
            description: "The first name of the user.",
            required: true
        },
        "lastname": {
            type: "string",
            description: "The last name of the user.",
            required: true
        },
        "address": {
            type: "object",
            description: "The address of the user.",
            required: true,
            fields: {
                "city": {
                    type: "string",
                    description: "The city of the user.",
                    required: true
                },
                "line1": {
                    type: "string",
                    description: "The first line of the address of the user.",
                    required: true
                },
                "line2": {
                    type: "string",
                    description: "The second line of the address of the user.",
                    required: false
                },
                "state": {
                    type: "string",
                    description: "The state of the user.",
                    required: true
                },
                "country": {
                    type: "string",
                    description: "The country of the user.",
                    required: true
                },
                "postalcode": {
                    type: "string",
                    description: "The postal code of the user.",
                    required: true
                }
            }
        },
        "actions": {
            type: "inner",
            description: "The actions to add to the address."
        },
        "icon": {
            type: "string",
            description: "The icon to use for the address."
        },
        "showTag": {
            type: "boolean",
            description: "Show the type of address.",
            default: true
        }
    }, 
    passthrough: {
        except: ["inner", "overlay", "overlayClass", "interval"]
    }
});

var events = require("../../../uam/events.js");
var sleep = require("../../../uam/functions/sleep.js").function;

var addrTypes = {
    "billing": "Billing",
    "shipping": "Shipping",
    "both": "Billing & Shipping"
};

/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    // console.log("Vehicle Render", options);

    var header = {
        "n": "p",
        "i": "",
        "class": "p-0 fs-4",
        "icon": "bi bi-person-vcard",
    };

    if ("quickname" in options) {
        header.i = options.quickname;
    }
    
    // var addrType = "";

    if ("type" in options) {

        if (options.showTag) {

            if (options.type in addrTypes) {
                header.i = [
                    {
                        n: "span",
                        c: "badge bg-primary text-uppercase",
                        i: addrTypes[options.type]
                    }, ">br", header.i
                ]
            }
        
        }
    }

    var actions = "";

    if ("icon" in options) {
        header.icon = options.icon;
    }

    if ("actions" in options) {
        header = {
            n: "div",
            c: "row",
            i: [{
                n: "div",
                c: "col-6",
                i: header
            }, {
                n: "div",
                c: "col-6 text-end",
                i: options.actions
            }]
        }
    }

    var element = {
        n: "div",
        c: "p-3 row text-start tiny-outline border-1 border-success border rounded mb-3 text-break",
        i: [header, {
            "n": "div",
            "c": "col-12",
            "i": `${options.firstname} ${options.lastname}`
          }, {
            n: "div",
            c: "col-12",
            i: [{
                    "n": "i",
                    "c": "bi bi-geo-alt",
                    "i": "&nbsp;"
                },
                    options.address.line1 + " " + options.address.line2 + " " + options.address.city + ", " + options.address.state + " " + options.address.postalcode + ", " + options.address.country]
        }, {
            n: "div",
            c: "col-12",
            i: [{
                    "n": "i",
                    "c": "bi bi-phone",
                    "i": "&nbsp;"
                },
                    options.phone]
        }]
    }

    return element;

}