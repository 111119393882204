/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.fs.col";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var required = ["inner"];
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Fullscreen Crow by column",
  fields: {
    "inner": {
      type: "inner", 
      description: "Expects each column to be an array inside of an array of rows. [ [col1, col2], [col3, col4] ]"
    }
  },
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);
  

  //convert options.inner to the row column format.
  //if options.inner is a one dimensional array and not an array of arrays

  var rows = [];
  var hasRows = false;

  if (Array.isArray(options.inner) && Array.isArray(options.inner[0])) {
    //I'm a two dimensional array, convert me to rows and columns
    hasRows = true;

    for (var i = 0; i < options.inner.length; i++) {
      var row = {
        n: "row",
        c: "vh-100 d-flex", //m-auto vw-100",
        i: []
      };

      for (var j = 0; j < options.inner[i].length; j++) {
        var col = {
          n: "div",
          c: "col m-auto p-3",
          i: options.inner[i][j]
        };

        row.i.push(col);
      }

      rows.push(row);
    
    }
  } else {
    //I'm a single dimensional array, convert me to 1 row and columns

    var row = {
      n: "row",
      c: "m-auto vw-100",
      i: []
    };

    for (var i = 0; i < options.inner.length; i++) {
      var col = {
        n: "div",
        c: "col p-3 m-auto",
        i: options.inner[i]
      };

      row.i.push(col);
    }

    rows = row;

  }

  //Wrap it in an article
  if (hasRows) {
    var article = {
      n: "article",
      c: "container-fluid-flex",
      i: rows
    };
  } else {
    var article = {
      n: "article",
      c: "container-fluid-flex vh-100 d-flex",
      i: rows
    };
  }

  return await jsonRender.convertJSONToDOM(article);
}

/** 
<article class="container-fluid-flex">
        <div class="row">
            <div class="col vh-100 d-flex">
              <div class="m-auto">
                <p class="text-center" ua-observe>Real-time Notifications.</p>
              </div>
            </div>
            <div class="col vh-100 d-flex">
              <div class="m-auto">
                <p class="text-center" ua-observe>Secure Payments.</p>
              </div>
            </div>
            <div class="col vh-100 d-flex">
              <div class="m-auto">
                <p class="text-center" ua-observe>Privacy builtin.</p>
              </div>
            </div>  
        </div>
      </article> */
