var log = require('../../scripts/log.js');
var uai = require("../../interface/interface.js");
var f = require('../../scripts/f.js');
var required = [];
exports.GetOptions = GetOptions;

var namespace = "ua.e";
exports.namespace = namespace;

/**
 * Checks the control and gathers all the options of it.
 * If the contorl is set up through JSON, options will pass, if HTML
 * it will convert it to JSON.
 * Gather's all the options of the control, and compares to it's required outputs.
 * @param       {*} elementOptions       [The element or the JSON options to valiidate.]
 * @param       {*} _required [description]
 * @constructor
 */
function GetOptions(element, _required) {
  var rtn = {};

  if (f.isDomElement(element)) {
    for (var att, i = 0, atts = element.attributes, n = atts.length; i < n; i++){
      att = atts[i];

      rtn[att.nodeName] = att.nodeValue;

    }

    if ("inner" in rtn) {
    } else {
      rtn.innerText = element.innerHTML;
      rtn.inner = ConformInner(element.innerHTML);
    }
  } else {
    rtn = element;
  }

  var req = [];
  req = req.concat(_required, required);

  rtn = ValidateOptions(rtn, _required);
  if (rtn.success == false) {
    //log.warn("Error validating options", rtn);
    throw rtn.error
  }

  // console.log(rtn);
  return rtn.options;
}

/**
 * Validates JSON object options
 * @param       {*} opt       [the options object]
 * @param       {*} _required [the elements object required tags]
 * @constructor
 */
function ValidateOptions(opt, _required) {
  var rtn = {
    success: true,
    options: opt
  };

  _required.concat(required);

  var missing = [];

  var i = 0;
  while (i < _required.length) {
    if (!(_required[i] in opt)) {
      missing.push(_required[i]);
    }
    i++;
  }

  if (missing.length > 1) {
    rtn.error = "Missing required items [" + missing.join(", ") + "]";
    rtn.required = missing;
    rtn.success = false;
    return rtn;
  }

  //console.log(rtn);
  return rtn;
}

exports.ValidateOptions = ValidateOptions;

/**
 * Report if the alert is rendered.
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  var options = GetOptions(ele, required);
  if (options.uarendered === true) {
    return true;
  } else {
    return false;
  }
}

/**
 * Passes all the options to the element. Returns element.
 * @param {*} ele The Dom Object
 * @param {*} options The JSON options object
 * @param {*} nopass The items not to pass
 */
function PassthroughOptions(ele, options, nopass) {
  for (const [key, value] of Object.entries(options)) {
    if (nopass.includes(key)) {
      //do nothing!
    } else {
      ele.setAttribute(key, value);
    }
  }
  return ele;
}

exports.PassthroughOptions = PassthroughOptions;

function FillPlaceHoldersEmails(options, $my, brand) {
  var $opt = {};

  for (const [key, value] of Object.entries(options)) {
    //log.info("------->", key, value);

    if (value == "$[logo]") {
      $opt[key] = brand.logo;
    } else {
      $opt[key] = value;
    }

  }

  //log.info("==*", $opt);
  return $opt;
}

// exports.RenderObject = uai.RenderObject;
// exports.RenderObjectByNamespace = uai.RenderObjectByNamespace;
exports.FillPlaceHoldersEmails = FillPlaceHoldersEmails;

function ConformInner(inner) {
 // var rtn = false;
  try {

    if (typeof inner == "string") {
      inner = inner.trim();

      try {
        //log.info("Attempting Json Conversion...");
        return JSON.parse(inner);
      } catch (error) {
        //I may be HTML
        var tmpDiv = document.createElement("div");
        tmpDiv.innerHTML = inner;

        // log.info("tmpDiv", tmpDiv);
        
        if (tmpDiv.length > 0) {
          var rtn = [];

          tmpDiv.forEach((ele) => {
            var obj = {};

            if (ele.nodeType === Node.TEXT_NODE) {
              obj.$ = "";
            } else {
              obj.$ = ele.tagName;
            }
 
        
            for(var i = ele.attributes.length - 1; i >= 0; i--) {
              obj[ele.attributes[i].name] = ele.attributes[i].value;
            }
        
            obj.inner = ele.innerHTML;
        
            if (obj.inner.trim() == "") {
              delete obj.inner;
            } else {
              obj.inner = ConformInner(obj.inner);
            }
        
            rtn.push(obj);
          });
        } else {
          //log.info("The object must be a string.");
          return inner;
        }
      }

    } else {
      return inner;
    }
   
  } catch (error) {
    
    log.info("The inner attribute could not be parsed.", error, inner);
    return inner;

  }
}

// function ConvertElementToJson(ele, inc) {
//     var obj = {};
//     obj.$ = ele.tagName;

//     for(var i = ele.attributes.length - 1; i >= 0; i--) {
//       obj[ele.attributes[i].name] = ele.attributes[i].value;
//     }

//     obj.inner = ele.innerHTML;

//     if (obj.inner.trim() == "") {
//       delete obj.inner;
//     } else {
//       obj.inner = ConformInner(obj.inner);
//     }

//     rtn.push(obj);
// }


exports.help = {
  title: "element",
  description: "A control that can be used to validate and output data.",
  namespace: namespace,
  properties: {
    "namespace": {
      description: "The namespace of the element or control to select.",
      required: true,
      default: false,
      valuemust: "ua.e"
    }
  },
  type: "element",
  example: {
    html: `<element namespace="ua.e"></namespace>`,
    json: {
      namespace: "ua.e"
    }
  }
};
