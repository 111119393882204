/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/**
 * Example
 * 
 * {
  "n": "pages.page",
  "title": "LUXE LEVELS - Artisan Made Jewelry",
  "useBackgroundImage": false,
  "on": {
    "published": "20231114101740",
    "processed": "20240406051056"
  },
  "url": "https://luxelevels.com/collection/artisan-made-jewelry.html",
  "i": {
    "n": "luxe.collection",
    "slug": "artisan-made-jewelry",
    "name": "Artisan Made Designer Jewelry"
  }
}
 */


var jsonRender = require("../../uat/src/interface/jsonRender.js");
var interface = require("../../uat/src/interface/interface.js");

var define = {
    namespace: "luxe.master.page",
    title: "Luxe Leveles Master Page",
    description: "Display's a [collection, designer, tag, product type] and all it's products.",
    fields: {
        name: {
            type: "string",
            description: "The name of the collection."
        },
        slug: {
            type: "string",
            description: "The slug of the collection."
        },
        image: {
            type: "uri",
            description: "The image to display for the collection."
        },
        myType: {
            type: "list",
            list: ["collection", "type", "vendor", "designer", "tag"],
            required: true
        }
    }//,
    // supportServer: false
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    switch (options.myType) {
        case "collection":

            //if the collection is the outlet don't exclude it

            if (!(options.handle == "the-outlet")) {
            

                //if excludeTags is not there, add it
                if (!("excludeTags" in options)) {
                    options.excludeTags = [];
                }

                options.excludeTags.push("The Outlet");

            }
            // options.handle = options.slug;
        break;
        case "type":
            // options.handle = options.slug;
        break;
        case "vendor":
            // options.handle = options.slug;
        break;
        case "designer":
            // options.handle = options.slug;
        break;
        case "tag":
            // options.handle = options.slug;
        break;
    };

    var header = {};

    //if no options.name is there a options.title
    if (!("name" in options)) {
        if ("title" in options) {
            options.name = options.title;
        }
    }

    var myImage = false;

    if ("image" in options) {

        if (options.image) {
            myImage = options.image;

            //is image an object?
            // console.log("myImage", myImage);
            if (typeof myImage === "object") {
                if ("src" in myImage) {
                    myImage = myImage.src;
                }
            }
        }

    }

    if (myImage) {


        // console.log("options.image", options.image);

        var myImage = options.image;

        //is image an object?
        if (typeof myImage == "object") {
            if ("src" in myImage) {
                myImage = myImage.src;
            }
        }

        // header = {
        //     n: "ua.fs.image",
        //     src: myImage,
        //     fixed: true,
        //     style: "height: 400px",
        //     inner: {
        //         n: "h1",
        //         c: "text-center p-3 text-uppercase", 
        //         s: "letter-spacing: 1px;",
        //         i: options.name
        //     }
        // }

        header = {
            n: "div",
            c: "luxe-master-page-image tiny-outline",
            s: `background-image: url(${myImage});`,
            i: {
                n: "div",
                c: "luxe-master-page-image-inner",
                i: {
                    n: "h1",
                    c: "luxe-text-header", 
                    i: options.name
                }
            }
        }

    } else {
        header = {
            n: "h1",
            c: "luxe-text-header",
            i: options.name
        }

        //if I'm the outlet
        if (options.handle == "the-outlet") {
            //<div class="bg-black text-white m-4 rounded text-center p-4 fs-1">Save 30% use code LUXE30 at checkout</div>
            header = [header, {
                n: "div",
                c: "text-black m-4 rounded text-center p-4 fs-1 fw-bold",
                s: "background-color: rgb(198, 164, 92);",
                i: "Save 30% Use Code LUXE30 at Checkout"
            }];
        }

    }
 
    var search = {
        n: "luxe.search",
        randomize: true
    };

    switch (options.myType) {
        case "collection":
            search.collection = options.handle;
        break;
        case "type":
            search.type = options.handle;
        break;
        case "vendor":
            search.vendor = options.handle;
        break;
        case "designer":
            search.designer = options.handle;
        break;
        case "tag":
            search.tag = options.handle;
        break;
    };

    if ("excludeTags" in options) {
        search.excludeTags = options.excludeTags;
    }
 
    var page = {
        n: "div",
        c: "tiny-outline",
        i: [header, search]
    }
    
    return page;

} 