/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/**
 * Example
 * 
 * {
  "n": "pages.page",
  "title": "LUXE LEVELS - Artisan Made Jewelry",
  "useBackgroundImage": false,
  "on": {
    "published": "20231114101740",
    "processed": "20240406051056"
  },
  "url": "https://luxelevels.com/collection/artisan-made-jewelry.html",
  "i": {
    "n": "luxe.collection",
    "slug": "artisan-made-jewelry",
    "name": "Artisan Made Designer Jewelry"
  }
}
 */


var jsonRender = require("../../uat/src/interface/jsonRender.js");
var uai = require("../../uat/src/interface/interface.js");

var define = {
    namespace: "luxe.tag",
    title: "Luxe Leveles Tag Page",
    description: "Display's a collection and all it's products.",
    fields: {
        name: {
            type: "string",
            description: "The name of the collection."
        },
        slug: {
            type: "string",
            description: "The slug of the collection."
        },
        image: {
            type: "uri",
            description: "The image to display for the collection."
        }
    },
    // supportServer: false
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

    return {
        n: "luxe.master.page",
        myType: "tag",
        ...options
    }; 

} 