/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.fs.scrollup";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var required = ["header", "inner"];
exports.required = required;

exports.define = {
  supportServer: false,
  namespace: namespace,
  title: "Fullscreen Scroll Up",
  fields: {
    "header": {
      type: "inner", 
      description: "The header that is displayed on top of the article."
    }, 
    "inner": {
      type: "inner",
      description: "The items to display and scroll up."
    }, 
    "ua-observe": {
      type: "boolean",
      description: "If true, the element will be observed and will trigger an event when it is in view."
    }
  },
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);
  
  var uaObserve = options["ua-observe"] ? options["ua-observe"] : false;
  // var uaAnimation = options["ua-animation"] ? options["ua-animation"] : "fade-50";

  //convert options.inner to the row column format.
  //if options.inner is an array, than I have multiple items
  //if it isn't an array than I have one.

  var rows = [];

  // console.log("inner",{
  //   inner: options.inner
  // })


  if ("namespace" in options.inner) {

    if (options.inner.namespace == "repeat") {
      //render the repeater
      options.inner = await jsonRender.search("repeat").render(options.inner, true);
      // console.log("Repeater", {
      //   inner: options.inner
      // });

    }

  }

  if (Array.isArray(options.inner)) {

    for (var i = 0; i < options.inner.length; i++) {


      rows.push(addItem(options.inner[i], uaObserve));
    }

  } else {
    rows.push(addItem(options.inner, uaObserve));
  }

  // <div class="full-screen-sticky position-sticky">
  //         <div class="vh-100 bg-info"></div>
  //       </div>
  //       <div class="full-screen-sticky position-sticky" style="z-index: 100000; margin-top: -100vh;">
  //         <!-- <div class="vh-100 bg-info"> -->
  //           <div class="row vh-100 d-flex">
  //             <div class="col m-auto">
  //               <h2 class="text-center fs-3 bg-blur-black text-white observer-visible" ua-observe="">Be Creative.</h2>
  //             </div>  
  //         </div>
  //       </div>

  //Wrap it in an article
  var article = {
    n: "article",
    c: "container-fluid-flex",
    i: [{
      n: "div",
      c: "full-screen-sticky position-sticky",
      i: {
        n: "div",
        c: "vh-100"
      }
    }, {
      n: "div",
      c: "full-screen-sticky position-sticky",
      s: "z-index: 1; margin-top: -100vh;",
      //appear on top of the scroller
      i: {
        n: "div",
        c: "row vh-100 d-flex",
        i: {
          n: "div",
          c: "col m-auto",
          i: options.header,
          "ua-observe": uaObserve
        }
      }
    }, ...rows]
  };

  // console.log("Scroll Up", {
  //   article: article,
  //   rows: rows
  //   });
  return await jsonRender.convertJSONToDOM(article);
}

/**
 * Returns a new item to add to the scroll up list
 * @param {*} item The itme class to add to the list
 * @param {*} observable If true, the item will be observed and will trigger an event when it is in view.
 * @param {*} animation The animation to use when the item is in view. The default here is "fade-50".
 * @returns The item to add to the list.
 */
function addItem(item, observable) {
        //   <div class="row vh-100 d-flex">
        //     <div class="col m-auto">
        //       <p class="text-center fs-3">Love from the Universe — Gorgeous updates<br>from the things you love.</p>
        //     </div>  
        // </div>


  // console.log("adding item", {
  //   item: item,
  //   observable: observable,
  //   animation: animation
  // })
  
  return {
    n: "div",
    c: "row vh-100 d-flex",
    i: {
      n: "div",
      c: "col m-auto",
      // "ua-observe": observable,
      // "ua-observe-animation": observable ? animation : false,
      i: item
    }

  }
}

/** 
<article class="container-fluid">
        <div class="full-screen-sticky position-sticky">
          <div class="vh-100 bg-info"></div>
        </div>
        <div class="full-screen-sticky position-sticky" style="z-index: 100000; margin-top: -100vh;">
          <!-- <div class="vh-100 bg-info"> -->
            <div class="row vh-100 d-flex">
              <div class="col m-auto">
                <h2 class="text-center fs-3 bg-blur-black text-white observer-visible" ua-observe="">Be Creative.</h2>
              </div>  
          </div>
          <!-- </div> -->
        </div>
        <div class="row vh-100 d-flex">
            <div class="col m-auto">
              <p class="text-center fs-3">Love from the Universe — Gorgeous updates<br>from the things you love.</p>
            </div>  
        </div>
        <div class="row vh-50 d-flex">
          <div class="col m-auto">
            <p class="text-center fs-3">Create your brand.
          </p></div>  
        </div>
        <div class="row vh-50 d-flex">
          <div class="col m-auto">
            <p class="text-center fs-3">Start that podcast.
          </p></div>  
        </div>
        <div class="row vh-50 d-flex">
          <div class="col m-auto">
            <p class="text-center fs-3">Sell those sneakers.
          </p></div>  
        </div>
        <div class="row vh-50 d-flex">
          <div class="col m-auto">
            <p class="text-center fs-3">Share that video.
          </p></div>  
        </div>
        <div class="row vh-50 d-flex">
          <div class="col m-auto">
            <p class="text-center fs-3">Real-time subscriptions.
          </p></div>  
        </div>
        <div class="row vh-50 d-flex">
          <div class="col m-auto">
            <p class="text-center fs-3">An interactive cornerstones for connection.
          </p></div>  
        </div>
</article> */
