/*
<div class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="customSwitch1">
  <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
</div>
*/

var f = require("../scripts/f.js");
var uac = require("./templates/ua.control.js");
var uae = require("../elements/templates/ua.element.js");

var namespace = "ua.c.switch";
exports.namespace = namespace;

exports.render = function (ele) {
 var options = uac.GetOptions(ele);

 //already appended
 if (!(options.uarendered === true)) {
  var customswitch = document.createElement("div");
  customswitch.classList.add("custom-control");
  customswitch.classList.add("custom-switch");

  var input = document.createElement("input");
  input.id = options.name;

  //this tells UA not to add it to the input list
  // but ask the control
  input.setAttribute("uaignore", "true");
  input.setAttribute("name", options.name);
  input.setAttribute("type", "checkbox");

  input.classList.add("custom-control-input");

  if (input.value === true) {
    input.checked = true;
  }

  input.willValidate = true;

  var label = document.createElement("label");
  label.classList.add("custom-control-label");
  label.classList.add("text-color");
  label.setAttribute("for", options.name);
  label.innerText = options.title;


  //pass all attributes to the input field
  input = PassAtributes(options, input);

  customswitch.appendChild(input);
  customswitch.appendChild(label);

  //ele.setAttribute(s"uarendered", true);
  //ele.appendChild(customswitch);

  // ele.setAttribute("uarendered", true);
  // ele.appendChild(formgroup);
  customswitch.addEventListener("prepare", Prepare);
  return uac.Render_FinalStep(customswitch);

 }

}

function Prepare(event) {
  var control = this;
  var jsonexport = uac.PrepareOutput(control);
  uac.StoreValue(control, jsonexport);
}

//copy from formgroup
function PassAtributes(ele, input, _except = []) {
  var except = ["class", "style"];
  except = except.concat(_except);

  return uae.PassthroughOptions(input, ele, except);
}
