/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "conformCurrencStandard";

/**
 * Conforms currency to a standard format.
 * Example: for 1000.00 = $ 1,000.00
 * @param {*} value The float or string to convert to a currency string.
 * @returns {String} The currency string.
 */
function conformCurrency(input) {
    // Convert the input to a string
    let flv = input.toString();

    // Remove $, commas, and spaces
    flv = flv.replace(/[$,\s]/g, "");

    // Convert to a number if it's a string
    flv = parseFloat(flv);

    // Round to the nearest cent
    flv = Math.round(flv * 100) / 100;

    // Force to a string and ensure it has two decimal places
    flv = flv.toFixed(2);

    // Add commas for thousands, millions, etc.
    let parts = flv.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
 
} 

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: conformCurrency,
    tests: [{
        namespace: `${namespace}.default`,
        must: "12.00",
        run: async () => {

            //test conformCurrency

            var value = "12";
            var result = conformCurrency(value);
            return result;

        }
    }]
};