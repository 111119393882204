/*!
 * Copyright (C) 2022 Justin K Kazmierczak - All Rights Reserved.
 * The use of this code is subject to The Terms of Service.
 * To access these terms visit: https://egtuniverse.com/legal/terms
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * 
 * Universe App Scafolding Object
 * Turns a string into an object appended to a specificed object.
 * 
 */

// var $ = require("../modules/scafolding");
var namespace = "objectifyProperty";

/**
 * Appends a prop to an object by crafting it's namespace.
 * Safe function, returns success as boolean.
 * 
 * NOTE: Only javscript varable names will work.
 * @param {*} _ns The namespace to convert to an object. 
 * @param {*} obj The object to append the namespace to.
 * @param {*} getter The getter function to apply to the property. getter(namespace)
 * @param {*} setter The setter function to apply to the property. setter(namespace, value)
 * @param {boolean} overide Should I overide properties.
 * @returns Success true or false.
 */
function objectify(_ns, obj, getter, setter, overide = true) {

    try {

        //split based off of "."
        //the | and : may not function here
        var ns = _ns.split(".");
        var cnt = 0;
        var lvl = obj;
        while (ns.length > cnt) {

            var stage = ns[cnt];
            
            //Am I the last one?
            if (ns.length - 1 == cnt)  {

                if (!overide) {
                    //check for overiding
                    if (stage in lvl) {

                        // $.log.add("overide",
                        // `${_ns} requested an overide of a property that was blocked.`, {
                        //     namespace: _ns,
                        //     stage: stage,
                        //     overide: overide,
                        //     lvl: lvl,
                        //     error: error
                        // }, 2, namespace); 
                
                        return false;
                    }
                }

                
                Object.defineProperty(lvl, stage,{
                    get() {
                        return getter(_ns);
                    },
                    set(value) {
                        setter(_ns, value);
                    }
                });

                // we are using properties as opposed to objects
                // lvl[stage] = prop;

            } else {

                //check if the level exsists and than objectify
                if (stage in lvl) {
                    if (lvl[stage] == null) lvl[stage] = {};
                    if (lvl[stage] == undefined) lvl[stage] = {};
                    // lvl = lvl[stage]
                } else {
                    lvl[stage] = {};
                }

                lvl = lvl[stage];
            }

            cnt = cnt + 1;
        }

    } catch (error) {

        // $.log.add("function",
        // `${_ns} failed to objectify.`, {
        //     namespace: _ns,
        //     error: error
        // }, 2, namespace); 

        return false;

    }


}

module.exports = {
    //name: "objectify",
    namespace: namespace,
    function: objectify,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            //test objectifyProperty
            var obj = {};
            var prop = "test";
            var overide = true;
            var _ns = "test.test.test";
            var getter = function(namespace) {
                return "test";
            }
            var setter = function(namespace, value) {
                return true;
            }

            objectify(_ns, obj, getter, setter, overide);

            if (obj.test.test.test == "test") {
                return true;
            } else {
                return false;
            }

        }
    }] 
};