/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "chart.js";
exports.namespace = namespace;

// var chart = require("chart.js/auto").Chart;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
var element = require("../interface/element.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "A Chart.js Chart",
  description: "An instance of a chart.js chart.",
  supportServer: false,
  fields: {
    "inner": {
      type: "array of list.item", 
      description: "The inner items of the listgroup."
    }
  },
  external: {
    src: "https://cdn.jsdelivr.net/npm/chart.js",
    // integrity: "sha512-L0Shl7nXXzIlBSUUPpxrokqq4ojqgZFQczTYlGjzONGTDAcLremjwaWv5A+EDLnxhQzY5xUZPWLOLqYRkY0Cbw==",
    // crossorigin: "anonymous",
    // referrerpolicy: "no-referrer",
    async: true
    // type: "module"
  },
  required: required
} 

//<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/4.4.1/chart.min.js" integrity="sha512-L0Shl7nXXzIlBSUUPpxrokqq4ojqgZFQczTYlGjzONGTDAcLremjwaWv5A+EDLnxhQzY5xUZPWLOLqYRkY0Cbw==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
// <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/4.4.1/chart.min.js" integrity="sha512-L0Shl7nXXzIlBSUUPpxrokqq4ojqgZFQczTYlGjzONGTDAcLremjwaWv5A+EDLnxhQzY5xUZPWLOLqYRkY0Cbw==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The rendered DOM object.
 */
exports.render = async function (options) {


  if ("UATisServer" in window && window.UATisServer) {

    //I'm not comapatible with server side rendering (yet)

    var onlyClientSideRender = {
      "n": "json",
      "type": "ua/interface",
      // "render": "no-server",
      "i": JSON.stringify(options, null, 0) + ""
    };

    // console.info("Only client side render", onlyClientSideRender);
    return await jsonRender.render(onlyClientSideRender);

  };

  var chart = Chart;

  if (!(chart)) {
    console.error("Chart.js is not loaded. Please include it in your project.");
    return await element.CreateError(namespace, "Chart.js is not loaded. Please include it in your project.", options);
  }

  var ctx = document.createElement("canvas");


  if ("color" in options) {
    chart.defaults.backgroundColor = options.color;
  }

  if ("borderColor" in options) {
    chart.defaults.borderColor = options.borderColor;
  }

  if ("color" in options) {
    chart.defaults.color = options.color;
  }

  var chrt = new chart(ctx, options);

  return ctx;

}

/** 
 * 
 * 
 * 
<div class="list-group">
  <a href="#" class="list-group-item list-group-item-action active" aria-current="true">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small>3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small>And some small print.</small>
  </a>
  <a href="#" class="list-group-item list-group-item-action">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small class="text-body-secondary">3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small class="text-body-secondary">And some muted small print.</small>
  </a>
  <a href="#" class="list-group-item list-group-item-action">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">List group item heading</h5>
      <small class="text-body-secondary">3 days ago</small>
    </div>
    <p class="mb-1">Some placeholder content in a paragraph.</p>
    <small class="text-body-secondary">And some muted small print.</small>
  </a>
</div>
*/
