/*!
 * (C) 2024. Justin K Kazmierczak. All Rights Reserved.
 *
 * Build for The Universe.
 * Subject to The Universe Terms of Service and Privacy Policy.
 * 
 * Built for Universe App Tools.
 * 
 * This module handles rendering Universe App Tools elements.
 * 
 */

var namespace = "ua.element";
module.exports.namespace = namespace;

//Import the functions
var f = require("../scripts/f.js");

var errorModule = require("../../../uam/errors.js");

var errors = errorModule.create(namespace);
errors.addErrors([{
    id: "noErrorsFound",
    title: "No Errors Found",
    description: "No Errors were found in the element's definition.",
}, {
    id: "noNamespace",
    title: "No Namespace",
    description: "The element does not have a namespace property.",
}]);

/**
 * The registry for the Universe App Tools Elements.
 * This is blank until provided in init.
 */
var registry = require("../../../uam/registry.js")("boilerplate");

/**
 * UAI: Universe App Tools Interface connection
 */
var uai = require("../interface/interface.js");

/** Add events */
var events = require("../../../uam/events.js");
// console.log("Adding Prepared Events...");
events.on("interface.prepare", Prepare);

/**
 * The JSON Render object.
 */
var jsonRender = require("./jsonRender.js");

/**
 * The placeholder element.
 * Added during init.
 */
var elePlaceholder = null;

/**
 * The server not supported placeholder.
 * Added during init.
 */
var serverNotSupportedPlaceholder = null;

/**
 * The external script element.
 * Added during init.
 */
var externalScriptElement = null;

/**
 * If the server is running.
 * Updated during init.
 */
var isServer = false;

/**
 * If the Universe App Tools Elements are initalized.
 */
var isInitalized = false;

// var errors = require("../../../uam/errors.js").create(namespace);
// errors.addErrors({

// })

// to be replaced by the uae.error element
// var alert = require("../elements/ua.e.alert.js");

/**
 * Attaches the ua rendering object registry to the rendering interface.
 * @param {*} _registry The ua elements registry.
 */
function init(_registry) {

    isInitalized = true;

    registry = _registry;
    
    
    try {
        elePlaceholder = registry.search("ua.element.placeholder");   
    } catch (error) {
        throw new Error(`The UElement Placeholder (${define.namespace}) is not registered in the registry.`);
    }

    try {
        var test =  registry.search("ua.element.error");   
    } catch (error) {
        throw new Error(`The Element Error (${define.namespace}) is not registered in the registry.`);
    }

    try {
        serverNotSupportedPlaceholder = registry.search("ua.element.serverNotSupported");
    } catch (error) {
        throw new Error(`The Element Server Not Supported (${define.namespace}) is not registered in the registry.`);
    }


    try {
        externalScriptElement = registry.search("ua.element.scripts");
    } catch (error) {
        throw new Error(`The Element External Scripts (${define.namespace}) is not registered in the registry.`);
    }

    isServer = uai.server();

} module.exports.init = init;

/**
 * Prepares the Universe App Tools Elements for rendering.
 */
async function Prepare() {
    
    // console.log("Preparing Universe App Tools Elements...");

    //if the registry is not set
    if (registry === null) {
        throw new Error(`The registry for the Universe App Tools Elements is not set.`);
    }

    var unprepared = [];

    //for each element in the registry
    for (var i in registry.registry) {

        var ele = registry.registry[i];

        // console.log("Element is being prepared...", {
        //     ele
        // });

        try {
                
            if ("define" in ele) {

                
                if ("supportServer" in ele.define) {
                    if (!ele.define.supportServer) {
                        if (isServer) {
                            continue;
                        }
                    }
                }

                //load external scripts
                if ("external" in ele.define) {

                    // console.log(`Processing External Scripts for ${ele.namespace}...`);
                    //if it's an object and not an array
                    if ((typeof ele.define.external === "object") && (!Array.isArray(ele.define.external))) {
                        var script = {
                            ...ele.define.external,
                            "from": ele.namespace,
                            "namespace": "ua.element.scripts"
                        };
                        // console.log(`Adding External Script...`, {
                        //     script
                        // });
                        document.head.appendChild(await jsonRender.render(script, {
                            "noPlaceholder": true,
                        }));
                    } else if (Array.isArray(ele.define.external)) {
                        for (var i = 0; i < ele.define.external.length; i++) {
                            var script = {
                                ...ele.define.external,
                                "from": ele.namespace,
                                "namespace": "ua.element.scripts"
                            };
                            // console.log(`Adding External Script...`, {
                            //     script
                            // });
                            document.head.appendChild(await jsonRender.render(script, {
                                "noPlaceholder": true,
                            }));
                        }
                    } else {
                        console.error(`${ele.namespace}.The external script must be an object or an array.`, {
                            external: ele.define.external
                        });
                    }

                };


                //load prepare scripts

            } else {
                // console.error(`The element ${ele.namespace} does not have a define object.`);
            }
            
        } catch (error) {
            var ns = "unknown";

            if ("namespace" in ele) {
                ns = ele.namespace;
            }

            console.error(`The element: ${ns} could not be prepared.`, 
                {error: f.GenerateSafeError(error), 
                    ele});
            unprepared = unprepared.push(ns);

        }

    }

} module.exports.Prepare = Prepare;

/**
 * Renders a Universe App Tools Element.
 * @param {*} jsonObj The UA/JSON to render. This should already be an object.
 * @param {*} reportable The additional data to report with an error, such as a previous element, and the namespace.
 * @param {*} options The options to pass to the element.
 * @property {boolean} options.noPlaceholder If true, the placeholder will not be added.
 * @property {boolean} options.doNotPrerender If true, the ua/json element will not be prerendered into a dom and immedaitly returned. Will not render a placeholder (as it is being used internally) unless the return from the element is a dom thus it will continue rendering normally.
 * @reutrns The rendered DOM object.
 */
async function RenderElement(_jsonObj, reportable = true, options = {}) {

    if (!isInitalized) {
        throw new Error(`The Universe App Tools Elements are not initalized.`);
    }

    //clone it to work with the json Object itself and not the reference.
    var jsonObj = {..._jsonObj};
    var domElement = null;
    // var addAttrToUAE = {};

    if (!("namespace" in jsonObj)) {
        errors.noNamespace.throw(jsonObj);
    }

    var element = registry.search(jsonObj.namespace);
    
        if (!(element)) {
            return await CreateError(jsonObj.namespace, new Error(`The element (${jsonObj.namespace}) is not registered in the registry and cannot be rendered.`), {
                jsonObj: jsonObj,
                reportable: reportable
            });
        }

        try {
        
            if ("supportServer" in element.define) {
                if (!element.define.supportServer) {                   
                    if (isServer) {
                        return await serverNotSupportedPlaceholder.render({
                            inner: jsonObj
                        });
                    }
                }
            }
            //work through the elements options
            for (var key in element.define.fields) {

                //if the key is not in the json object
                if (!(key in jsonObj)) {

                    //if it's required
                    if (element.define.fields[key].required) {
                        return await CreateError(jsonObj.namespace, new Error(`According to the Element ${jsonObj.namespace}, the field "${key}" is required, but not provided.`), {
                            jsonObj: jsonObj,
                            reportable: reportable
                        });
                    }

                    //if it's not required, but it has a default
                    if ("default" in element.define.fields[key]) {
                        jsonObj[key] = element.define.fields[key].default;
                    }

                    // //if addtouae is true
                    // if ("addtouae" in element.define.fields[key]) {
                    //     if (element.define.fields[key].addtouae) {
                    //         addAttrToUAE[key] = jsonObj[key];
                    //     }
                    // }

                }

            }
            
        /**
         * Safely share the jsonObject by cloning it to the render core.
         */

        // console.log(`Rendering ${jsonObj.namespace}...`, {
        //     jsonObj: jsonObj
        // });

        domElement = await element.render(jsonObj);

        //If I'm undefined or null, I'll render a safe version
        if (domElement === undefined || domElement === null) {
            // return domElement;
            domElement = await RenderSafeUndefinedReturn(domElement, {
                element: element
            }, jsonObj.namespace);
        }
        
        //Check if the element is a node
        if (!(f.isDomElement(domElement))) {

            if ("doNotPrerender" in options) {
                if (options.doNotPrerender) {
                    return domElement;
                }
            }

            //treat it as json
            domElement = await jsonRender.render(domElement);

        }

        // Let's auto passthrough the options
        if ("passthrough" in element.define) {
            if (element.define.passthrough) {

                //if it's just a type boolean
                if (typeof element.define.passthrough === "boolean") {

                    domElement = f.PassOptions(jsonObj, domElement);

                } else if (typeof element.define.passthrough === "object") {
                    
                    domElement = f.PassOptions(jsonObj, domElement, element.define.passthrough.except);

                }
            }
        }

        



    } catch (error) {
        error.namespace = jsonObj.namespace;    
        domElement = await CreateError(jsonObj.namespace, error, {
            jsonObj: jsonObj,
            reportable: reportable
        });
    }

    //ensure the domElement is not undefined or null
    domElement = await RenderSafeUndefinedReturn(domElement, {
        json: jsonObj
    }, jsonObj.namespace);


    /** add the new placeholder */
    if ("noPlaceholder" in options) {
        if (options.noPlaceholder) {
            return domElement;
        }
    }

    // console.log("Rendering Placeholder...", {
    //     domElement: domElement
    // });


    domElement = await elePlaceholder.render({
        define: element.define,
        inner: domElement,
        name: jsonObj.name,
        options: jsonObj
    });

    // replaced with the UAE element
    // //append the addAttrToUAE to the domElement
    // for (var key in addAttrToUAE) {
    //     domElement.setAttribute(key, addAttrToUAE[key]);
    // }

    return domElement;

} module.exports.RenderElement = RenderElement;
module.exports.render = RenderElement;

/**
 * Renders a error for a Universe App Tools Element that isn't properly instantiated.
 * It checks if a rendered function is undefined or null, and if so, returns a consistent
 * error the developer can use to diagnose the issue.
 * @param {*} rtn The object to return - should be a dom, or a document fragment.
 * @param {*} data The data to include with a report
 * @param {*} namespace The namespace of the object. A defualt of "unknwon" will be used if not provided.
 * @returns The orginal rtn object or a created error object.
 */
async function RenderSafeUndefinedReturn(rtn, data, namespace = "unknwon") {

    //expected types are: dom, document fragment

    //if rtn is a dom element
    if (f.isDomElement(rtn)) {
        return rtn;
    } 

    //if the rtn has failed for whatever reason.


    if (data) {
        //does data have a namespace tree
        if ("namespaceTree" in data) {
            var mytree = data.namespaceTree.join(" > ");
            console.log(`Namespace Tree: ${mytree}`, {
                tree: data.namespaceTree
            });
        }
    }

    // //if rtn is undefined
    // if ((rtn === undefined) || (rtn === null)) {
        // console.error(`The return value of ${namespace} is undefined.`, data);
        return await CreateError(namespace, new Error(`${namespace}: The element did not return an expected type. (Did it return anything?) Returned type: ${typeof rtn}.`), {
            namespace: namespace,
            rtn: rtn,
            data: data
        });
    // } else {
        // return rtn;
    // }

    // return "This is a placeholder for the render safe undefined return.";

} module.exports.RenderSafeUndefinedReturn = RenderSafeUndefinedReturn;


/**
 * Creates a consistent error for Universe App Tools Elements.
 * It uses the ua.element.error Universe App Tool Element in the registry.
 * @param {*} error The error to display
 */
async function CreateError(_namespace, error, ...args) {

    console.log(`UAT: ${error} (${_namespace})`, {
        stack: error.stack,
        error: f.GenerateSafeError(error),
        args: args
    });

    return await jsonRender.render({
        n: "ua.element.error",
        "_namespace": _namespace,
        error: error,
        args: args
    });

} module.exports.CreateError = CreateError;


var GenerateRandomID = require("../../../uam/functions/generateRandomID.js");
const generateSafeError = require("../../../uam/functions/generateSafeError.js");
module.exports.GenerateRandomID = GenerateRandomID.function;


/**
 * The save feature on the interface will prepare the controls in the provided selector,
 * validate them using their definition, and return them to the requesting function.
 * @param {*} _selector The DOM Query Selector to find the controls to save. Defaults to "body".
 * @returns {Object} A JSON encodable object ready to be saved to the server, a database, or the local web storage.
 * @property {*} repo.success The success object (is this field ready to be saved).
 * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
 * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
 * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
 * @property {*} repo.errors.input.id The id of the input field, if applicable.
 * @property {*} repo.errors.input.name The name of the input field if applicable.
 * @property {*} repo.errors.type The type of error that occured.
 *  - Supports: "validation" - The input or field or control is invalid
 *  - Supports: "thowable" - Processing this field caused a throwable to error out.
 * @property {*} repo.errors.message The message to display to the user.
 * @throws If a control does not have a namespace, the Save will fail.
 */
async function Save(_selector = "body") {

    // var rtn = {};
  
    //get all the controls
    var controls = document.querySelectorAll(_selector + " uae[name]");

    // console.log(`Saving Controls with the selector: ${_selector}`, {
    //     controls: controls
    // });

    var rtn = {};
    rtn.success = true;

    for (var i = 0; i < controls.length; i++) {

        var control = controls[i];

        if (!control.getAttribute("namespace")) {
            console.error(`The control ${control.getAttribute("name")} does not have a namespace attribute.`, {
                control
            });
            throw new Error(`The control ${control.getAttribute("name")} does not have a namespace attribute. Your data can not be saved.`);
        }

        try {
            var ele = registry.search(control.getAttribute("namespace"));

            var repo = {
                success: true,
                data: null,
                errors: [],
                namespace: control.getAttribute("namespace")
            }

            if (!ele) {
                // console.error(`The element ${control.getAttribute("namespace")} is not registered in the registry.`, {
                //     control,
                //     element: ele
                // });
                throw new Error(`The element ${control.getAttribute("namespace")} is not registered in the registry.`);
                continue;
            }

            if (!("define" in ele)) {
                // console.error(`The element ${control.getAttribute("namespace")} does not have a definition object.`, {
                //     control,
                //     element: ele
                // });
                throw new Error(`The element ${control.getAttribute("namespace")} does not have a defintion object.`);
                continue;
            }

            if (control.getAttribute("name") == "success") {
                // console.error(`The control ${control.getAttribute("namespace")} has a name of "success". This is a reserved name and can not be used.`, {
                //     control,
                //     element: ele
                // });
                // throw new Error(`The control ${control.getAttribute("namespace")} has a name of "success". This is a reserved name and can not be used.`);
                continue;
            }

            /** Pre Validate Control */

            if ("prevalidate" in ele.define) {
                if (ele.define.prevalidate) {
                
                    //clear control classes
                    control.classList.remove("is-invalid");
                    control.classList.remove("is-valid");

                    //get all inputs
                    var inputs = control.querySelectorAll("input, select, textarea");

                    //validate the inputs using html validation
                    for (var j = 0; j < inputs.length; j++) {
                        var myInput = inputs[j];

                        //clear the input classes
                        myInput.classList.remove("is-invalid");
                        myInput.classList.remove("is-valid");

                        if (!myInput.checkValidity()) {
                            repo.errors.push({
                                "input": {
                                    id: myInput.id,
                                    name: myInput.name
                                },
                                "message": inputs[j].validationMessage,
                                "type": "validation"
                            });
                            repo.success = false;
                            rtn.success = false;

                            //add the bootstrap invalidation class
                            myInput.classList.add("is-invalid");
                            control.classList.add("is-invalid");

                        } else {
                            //it's valid!
                            myInput.classList.add("is-valid");
                            control.classList.add("is-valid");
                        }
                    }

                }
            }

            if ("save" in ele) {

                var nRepo = await ele.save(control.getAttribute("name"), control, repo);

                //is nRepo undefined, or null, or empty
                if (nRepo === undefined || nRepo === null || Object.keys(nRepo).length === 0) {
                    throw new Error(`The save function for ${control.getAttribute("namespace")} returned an empty object. It should return a JSON object with the data to save.`);
                }

            } else {
                //pull all the inputs again including radio groups and checkbox

                var inputs = control.querySelectorAll("input, select, textarea");

                //for each input pull the name and the value, adding to data
                for (var j = 0; j < inputs.length; j++) {
                    var myInput = inputs[j];
                    if (myInput.type === "radio") {
                        if (myInput.checked) {
                            repo.data[myInput.name] = myInput.value;
                        }
                    } else if (myInput.type === "checkbox") {
                        repo.data[myInput.name] = myInput.checked;
                    } else {
                        repo.data[myInput.name] = myInput.value;
                    }
                }

            }

            /** append it to the save */

            if (!(repo.success)) {
                rtn.success = false;
            }

            rtn[control.getAttribute("name")] = repo;
       
        } catch (error) {

            var err = new Error(`The control ${control.getAttribute("name")} with namespace ${control.getAttribute("namespace")} could not be saved. ${error.toString()}`);
            error = f.GenerateSafeError(error);
            console.error(err.toString(), {
                control, error
            });

            rtn.success = false;

            rtn[control.getAttribute("name")] = {
                success: false,
                data: null,
                namespace: control.getAttribute("namespace"),
                errors: {
                    ...error,
                    message: err.toString(),
                    type: "thowable"
                }
            };

        }
    }

    // console.log(`The save is as follows...`, rtn);

    return rtn;
  
} module.exports.save = Save;

/**
 * Loads all the selected controls with the updated data.
 * After a field has sent, it will peform prevalidation on the fields.
 * @param {*} data The data should be "name": "value" object. {email: "this@that.com"}
 * @param {*} selector The selector to find the controls to load. Defaults to "body".
 */
async function Load(data, selector = "body") {

    // console.info("Loading Data...", {
    //     data, selector
    // });

    var controls = document.querySelectorAll(selector + " uae[name]");

    // console.log(`Loading Controls with the selector: ${selector}`, {
    //     controls: controls
    // });

    for (var i = 0; i < controls.length; i++) {

        var control = controls[i];

        if (!control.getAttribute("namespace")) {
            console.error(`The control ${control.getAttribute("name")} does not have a namespace attribute.`, {
                control
            });
            throw new Error(`The control ${control.getAttribute("name")} does not have a namespace attribute. Your data can not be saved.`);
        }

        try {
            var ele = registry.search(control.getAttribute("namespace"));

            // console.log("Working with Element...", {
            //     control, ele
            // });


            if (!ele) {
                console.error(`The element ${control.getAttribute("namespace")} is not registered in the registry.`, {
                    control,
                    element: ele
                });
                throw new Error(`The element ${control.getAttribute("namespace")} is not registered in the registry.`);
                continue;
            }

            if (!("define" in ele)) {
                console.error(`The element ${control.getAttribute("namespace")} does not have a definition object.`, {
                    control,
                    element: ele
                });
                throw new Error(`The element ${control.getAttribute("namespace")} does not have a defintion object.`);
                continue;
            }

            if (control.getAttribute("name") == "success") {
                console.error(`The control ${control.getAttribute("namespace")} has a name of "success". This is a reserved name and can not be used.`, {
                    control,
                    element: ele
                });
                throw new Error(`The control ${control.getAttribute("namespace")} has a name of "success". This is a reserved name and can not be used.`);
                continue;
            }

            var name = control.getAttribute("name");

            if (!(name in data)) {
                // console.warn(`The control ${control.getAttribute("name")} with namespace ${control.getAttribute("namespace")} does not have a value in the data object using the selector ${selector}`, {
                //     control, data});
                continue; 
            }

            var value = data[name];

            if ("load" in ele) {

                // console.log(`Loading ${control.getAttribute("name")} with namespace ${control.getAttribute("namespace")}`, {
                //     control, ele, name, value
                // });

                await ele.load(name, control, value);

            } else {

                // console.log(`NO LOAD DEFINED - Loading ${control.getAttribute("name")} with namespace ${control.getAttribute("namespace")}`, {
                //     control, ele, name, value
                // });

                //pull all the inputs again including radio groups and checkbox

                var inputs = control.querySelectorAll("input, select, textarea");

                //for each input pull the name and the value, adding to data
                for (var j = 0; j < inputs.length; j++) {
                    var myInput = inputs[j];
                    if (myInput.type === "radio") {
                        if (myInput.checked) {
                            myInput.value = data[myInput.name];
                        }
                    } else if (myInput.type === "checkbox") {
                        myInput.checked = data[myInput.name];
                    } else {
                        myInput.value = data[myInput.name];
                    }
                }

            
            }

            //do prevalidation if it's in the define
            if ("prevalidate" in ele.define) {
                if (ele.define.prevalidate) {
                    //clear control classes
                    control.classList.remove("is-invalid");
                    control.classList.remove("is-valid");

                    //get all inputs
                    var inputs = control.querySelectorAll("input, select, textarea");

                    //validate the inputs using html validation
                    for (var j = 0; j < inputs.length; j++) {
                        var myInput = inputs[j];

                        //clear the input classes
                        myInput.classList.remove("is-invalid");
                        myInput.classList.remove("is-valid");

                        if (!(myInput.value == "")) {

                            if (!myInput.checkValidity()) {
                                //add the bootstrap invalidation class
                                myInput.classList.add("is-invalid");
                                control.classList.add("is-invalid");

                            } else {
                                //it's valid!
                                myInput.classList.add("is-valid");
                                control.classList.add("is-valid");
                            }
                        }
                    }

                }
            }

        } catch (error) {

            var err = new Error(`The control ${control.getAttribute("name")} with namespace ${control.getAttribute("namespace")} could not be saved. ${error.toString()}`);
            error = f.GenerateSafeError(error);
            console.error(err.toString(), {
                control, error
            });

        }

    }
        
} module.exports.load = Load;

/**
 * Get's the UAE placeholder object by a name.
 * @param {*} name The name of the placeholder.
 * @returns The placeholder DOM Object.
 */
function GetUAEbyName(name) {
    return document.querySelector(`uae[name="${name}"]`);
} module.exports.GetUAEbyName = GetUAEbyName;

/**
 * Append the window components
 */

/**
 * Working with Elements.
 */

/**
 * Get the errors for the element.
 * @param {*} define The define object for the element.
 * @returns The errors object.
 */
function getErrors(define) {

    if (!("errors" in define)) {
        errors.noErrorsFound.throw(define);
    }

    var errors = [];

    //for each error property in errors object
    for (var key in define.errors) {
        //add the error to the errors array
        errors.push({
            id: key,
            title: define.errors[key].title,
            description: define.errors[key].description
        });
    }

    var erz = errorModule.create(define.namespace);
    erz.addErrors(errors);

    return erz;
} module.exports.getErrors = getErrors;