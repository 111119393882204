/*!** STOP ***

  This element is purely for editing and e-mail.

  If you are a developer, writing your pages (and not building them in database)
  try to use the HTML <p> tag and not an <element> tag.

  The element tag is designed for database stored tags, e-mail, and editing them.

*** STOP ***/

var f = require("../scripts/f.js");
var uae = require("./templates/ua.element.js");

var required = ["inner"];

exports.namespace = "ua.e.paragraph";
exports.render = function (ele) {
  var options = uae.GetOptions(ele, required);
  var para = document.createElement("p");
  para.innerHTML = ele.innerHTML;

  return para;

};

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Creates the object that will be used to render the e-mail
 * @param  {*} $my   [The e-mail config]
 * @param  {*} brand [The brand colors and style]
 * @param  {*} element   [The element]
 * @return {*}       [description]
 */
exports.renderEmail = function($my, brand, element) {

  var options = uae.ValidateOptions(element, required);
  if (options.success == false) {
    return options;
  } else {
    options = options.options;
  }

  var tablerow = document.createElement("tr");
  tablerow.setAttribute("border", 0);
  tablerow.setAttribute("cellpadding", 0);
  tablerow.setAttribute("cellpadding", 0);

  var tablecell = document.createElement("td");
  tablecell.setAttribute("style", `${$my.style.defaultcell} ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};`);

  var p = document.createElement("p");
  p.setAttribute("style", `${$my.style.padding} margin:0px; ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};`);
  p.innerHTML = options.inner;

  tablecell.appendChild(p);
  tablerow.appendChild(tablecell);

  return {
    success: true,
    orginal: uae,
    row: tablerow
  };

  // return {
  //   success: false,
  //   fault: "pending",
  //   error: "This part of the function is stll being written",
  //   original: uae
  // }

}
