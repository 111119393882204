/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "convertToAccountingDate";

// var jckConsole = require("@jumpcutking/console");

/**
 * Converts a date to a 14 digit accounting date. YYYY MM DD HH MM SS
 * All dates are converted to UTC.
 * @param {*} dateInput The date to convert. Can be a Date object or a string that can be converted to a Date object.
 * @returns YYYY MM DD HH MM SS format with padded zeros. 4 digit year, 2 digit month, 2 digit day, 2 digit hour, 2 digit minute, 2 digit second.
 * @throws Error if date is not a valid date object.
 */
function convertToAccountingDate(dateInput) {

        //if the dateinput is undefined throw an error
        if(dateInput == undefined){
            throw new Error("The input is undefined.");
        }

        let date;
        // console.info("Original Date", dateInput);
        
        // If dateInput is not a Date object, make it one
        if (!(dateInput instanceof Date)) {
            date = new Date(dateInput);
            if (isNaN(date.getTime())) { // Better way to check for an invalid date
                console.error("Invalid Date", { date: dateInput });
                throw new Error("Invalid Date");
            }
        } else {
            date = dateInput;
        }
        
        // Extract components using UTC methods
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        let day = String(date.getUTCDate()).padStart(2, '0');
        let hours = String(date.getUTCHours()).padStart(2, '0');
        let minutes = String(date.getUTCMinutes()).padStart(2, '0');
        let seconds = String(date.getUTCSeconds()).padStart(2, '0');
      
        let rtn = year + month + day + hours + minutes + seconds;
      
        // console.info("Accounting Date", {
        //     date: date.toISOString(), // This shows the date in ISO format, in UTC
        //     rtn: rtn
        // });
    
        return rtn;
    

}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: convertToAccountingDate,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
            
            var date = "October 26, 2021 1:31:00 PM";
            var result = convertToAccountingDate(date);
            
            if (result == "20211026133100") {
                return true;
            } else {
                return false;
            }
        }
    }]
};