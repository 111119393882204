/*!
 * Copyright (C) 2022 Justin K Kazmierczak - All Rights Reserved.
 * The use of this code is subject to The Terms of Service.
 * To access these terms visit: https://egtuniverse.com/legal/terms
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * 
 * Universe App Scafolding Object
 * Turns a string into an object appended to a specificed object.
 * 
 */

// var $ = require("../modules/scafolding");
var namespace = "jsonschema";

const { Draft04, Draft06, Draft07, Draft, JsonError } = require("json-schema-library");


/**
 * Using the jsonschema library, validate an object against a schema.
 * Throws an error if the object is invalid.
 */
function validate(schema, object, _throw = false) {
  // const myJsonSchema = require("./myJsonSchema.json");
    // const myData = require("./myData.json");

    try {
        const jsonSchema = new Draft07(schema);
        const errors = jsonSchema.validate(object);
    
        // var Validator = require('jsonschema').Validator;
        // var v = new Validator();
        // var result = v.validate(object, schema);
    
        if (errors) {
            if (errors.length > 0) {
    
                // console.log("Errors", errors);
    
                if (_throw) {
    
                    var err = "";
                    errors.forEach(function (error) {
                        err += `${error.code} (${error.type}) ${error.message}\n`;
                    });
    
                    throw `JSON object could not be validated by Schema. Errors ${err}`;
    
                } else {
    
                    var err = [];
                    errors.forEach(function (error) {
                        err.push({
                            code: error.code,
                            message: error.message,
                            type: error.type
                        });
                    });
    
                    return {
                        success: false,
                        errors: err
                    }
                }
            }
        }
    
        if (_throw) {
            return true;
        } else {
            return {
                success: true,
                errors: []
            };
        }  
    
     } catch (error) {
        console.error("Failed to validate order.", error);
     }
}

module.exports = {
    //name: "objectify",
    namespace: namespace,
    function: validate,
    tests: [{
        namespace: `${namespace}.test`,
        must: true,
        run: async () => {

            var schema = {
                "id": "/SimplePerson",
                "type": "object",
                "properties": {
                    "name": {
                        "type": "string"
                    },
                    "age": {
                        "type": "integer",
                        "minimum": 0
                    }
                },
                "required": ["name"]
            };

            var object = {
                "name": "John Doe",
                "age": 45
            };

            validate(schema, object);

            return true
            
            
        }}, {
            namespace: `${namespace}.test2`,
            must: true,
            run: async () => {

                //must fial
                var schema = {
                    "id": "/SimplePerson",
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string"
                        },
                        "age": {
                            "type": "integer",
                            "minimum": 0
                        }
                    },
                    "required": ["name"]
                };
    
                var object = {
                    "name": false,
                    "age": -1,
                    "hair": "brown"
                };

                try {
                    validate(schema, object);
                } catch (error) {
                    console.log("error", error);
                    return true;
                }

                return false;
            
            }

        }
    ]
};