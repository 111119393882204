/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*

<!doctype html>
<head>
    <meta charset="utf8">
    <style type="text/css">
    body {
        padding:0px;
        margin:0px;
        background: #171b1f;
    }
    doc {
        display:none;
    }
   </style>
</head>
<!-- <doc type="javascript">
function test() {
    return false;
}
</doc> -->
<doc type="json">
[
{
    "this": false;
    "this": false
}
]

</doc>
<script src="editor.bundle.js"></script>
*/

var f = require("../scripts/f.js");
var config = require("../config.js");
// var uac = require("../controls/templates/ua.control.js");
// var uae = require("../elements/templates/ua.element.js");
var jsonRender = require("../interface/jsonRender.js");
// const { lastIndexOf } = require("../registry.js");

// var namespace = "ua.code";
// module.exports.namespace = namespace;

// var except = ["inner", "type", "id"];

var define = {
  namespace: "ua.code",
  title: "Code Editor",
  description: "A code editor for javascript, json, html, and css.",
  supportServer: false,
  control: true,
  fields: {
    "name": {
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "type": {
      type: "list", 
      options: ["javascript", "json", "html", "css"],
      description: "The type of code to render.",
      required: true
    },
    "inner": {
      type: "string",
      description: "The inner code to render."
    }
  },
  passthrough: {
    except: ["inner", "type", "id"]
  }
}; module.exports.define = define;


/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {

// console.log("New version of ua.code render");

//  var options = uac.GetOptions(ele, required);

 //already appended
//  if (!(options.uarendered === true)) {

  var iframe = document.createElement("iframe");

  var html = `<!doctype html>
  <head>
      <meta charset="utf8">
      <style type="text/css">
      body {
          padding:0px;
          margin:0px;
          background: #171b1f;
      }
     </style>
  </head>
  <body>
  <doc type="${options.type}">${options.inner}</doc>
  <script src="${f.FullUrl()}${config.location}code.editor.bundle.js"></script>
  </body>
  </html>`;
  // console.log("Encoding to frame", html);

  var blob = new Blob([html], {type: 'text/html'});
  iframe.src = URL.createObjectURL(blob);
  iframe.classList.add("card");
  iframe.classList.add("iframedoc");

  // add: allow-popups - if you need thems
  iframe.setAttribute("sandbox", "allow-same-origin allow-popups allow-scripts allow-forms");
  // iframe = uac.PassOptions(options, iframe, except);
  // jsonRender.PassOptions

  // iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
  // This does not work if the iframe isn't a member of the
  // body doucment.
  //
  // iframe.contentWindow.document.open();
  // iframe.contentWindow.document.write(html);
  // iframe.contentWindow.document.close();

  //this is old
  // iframe.addEventListener("prepare", Prepare);

  //this is new
  // iframe.setAttribute("data-ua-control", "ua.code");
  
  return iframe;

//  }

}

/**
 * Saves the code from the iframe.
 * Called by UATools.
 * @param {*} name The name of the control.
 * @param {*} control The control to save.
 * @param {*} repo The repo (used to send back), with the prevalidation results.
 * @property {*} repo.success The success object (is this field ready to be saved).
 * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
 * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
 * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
 * @property {*} repo.errors.input.id The id of the input field, if applicable.
 * @property {*} repo.errors.input.name The name of the input field if applicable.
 * @property {*} repo.errors.type The type of error that occured.
 *  - Supports: "validation" - The input or field or control is invalid
 *  - Supports: "thowable" - Processing this field caused a throwable to error out.
 * @property {*} repo.errors.message The message to display to the user.
 * @returns The repo object with the data to save
 */
async function save(name, control, repo) {

  // console.log("Starting save", {
  //   control, repo
  // });

  var iframe = control.querySelector("iframe");

  

  var data = iframe.contentWindow.postMessage("save", '*');

  // var data = iframe.contentWindow.postMessage("save", '*');


  console.info(`${name}: Code Returned`, data);

  // var response = false;

  if (data.success == false) { 
    console.error("The Code could not be saved.", {
      name: control.getAttribute("name"),
      data: data
    }); 

    repo.success = false;
    repo.errors.push({
      type: "validation",
      message: "The code could not be saved."
    });

  } else {
    // repo.success = true;
    repo.data = data.source;
  }

  return repo;

} module.exports.save = save;
