/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.platform.video";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");
var element = require("../interface/element.js");
// var uae = require("./templates/ua.element.js");

var required = ["platform", "id"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Video Element",
  description: "",
  fields: {
    "platform": {
      type: "list",
      items: ["vimeo", "youtube"],
      description: "The platform provider of the video."
    }, 
    "id": {
      type: "string",
      description: "The id of the video on the platform."
    }
  },
  passthrough: false,
  required: ["platform", "id"]
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {


  var embed = {};

  if (options.platform == "youtube") {
    embed = {
      n: "iframe",
        src: "https://www.youtube.com/embed/" + options.id,
        width: "100%",
        height: "640px",
        frameborder: "0",
        allowfullscreen: "allowfullscreen"
    };
  } else if (options.platform == "vimeo") {
    embed = {
      n: "iframe",
        src: "https://player.vimeo.com/video/" + options.id,
        width: "100%",
        height: "640px",
        frameborder: "0",
        allowfullscreen: "allowfullscreen"
    };
  } else {
    return await element.CreateError(namespace, new Error(`The video platform ${options.platform} is not yet supported.`), {
      options: options
    });
  }



  return await jsonRender.convertJSONToDOM(embed);

}

/** 
 * 
 * 
 * 
*/
