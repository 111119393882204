var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

var define = {
  namespace: "ua.select.accordion",
  title: "Accordion Control",
  description: "A collapsible accordion control for selecting options.",
  control: true,
  fields: {
    "inner": {
      type: "array",
      description: "The items of the accordion, each with a title, description, and optional icon.",
      required: true,
      item: {
        type: "object",
        properties: {
          "title": { type: "string", required: true },
          "inner": { type: "object", required: true },
          "icon": { type: "string", required: false },
          "value": { type: "string", required: true },
          "class": {
            type: "class",
            description: "The class to apply to the accordion item.",
          }
        }
      }
    }, "name": {
      type: "string",
      description: "The name of the accordion control.",
      required: true
    },
    "showall": {
      type: "boolean",
      description: "If true, all accordion items are expanded by default.",
      default: false,
      addtouae: true
    },
    "showcollapseall": {
      type: "boolean",
      description: "If true, shows the collapse all button.",
      default: false, 
      addtouae: true
    },
    "required": {
      type: "boolean",
      description: "If true, the control is required.",
      default: false
    },
    "title": {
      type: "string",
      description: "The title of the accordion control. Currently hidden, this title will be used when an error is thrown - such as no selection has been made.",
      default: ""
    }
  }
}; module.exports.define = define;

async function render(options) {

  var id = uai.generateRandomID(12);

  var elements = options.inner.map((item, index) => {
    var radioId = `radio-${uai.generateRandomID(12)}`;
    var randomId = `collapse-${uai.generateRandomID(12)}`;
    var headerId = `heading-${uai.generateRandomID(12)}`;

    var accordionBody = 
      {
        "n": "div",
        "id": randomId,
        "c": "accordion-collapse collapse",
        "aria-labelledby": headerId,
        "data-bs-parent": `#${id}`,
        "i": {
          "n": "div",
          "c": "accordion-body",
          "i": item.inner
        }
      };

      if ("showall" in options) {
        if (options.showall) {
          accordionBody.c += " show";

        }
      }

      if ("class" in item) {
        accordionBody.i.c += ` ${item.class}`;
      }

    var accordionItem = {
      "n": "div",
      "c": "accordion-item",
      "i": [
        {
          "n": "h2",
          "c": "accordion-header",
          "id": headerId,
          "i": {
            "n": "button",
            "c": "accordion-button collapsed",
            "type": "button",
            "data-bs-toggle": "collapse",
            "data-bs-target": `#${randomId}`,
            "aria-expanded": "false",
            "aria-controls": randomId,
            "data-accordion-id": id,
            "i": [
              {
                "n": "input",
                "c": "form-check-input",
                  "type": "radio",
                  "name": options.name,
                  "value": item.value,
                  "id": radioId,
                  "data-collapse-target": randomId,
                  "data-accordion-id": id
              },
              {
                "n": "div",
                "c": "p-3 fs-4",
                "i": {
                  "n": "label",
                  "c": "form-check-label",
                  "for": radioId,
                  "radioid": radioId,
                  "i": item.title,
                  "data-accordion-id": id
                }
              }
            ]
          }
        },
        accordionBody
      ]
    };

    if ("required" in options) {
      if (options.required) {
        accordionItem.i[0].i.i[0].required = true;
      }
    }

    
    if ("showall" in options) {
      if (options.showall) {
        accordionItem.i[0].i.c = "accordion-button";
        accordionItem.i[0].i["aria-expanded"] = "true";
      }
    }

    return accordionItem;
  });


  //build the default item
  var defItem = {
    "n": "div",
    "note": "This is the default item that is shown when no selection is made. It is used for validation purposes.",
    "c": "d-none",
    "i": [
      {
        "n": "h2",
        "c": "accordion-header",
        "id": `heading-${uai.generateRandomID(12)}`,
        "i": {
          "n": "button",
          "c": "accordion-button",
          "type": "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": `#collapse-${uai.generateRandomID(12)}`,
          "aria-expanded": "true",
          "aria-controls": `collapse-${uai.generateRandomID(12)}`,
          "i": [
            {
              "n": "input",
              "c": "form-check-input",
              "type": "radio",
              "name": options.name,
              "value": "",
              "id": `radio-${uai.generateRandomID(12)}`,
              "data-collapse-target": `collapse-${uai.generateRandomID(12)}`,
              "data-accordion-id": id
            },
            {
              "n": "div",
              "c": "p-3 fs-4",
              "i": {
                "n": "label",
                "c": "form-check-label",
                "for": `radio-${uai.generateRandomID(12)}`,
                "radioid": `radio-${uai.generateRandomID(12)}`,
                "i": `${options.title}`,
                "data-accordion-id": id
              }
            }
          ]
        }
      },
      {
        "n": "div",
        "id": `collapse-${uai.generateRandomID(12)}`,
        "c": "accordion-collapse collapse show",
        "aria-labelledby": `heading-${uai.generateRandomID(12)}`,
        "data-bs-parent": `#${id}`,
        "i": {
          "n": "div",
          "c": "accordion-body",
          "i": "No selection."
        }
      }
    ]
  };

  // elements.unshift(defItem);

  var accordion = {
    "n": "div",
    "c": "accordion",
    "id": id,
    "name": options.name,
    "i": elements
  };

  // console.log("ua.select.accordion: options", options);

  if ("showcollapseall" in options) {
    // console.log("showcollapseall", options.showcollapseall);
    if (options.showcollapseall) {
      var collapseAll = {
        "n": "button",
        "c": "btn btn-primary",
        "i": [{
          "n": "i",
          "c": "bi bi-list"
        }],
        "data-bs-toggle": "collapse",
        "data-bs-target": `#${id} .accordion-collapse`,
        "aria-expanded": "false",
        "aria-controls": id,
        "data-accordion-id": id
      };

      // accordion.i.push(collapseAll);
      //prepend the collapse all button
      accordion.i.unshift(collapseAll);

    }
  }

  var element = {
    "n": "div",
    "i": [defItem, accordion]
  };

  return element;
}; module.exports.render = render;


//set up a click event to collapse other accordion-items, and select the radio button when the accordion button is clicked
document.addEventListener('click', function (event) {

  try {
    

    // if (event.target.matches('.accordion-button')) {

    //   // console.log("accordion button clicked", event.target);

    //   var radio = event.target.querySelector('input[type="radio"]');
    //   var uae = event.target.closest('.uae');
    //   if (radio) {
    //     radio.checked = true;

    //     //check to see if the accordian clicked is already open, if uae.showall is set to trye
    //     if (uae.getAttribute('showall') === "true") {
    //       var collapse = bootstrap.Collapse.getInstance(event.target.nextElementSibling);
    //       if (collapse) {
    //         if (collapse._isShown) {
    //           collapse.hide();
    //         } else {
    //           collapse.show();
    //         }
    //       }

    //       //remove the attribute
    //       uae.removeAttribute('showall');

    //     }

    //   }

    //   // var accordionId = event.target.getAttribute('data-accordion-id');
    //   // var accordion = document.getElementById(accordionId);
    //   // var items = accordion.querySelectorAll('.accordion-item');
    //   // items.forEach(item => {

    //   //   try {
    //   //     var collapse = bootstrap.Collapse.getInstance(item.querySelector('.accordion-collapse'));

    //   //     if (collapse) {
    //   //       if (item !== event.target.closest('.accordion-item')) {
    //   //         // item.querySelector('.accordion-button').classList.add('collapsed');
    //   //         //use bootstrap collapse to hide the accordion body
            
    //   //         collapse.hide();
    
    //   //       } else {
    //   //         // item.querySelector('.accordion-button').classList.remove('collapsed');
    //   //       }
    //   //     }
    //   //   } catch (error) {
          
    //   //   }
      

    //   // });
    // }

    //if it's one of the labels or radio buttons as well
    if (event.target.matches('[data-accordion-id]')) {
 
      console.log("label or radio clicked", event.target);

      var radio = event.target.querySelector('input[type="radio"]');
      var uae = event.target.closest('uae');
      if (radio) {
        radio.checked = true;
      } 

 
        //check to see if the accordian clicked is already open, if uae.showall is set to trye
        if (uae.getAttribute('showall') == "true") {
          //remember all accordions are open, so we need to close them all, except the one clicked, and than remove the showall attribute
          var accordionId = event.target.getAttribute('data-accordion-id');
          var accordion = document.getElementById(accordionId);
          var items = accordion.querySelectorAll('.accordion-item');
          items.forEach(item => {
            
            //don't use bootstrap collapse to hide the accordion body- do it manually by mofifying the classes and the aria-expanded attribute
            if (item !== event.target.closest('.accordion-item')) {
              item.querySelector('.accordion-button').classList.add('collapsed');
              item.querySelector('.accordion-button').setAttribute('aria-expanded', 'false');
              item.querySelector('.accordion-collapse').classList.remove('show');
              item.querySelector('.accordion-collapse').classList.add('collapse');
            } else {
              item.querySelector('.accordion-button').classList.remove('collapsed');
              item.querySelector('.accordion-button').setAttribute('aria-expanded', 'true');
              item.querySelector('.accordion-collapse').classList.add('show');
              item.querySelector('.accordion-collapse').classList.remove('collapse');
            }

          });
          
          uae.removeAttribute('showall');

        }

        //remove the attribute
        uae.removeAttribute('showall');

    //   var accordionId = event.target.getAttribute('data-accordion-id');
    //   var accordion = document.getElementById(accordionId);
    //   var items = accordion.querySelectorAll('.accordion-item');
    //   items.forEach(item => {
    //     try {
    //       var collapse = bootstrap.Collapse.getInstance(item.querySelector('.accordion-collapse'));

    //       if (collapse) {
    //         if (item !== event.target.closest('.accordion-item')) {
    //           // item.querySelector('.accordion-button').classList.add('collapsed');
    //           //use bootstrap collapse to hide the accordion body
    //           collapse.hide();
    //         } else {
    //           // item.querySelector('.accordion-button').classList.remove('collapsed');
    //         }
    //       }
    //     } catch (error) {
          
    //     }
    //   });
    }

  } catch (error) {
      
  }


});

/**
 * Saves the state of the accordion control.
 * @param {string} name The name of the control.
 * @param {HTMLElement} control The accordion control element.
 * @param {Object} repo The repository object to store the save results.
 * @returns {Object} The updated repository object with the save results.
 */
async function save(name, control, repo) {
  var selectedInput = control.querySelector('input[type="radio"]:checked');

  if (!selectedInput) {
      repo.success = false;
      repo.errors.push({
          type: "validation",
          message: "No option selected."
      });
  } else {
      repo.success = true;
      repo.data = {
          value: selectedInput.value
      };
  }

  return repo;
}

/**
* Loads the state into the accordion control.
* @param {string} name The name of the control.
* @param {HTMLElement} control The accordion control element.
* @param {Object} data The data to load into the control.
*/
async function load(name, control, data) {
  if (data && data.value) {
      var inputToSelect = control.querySelector(`input[type="radio"][value="${data.value}"]`);
      if (inputToSelect) {
          inputToSelect.checked = true;
      }
  }
}

module.exports.save = save;
module.exports.load = load;
