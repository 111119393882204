var delegate = require('delegate');
var ui = require("./interface.js");
var f = require("../scripts/f.js");
var log = require("../scripts/log.js");
// var pages = require("./pages.js");

var config = require("../config.js");
var langError = require("./lang_error.js");

exports.test = function () {
  log.info("Working");
};

exports.PerformUAAction = PerformUAAction;
exports.Init = Init;

function Init() {
  // log.info("Init Actions...");
  ActivateUaActions();
}

//exports.ActivateUaActions = ActivateUaActions;

//var actionscount = 0;
function ActivateUaActions() {
  delegate("*[ua-action]", "click", async function(e) {
    await _doAction(e.delegateTarget);
  });
  window.onpopstate = async function (event) {
    log.info("History state has changed...", event);
    if (event.state === null) {
      // I don't know what I was thinking, for now disable this function.
      // window.location = document.location;
    } else {
      event.state.fromhistory = true;
      await PerformUAAction(event.state);
    }
  }
}

//attempt to submit a form
exports.AttemptFormAction = AttemptFormAction;
async function AttemptFormAction(form) {
  var $submit = form.querySelector("submit");
  // log.info($submit);
  if ($submit === null) {
    log.info("No submit found in form.", form);
  } else {
    if ($submit.matches("*[ua-action]")) {
      await _doAction($submit);
    } else {
      form.submit();
    }
  }
}

var disableWhileLoading = false;
async function _doAction(ele) {
    var uaactionSelector = "*[ua-action]";
    if (!ele.matches(uaactionSelector)) {
      return;
    }

    // log.info("Universe Action:", ele);
    if (disableWhileLoading == false) {
    event.preventDefault();

    //determines if all the information is present
    var hasError = false;

    var uri = ele.getAttribute('uri');

    /**
     * The Realtime app has a "callernamespace" which is than patched to ua.namesapce of the registered app.
     */
    var namespace = ele.getAttribute("callernamespace");

    var uriMethod = ele.getAttribute('method');
    if (uriMethod === null) {
        log.info("Could not find a uri method in the requested action.");
        hasError = true;
    } else {
      uriMethod = uriMethod.trim().toLowerCase();
      
        if (uriMethod == "realtime") {
          if (namespace === null) {
            log.info("Could not find a namespace in the requested action.");
            hasError = true;
          }
        } else if (uri === null) {
          log.info("Could not find a uri in the requested action.");
          hasError = true;
        }
        
    }

    //let's verify the methods are real
    if (uriMethod == "post") {
    } else if (uriMethod == "get") { //standard HTTP Get Method
    } else if (uriMethod == "update") { //standard HTTP Update Method
    } else if (uriMethod == "insert") { //standard HTTP Insert Method
    } else if (uriMethod == "delete") { //standard HTTP Delete Method
    } else if (uriMethod == "realtime") { //sockets.io realtime method
    } else if (uriMethod == "bundled") {  //ua.pages supprt
    } else {
      if (hasError == false) {
        log.info("URI method (" + uriMethod + ") is not supported.");
        hasError = true;
      } //why would I report the error twice
    }

    uaDisableLoad = ele.getAttribute('ua-disableWhileLoading');

   if (uaDisableLoad === null) {
   } else {
      _DisableUAAction();
   }

    var data = _getData(ele);
    var uaaction = {
      "method": uriMethod,
      "disableWhileLoading": uaDisableLoad,
      "data": data,
      "uaversion": config.version
    };

    if (!(uri === null)) {
      uaaction.uri = uri;
    }

    if (!(namespace === null)) {
      uaaction.namespace = namespace;
    }

    if (hasError) {
      log.info("The Action Failed - missing an attribute.", uaaction);
      if (uaaction.disableWhileLoading == true) {
        _EnableUAAction();
      }
    } else {
      await PerformUAAction(uaaction);
    }

  } else {
    log.info("Actions are currently disabled.");
  }
}

// window.PerformUAAction = PerformUAAction;
async function PerformUAAction(uaaction) {
  var error = [];
  log.info("Starting UA Action", uaaction);

  if (typeof uaaction == typeof undefined) {
    error.push("UA Action is undefined.");
  } else if (typeof uaaction.method == typeof undefined) {
    error.push("The Action has no HTTP/API method.");
  }

  //am I a realtime request?
  if (uaaction.method == "realtime") {
    if (typeof uaaction.namespace == typeof undefined) {
      error.push("The action has no namespace.");
    }
  } else {
    if (typeof uaaction.uri == typeof undefined) {
      error.push("The action has no URI.");
    }
  }

  //am I a bundled request?
  if (uaaction.method == "bundled") {
    if (typeof uaaction.namespace == typeof undefined) {
      error.push("The action has no namespace, can't get you a page.");
    }
  }

  if (typeof uaaction.disableWhileLoading !== typeof undefined) {
    if (disableWhileLoading) {
      _DisableUAAction();
    }
  }

  if (error.length > 0) {
    log.info("UA Action failed:", error)
  } else {

    //check if I'm a realtime app or not

    if (uaaction.method == "bundled") {
      //uses prebundeld pages in ua-tools
      try {
        await ui.PerformResponse(pages.Render(uaaction));
      } catch (error) {
        error.push("The bundled request failed.");
        error.push(error.message);
        log.info("UA Action failed:", error);
      }


    } else if (uaaction.method == "realtime") {
      if (window.realtime === undefined) {
        error.push("The app hasn't registered support for realtime requests.");
        log.info("UA Action failed:", error);
      } else {
        try {
          window.realtime.emit("request", {
            namespace: uaaction.namespace,
            data: uaaction
          });
        } catch (error) {
          error.push("The realtime app request failed.");
          error.push(error.message);
          log.info("UA Action failed:", error);
        }
      }
    } else {


      /***
       * Please FOR THE LOVE OF GOD
       * REWRITE THIS PROMISE CHAIN
       * to async await
       * @jumpcutking
       */

      //I'm http standard request
      fetch(uaaction.uri, {
        method: uaaction.method,
        headers: {
          "Accept": 'text/html',
          "Content-type": "application/json",
          "Request-type": "universe-action"
        },
        body: JSON.stringify(uaaction)
      }).then(CheckStatus)
        .then(GotResponse)
        .then(await ui.PerformResponse)
        .catch(GotProblem);
    }  

  }

}

function GotProblem(err) {
  log.info("Request Failed: ", err);
  error = langError.requestfailed;
  ui.ShowErrorMessage(error.title, error.body, err.name + ": " + err.message , true);
}

function CheckStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  } else {
    if (response.satus = 500) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }
}

function GotResponse(response) {
  log.info("Response: ", response);

  var data = response.text();
  //log.info("Response Body: ", data);

  if (disableWhileLoading) {
    _EnableUAAction();
  }

  return data;

}

function _DisableUAAction() {
  disableWhileLoading = true;
  log.info("Disabling UA Actions.")
}

function _EnableUAAction() {
  disableWhileLoading = false;
  log.info("Enabling UA Actions.")
}

//get the JSON object with the data
function _getData(ele) {

    var data = {};

    var jsonobject = ele.getAttribute("json");
    if (jsonobject === null) {
    } else {
      //log.info("Got JSON Object: " + jsonobject);
      //log.info($("#" + jsonobject).text());
      f.extend(data, data, JSON.parse(document.querySelector("#" + jsonobject).textContent));
    }

    var formid = ele.getAttribute("form");
    if (formid) {
      //log.info("Got Form Object: " + formobject)
      var form = document.querySelector("#" + formid);
      var hasControlError = false;

      //validate the form first - this should set input field tests
      if (form.matches(".needs-validation")) {
        // log.info("Form requires validation.");
        if (form.checkValidity() === false) {
          log.info("Validation failed.", formid);
          form.classList.add("was-validated");
          log.error("Form Validation Failed!");
        } else {
          //form.classList.add('was-validated');
          form.classList.add("was-validated");

        }
      }

      //prepare the controls
      form.querySelectorAll("control").forEach(function (control) {
        if (!(ui.ValidateControl(control))) {
          hasControlError = true;
        }
      });

      //double check the validation and update it
      ui.UpdateControlsAfterValidation(form);

      if (hasControlError) {
        log.error("Control Validation Failed!");
      }

        f.extend(data, data, _getFormData(formid));

    }

    return data;

}

//get the form data as an Object
var _gFormData;
function _getFormData(formid) {

  //creates an object, that I treat as a 2-deminsional array.
  _gFormData = {};
  _gFormData["form"] = formid;

  var $inputs = document.querySelectorAll("#" + formid + " input");
  $inputs.forEach(function (input) {
      if (!(input.closest("control") === null)) {} else {
      //find all input fields
      if (input.matches("input[type='checkbox']")) {
        //is it a checkbox?
        if (input.matches(":checked")) {
          _gFormData[input.getAttribute("name")] = true;
        } else {
          _gFormData[input.getAttribute("name")] = false;
        }

      } else if (input.matches("input[type='radio']")) {
        //is it a radio
        if (input.matches(":checked")) {
          _gFormData[input.getAttribute("name")] = true;
        } else {
          _gFormData[input.getAttribute("name")] = false;
        }

      } else {
        _gFormData[input.getAttribute("name")] = input.value;
      }
    }

  });

  //get all selectors
  var $selectors = document.querySelectorAll("#" + formid + " select");
  $selectors.forEach(function (input) {
    if (!(input.closest("control") === null)) {} else {
      _gFormData[input.getAttribute("name")] = input.value;
    }
  });

  //get all selectors
  var $textareas = document.querySelectorAll("#" + formid + " textarea");
  $textareas.forEach(function (input) {
    if (!(input.closest("control") === null)) {} else {
      _gFormData[input.getAttribute("name")] = input.value;
    }
  });

  //get all hidden fields ?
  var $hiddens = document.querySelectorAll("#" + formid + " hidden");
  $hiddens.forEach(function (input) {
    if (!(input.closest("control") === null)) {} else {
      _gFormData[input.getAttribute("name")] = input.value;
    }
  });

  //Now let's render all the custom controls
  var $controls = document.querySelectorAll("#" + formid + " control");
  $controls.forEach(function (control) {

    $json = control.querySelector("json");
    if ($json === null) {
      log.info("Control is missing JSON.", control);
      log.error("Control preparation event failed to produce a JSON object.");
    } else {
      json = JSON.parse($json.innerText);
      _gFormData[json.name] = json.data;
    }
  })

  return _gFormData;

}
