/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * @jumpcutking/console
 *  
 * Justin K Kazmierczak
 * 
 * Generates a safe and passable error message
 */

var namespace = "generateSafeError";
var parseStackTrace = require("./parseStackTrace.js").function;


/**
 * Generates a safe and passable error message
 * @param {*} err The error to generate a safe error message for.
 */
function generateSafeError(err) {

    //if err is undefined, return undefined
    if (typeof err == "undefined") {
        console.error("Threads is unable to generate a safe error. Error is undefined.");
    }

    if (typeof err == "string") {
        return err;
    }

    var stack = parseStackTrace(err.stack, 0);
    var safeError = JSON.parse(JSON.stringify(err, Object.getOwnPropertyNames(err)));

    //for each property if it is a null or undefined use the { '$': 'nonejson', type: 'undefined' } or { '$': 'nonejson', type: 'null' } object.
    for (var prop in safeError) {
        if ((safeError[prop] == null) || (typeof safeError[prop] == "null")){
            safeError[prop] = { '$': 'nonejson', type: null };
        }

        if (typeof safeError[prop] == "undefined") {
            safeError[prop] = { '$': 'nonejson', type: "undefined" };
        }
    }

    safeError.stack = stack;
    safeError.stacktrace = err.stack;

    if ("title" in err) {
        safeError.title = err.title;
    }

    if ("description" in err) {
        safeError.description = err.description;
    }

    return safeError;

}

module.exports = {
    namespace: namespace,
    function: generateSafeError,
    tests: [{
        namespace: `${namespace}.test`,
        invalidTest: true,
        must: true,
        run: async () => {
    
            var myStack = generateSafeError(new Error("Test error"));
            console.log("My stack", myStack);
            return true;

           }
    }] 
};