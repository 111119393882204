/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "ua.notification.icon";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var required = ["inner"];
exports.required = required; 

exports.define = {
  namespace: namespace,
  fields: {
    "description": {
      type: "string",
      description: "A description of the notification."
    }, 
    "inner": {
      type: "string",
      description: "The inner html of the notification."
    }, 
    "position": {
      type: "boolean",
      description: "Should it be a position of absolute or nothing by default?",
      default: true
    }
  },
  required: required
} 



/**
 * Adds notification badge to the top corner of an element
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (json) {

  var options = uae.GetOptions(json, required);

  var span = {
    n: "span",
    c: "top-0 badge rounded-pill bg-info",
    i: options.inner
  }

  if ("position" in options) {
    if (options.position) {
      span.c = span.c + " position-absolute";
    }
  } else {
    span.c = span.c + " position-absolute";
  }

  //support for visual description and accessibility
  if ("description" in options) {
    var inner = [
      options.inner, {
        n: "span",
        c: "visually-hidden",
        i: options.description
      }
    ];

    span.i = inner;

  }
  
  return await jsonRender.render(span, {
    PassthroughOptions: options
  });
  
};

/* <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    99+
    <span class="visually-hidden">unread messages</span>
  </span> */