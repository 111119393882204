/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "conformCurrencyEcommerce";

/**
 * Converts a number where cents are denoted before the decimal place (like in stripe)
 * Example: for $1.00 the input would be 100 to a currency string with two decimal places.
 * @param {*} value The number or string to convert to a currency string.
 * @returns {String} The currency string.
 */
function conformCurrencyEcommerce(value) {
    //if it's a number it will force it to a string

    var value = parseFloat(value);
      //add commas to the price
      value = value.toFixed(2);
    //   return `$ ${price}`;

    if (value.includes(".")) {
        var split = value.split(".");
        if (split[1].length == 1) {
            value = value + "0";
        }
    } else {
        //add a decimal place at two characters from the end
        value = value.substring(0, value.length - 2) + "." + value.substring(value.length - 2);
    }

    //now add commas
    var split = value.split(".");
    var dollars = split[0];
    var cents = split[1];
    var dollarsArray = dollars.split("");
    var newDollars = [];
    var count = 0;
    for (var i = dollarsArray.length - 1; i >= 0; i--) {
        count++;
        newDollars.unshift(dollarsArray[i]);
        if (count == 3 && i != 0) {
            newDollars.unshift(",");
            count = 0;
        }
    }
    value = newDollars.join("") + "." + cents;

    return `$ ${value}`;
} 

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: conformCurrencyEcommerce,
    tests: [{
        namespace: `${namespace}.default`,
        must: "$ 1,002.00",
        run: async () => {

            //test conformCurrency

            var value = "100200";
            var result = conformCurrencyEcommerce(value);
            return result;

        }
    }]
};