/*!
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * (C) 2024 Justin K Kazmierczak, The Universe, The EGT Universe, LLC. All Rights Reserved.
 * 
 * This notice may not be removed.
 * 
 * Subject to The Universe Terms of Service and Privacy Policy.
 * https://egtuniverse.com/legal
 * 
 * This is licensed to members in good standing of The Universe App Artisan Program.
 * 
 * Source code access and modifcation is subject to the terms of the license
 * and may not be shared or distributed without the express written consent of
 * The Universe. 
 * 
 * All instances of this software must be registered with The Universe.
 * Domains (and subdomains or wildcard domain) must be registered with The Universe.
 * 
 * Use source code responsibly.
 * 
 */

var config = require("./config.js");
function ShowLicenseInformation() {
  const styles =
        `padding: 8px; background-color: #181a1f; color: #c48e2f; font-style:
         bold; border: 2px solid c48e2f; font-size: 1.3em;`
  const errors =
        `padding: 10px; background-color: #181a1f; color: #d33682; font-style:
         bolder; border: 5px solid crimson; font-size: 5em;`
  console.log(`%cThis website uses Universe App Tools designed by App Artisans. It is licensed to members of The Universe App Artisan Program in good standing. To obtain a license, access source code, or more information on the terms and conditions, visit https://egtuniverse.com
  Data collected may be subject to The Universe Terms of Service and Privacy Policy. Access the policy at https://egtuniverse.com/legal`, styles);
}


ShowLicenseInformation();
exports.default = true;
