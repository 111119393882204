/*!
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "convertFromAccountingDate";
// var errorz = require("../errors.js").create(namespace);
// errorz.addErrors({
//     "invalidAccountingDate": "The date is not a valid accounting date."
// });

var convertToAccountingDate = require("./convertToAccountingDate.js").function;

/**
 * Converts a 8-12 digit date to a Date()
 * @param {date|number|string} date The 12 digit date.
 * @returns A Date object.
 */
function convertFromAccountingDate(date) {

    //am I a date?
    if (date instanceof Date) {
        return date;
    }

    //if I'm a number make a string
    if (typeof date == "number") {
        date = date.toString();
    }

    if (!(typeof date == "string")) {
        throw new Error("The date is not a valid accounting date. Expecting a string or number. Date objects will automatically be returned.");
    }
 
    //is it a number?
    // if (typeof date == "number") {
        // var date = options.date;
        var year = date.substring(0, 4);
        var month = date.substring(4, 6);
        var day = date.substring(6, 8);
        var hour = "00";
        var minute = "00";
        var second = "00";

        if (date.length > 8) {
            hour = date.substring(8, 10);
            minute = date.substring(10, 12);
            
            if (date.length > 12) {
                second = date.substring(12, 14);
            }

        }

        var dtaConversion = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;

        // console.log(`Conversion Attempted of Accounting Date: ${date} to ${dtaConversion}`);
        
        return new Date(`${dtaConversion}`);
        // console.info(`Result: ${date.toLocaleString('default', { month: 'long' }) + " " + day + ", " + year}`);


    
  
}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: convertFromAccountingDate,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
            
            return false
            
        }
    }]
};