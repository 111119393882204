var f = require("../scripts/f.js");
var uae = require("./templates/ua.element.js");
var log = require('../scripts/log.js');
var required = ["id", "inner"];

var example = {
  "id": "mytab",
  "namespace": "ua.e.nav.tabs",
  "inner": [{
    "order": 0,
    "title": "Tab 1",
    "icon": "bi-message",
    "alt": "The my thing button.",
    "inner": [{
      "$": "p",
      "inner": "Example tab"
    }]
  },{
    "order": 0,
    "title": "tab 2",
    "icon": "bi-inbox",
    "alt": "The my thing button.",
    "inner": [{
      "$": "p",
      "inner": "Example tab 2"
    }]
  }]
};

/**
 * The ID to be appended to the id of the holder.
 */
var contentHolderId = "Content";

var navHolderId = "Navbar"

exports.namespace = "ua.e.nav.tabs";
exports.render = async function (ele) {

  var options = uae.GetOptions(ele, required);

  var parent = document.createElement("div");
  var ul = document.createElement("ul");
  ul.classList.add("nav");
  ul.classList.add("nav-tabs");

  ul.setAttribute("id", options.id + navHolderId);
  ul.setAttribute("role", "tablist");

  var holder = document.createElement("div");
  holder.classList.add("tab-content");
  holder.setAttribute("id", options.id + contentHolderId);

  if ("container" in options) {

    if ("class" in options.container) {
      holder.setAttribute("class", holder.getAttribute("class") + " " + options.container.class);
    }

    if ("style" in options.container) {
      holder.setAttribute("style", options.container.style);
    }

  }

  var fade = false;

  if ("fade" in options) {
    fade = options.fade;
  }

  options.inner.forEach(nav => {

    var myid = f.UniqueID();
    var navLinkID = "navlink" + myid;
    var navContentId = "navcont" + myid;
    var active = false;

    if ("active" in nav) {
      active = nav.active;
    }

    var li = document.createElement("li");
    li.classList.add("nav-item");
    li.setAttribute("role", "presentation");

    var a = document.createElement("a");
    li.appendChild(a);

    a.setAttribute("id", navLinkID);
    a.setAttribute("data-bs-toggle", "tab");
    a.setAttribute("href", "#" + navContentId);
    a.setAttribute("role", "tab");
    a.setAttribute("aria-controls", navContentId);
    a.classList.add("nav-link");

    if (active) {
      a.classList.add("active");
      a.setAttribute("aria-selected", true);
    } else {
      a.setAttribute("aria-selected", false);
    }

    if ("alt" in nav) {
      a.setAttribute("title", nav.alt);
    } 

    if ("icon" in nav) {
      a.innerHTML = `<i class="${nav.icon}"></i> ${nav.title}`;
    } else {
      a.innerHTML = nav.title;
    }
    

    var content = document.createElement("div");
    content.classList.add("tab-pane");
    content.setAttribute("id", navContentId);
    content.setAttribute("role", "tabpanel");
    content.setAttribute("aria-labelledby", navLinkID);

    //I'm starting with this panel
    if (active) {
      content.classList.add("active");
      content.classList.add("show");
      //content.setAttribute("aria-selected", true);
    } else {
      //content.setAttribute("aria-selected", false);
    }

    if (fade) {
      content.classList.add("fade");
    }

    if ("header" in nav) {
      nav.header.namespace = "ua"
      var header = uae.RenderObjectByNamespace("ua.e.header", nav.header);
      header[0].classList.add("tabHeader"); //make the div a tabheader and append it
      content.appendChild(header);
    }

    if ("inner" in nav) {
      throw "Not implemented yet.";
      // content.appendChild(await uae.RenderInner(nav.inner));        
    }


    ul.appendChild(li);
    holder.appendChild(content);

  });

  parent.appendChild(ul);
  parent.appendChild(holder);
  return parent;

};

/**
 * Report if the alert is rendered. (uae)
 * @param  {*} element [description]
 * @return {*}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

/**
 * Creates the object that will be used to render the e-mail
 * @param  {*} $my   [The e-mail config]
 * @param  {*} brand [The brand colors and style]
 * @param  {*} element   [The element]
 * @return {*}       [description]
 */
exports.renderEmail = function($my, brand, element) {

  var options = uae.ValidateOptions(element, required);
  if (options.success == false) {
    return options;
  } else {
    options = options.options;
  }

  options = uae.FillPlaceHoldersEmails(options, $my, brand);

  log.info("Rendered Options", options);

  var tablerow = document.createElement("tr");
  tablerow.setAttribute("border", 0);
  tablerow.setAttribute("cellpadding", 0);
  tablerow.setAttribute("cellpadding", 0);

  var tablecell = document.createElement("td");
  tablecell.setAttribute("style", `${$my.style.defaultcell} ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};`);

  var img = document.createElement("img");
  img.border = "none";

  if ("height" in options) {
    img.height = options.height;
  }

  img.setAttribute("width", $my.style.maxwidth);

  img.setAttribute("style", `${$my.style.padding} margin:0px; ${$my.style.fonts} ${$my.style.fontsize} color: ${brand.textcolor};
    width: ${$my.style.maxwidth}; border: none 0px;`);

  img.src = options.image;

  tablecell.appendChild(img);
  tablerow.appendChild(tablecell);

  return {
    success: true,
    orginal: uae,
    row: tablerow
  };

  // return {
  //   success: false,
  //   fault: "pending",
  //   error: "This part of the function is stll being written",
  //   original: uae
  // }

}
