var delegate = require('delegate');
var ua = require("../interface/action.js");
var ui = require("../interface/interface.js");
/* exports.AddValidation = function() {
  'use strict';
  window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
};*/


delegate(".needs-validation", "submit", function (e) {
  // console.log(e);
  if (e.delegateTarget.checkValidity() === false) {
    e.preventDefault();
    e.stopPropagation();
  }
  e.delegateTarget.classList.add('was-validated');
  ui.UpdateControlsAfterInputValidation(e.delegateTarget);
});

delegate("input", "input",  function (e) {
  var form = e.delegateTarget.closest("form");
  if (!(form === null)) {
    if (form.matches(".needs-validation")) {
      var control = e.delegateTarget.closest("control");
      if (!(control === null)) {
        ui.UpdateControlAfterValidation(control);
      }
    }
  }
})

delegate("input", "keypress", async function (e) {
  if (event.which === 13 && !event.shiftKey) {
    var form = e.delegateTarget.closest("form");
    if (!(form === null)) {
      //check if the form has the right class, and validate
      if (form.matches(".needs-validation")) {
        if (form.checkValidity() === false) {
          //update controls
          ui.UpdateControlsAfterValidation(form);
          e.preventDefault;
          e.stopPropagation;
        } else {
          await ua.AttemptFormAction(form);
        }
        form.classList.add('was-validated');
      } else {
        form.submit();
      }

    }
  }
});
