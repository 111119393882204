var f = require("../../scripts/f.js");
var uac = require("./ua.control.js");
var uae = require("../../elements/templates/ua.element.js");
var namespace = "ua.c.fg";

/*!
 * A Ghost is a an ellement "shell" or template.
 * It should not be added as an element.
 *
 * It should never contain a namespace field, as it is
 * called by the control that needs it, when it is needed.
 */

exports.CreateShell = function(options, child) {
  //children accepted
  //content: can be any element, such as an input field
  //    that is wrapped into the form group.
  //options:
  //    icon | text | class to an icon in the "i" elements
  //    inputgroup | text | adds the input to a group with icon on the left or right
  //    hidelable | removes the lable on the title attribute

  finalchild = child; //this could have stuff appened to it.

  var formgroup = document.createElement("div");
  formgroup.classList.add("form-group");

  var useicon = false;

  //console.log("options.icongroup:", options.icongroup);

  if ("inputgroup" in options) {
    finalchild = AddInputGroup(options, child);
    useicon = true;
  }

  if (!("hidelabel" in options)) {
    //create my label
    var label = document.createElement("label");
    label.setAttribute("for", options.name);

    //console.log(options);

    if (useicon) {
      label.innerText = options.title;
    } else {
      if ("icon" in options) {
        label.innerHTML = `<i class="${options.icon}"></i> ${options.title}`;
      } else {
        label.innerText = options.title;
      }
    }

    formgroup.appendChild(label);
  }

  // var small = false;
  if ("help" in options) {
    //<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>

    var small = document.createElement("small");
    small.classList.add("form-text");
    small.classList.add("text-muted");
    small.innerHTML = options.help;
    formgroup.appendChild(small);

  }

  formgroup.appendChild(finalchild);

  // if (small) {
  //   formgroup.appendChild(small);
  // }

  return formgroup;
};

function AddInputGroup(options, child) {

  //default prepends
  append = false;

  if ("appendinputgroup" in options) {
    append = true;
  }

  var inputgroup = document.createElement("div");
  inputgroup.classList.add("input-group");

  var inputappend = document.createElement("div");
  text = options.inputgroup;

  if ("icon" in options) {
    if (options.inputgroup === true) {
      inputappend.innerHTML = `<div class="input-group-text text-color"><i class="${options.icon}"></i></div>`;
    } else {
      inputappend.innerHTML = `<div class="input-group-text text-color"><i class="${options.icon}"></i>${options.inputgroup}</div>`;
    }
  } else {
    inputappend.innerHTML = `<div class="input-group-text text-color">${options.inputgroup}</div>`;
  }

  if (append) {
    inputappend.classList.add("input-group-append");
    inputgroup.appendChild(child);
    inputgroup.appendChild(inputappend);
  } else {
    inputappend.classList.add("input-group-prepend");
    inputgroup.appendChild(inputappend);
    inputgroup.appendChild(child);
  }

  return inputgroup;

}

exports.PrepareOutput = PrepareOutput;

/**
 *  Prepares the object to be outputed, also validates control.
 * @param       {*}  control                       [The control to validate.]
 * @param       {Boolean} [data=false]                  [The data to overide, if false, will use any avalable input output.]
 * @param       {Boolean} [_ShowValidationMessage=true] [description]
 * @constructor
 */
function PrepareOutput(control, data = false, _ShowValidationMessage = true) {

  $jsondata = uac.PrepareOutput(control, data, false);

  if (_ShowValidationMessage) {
    if ($jsondata.validation.valid == false) {
      ShowValidationMessage(control, $jsondata);
    } else {
      uac.HideError(control);
    }
  }

  return $jsondata;
}

exports.PassAtributes = function(ele, input, _except = []) {
  var except = ["name", "id", "hidelabel", "icon", "textgroup", "icongroup", "uarendered", "class", "style"];
  except = except.concat(_except);

  return uae.PassthroughOptions(input, ele, except);
}

exports.ShowValidationMessage = ShowValidationMessage;
function ShowValidationMessage(control, json) {
  var label = control.querySelector("label");
  var error = json.validation.message;
  if (Array.isArray(error)) {
    error =  error.join(" ");
  }

  uac.ShowError(label, error);
}

/**
 * Passes the prefilled help properties to the control.
 */
exports.helpTemplate = {
  template: "formgroup",
  ...uac.helpTemplate
};

exports.help = {
  title: "Form Group Template",
  description: "A form group template for use with controls.",
  namespace: namespace,
  properties: {
    "help": {
      title: "Help Text",
      description: "The help text to display below the control.",
      default: false,
      type: "string",
    },
    "icon": {
      title: "Icon",
      description: "The icon to display on the control.",
      default: false,
      type: "string"
    },
    ...uac.help.properties
  },
  ...uac.help,
  example: {
    html: `<control namespace="${namespace}"></control>`,
    json: {
      namespace: `${namespace}`
    }
  }
}