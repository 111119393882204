/*!
 * Universe App Tools
 * Quick Template
 * 
 * Adds a Bio of a person to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var f = require("../scripts/f.js");
var log = require("../scripts/log.js");
var jsonRender = require("../interface/jsonRender.js");
var ui = require("../interface/interface.js");

var namespace = "ua.quick.bio";
exports.namespace = namespace;

exports.define = {
  namespace: namespace,
  fields: {
    "name": {
      type: "string",
      description: "The name of the person.",
      required: true
    },
    "inner": {
      type: "inner",
      description: "The description of the person."
    },
    "image": {
      type: "uri",
      description: "The image of the person."//,
      // required: true
    },
    "title": {
      type: "string",
      description: "The title of the person."
    }, 
    "flip": {
      type: "boolean",
      description: "Flip the image and text.",
      default: false
    }
  }
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @ua:uses ua.a.incenter
 * @param {*} ele The element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = function (options) {

  var title = "";
  if ("title" in options) {
    title = {
      "n": "small",
      "c": " text-muted d-block",
      "i": options.title
    }
  }

  var bio = false;

  if (!("image" in options)) {
    bio = {
      "n": "ua.hcard.simple",
      "class": " mb-1 mt-1 ",
      "i": [{
        "n": "div",
        "c": "row mb-3",
        "i": [
          {
            "n": "div",
            "c": "col-12 p-0 d",
            "i": [
              {
                "n": "div",
                "c": "text-white bg-75-info p-2 mb-3 rounded d-block tiny-outline box-shadow col-12",
                "i": [{
                  "n": "h1",
                  "c": "fs-3",
                  "i": [options.name, {
                    "n": "small",
                    "c": " text-muted d-block",
                    "i": title
                  }]
                }]
              }
            ]
          }
        ]
      }, {
        "n": "div",
        "c": "fs-5 bg-75-black p-3 rounded",
        "i": [
          options.inner
        ]
      }]
    };
  }

  if ((!(bio)) && ("flip" in options)) {
    if (options.flip) {
      bio = {
        "n": "ua.hcard.simple",
        "class": " mb-1 mt-1 ",
        "i": [{
          "n": "div",
          "c": "row mb-3",
          "i": [
            {
              "n": "div",
              "c": "col-lg-6 col-12 p-3 d-md-none",
              "i": {
                "n": "img",
                "class": "img-fluid rounded box-shadow rounded-circle tiny-outline",
                "src": options.image,
              }
            },
            {
              "n": "div",
              "c": "col-lg-6 col-12 p-0 d-flex align-content-end flex-wrap",
              "i": [
                {
                  "n": "div",
                  "c": "text-white bg-75-info p-2 mb-3 rounded d-block tiny-outline box-shadow col-12",
                  "i": [{
                    "n": "h1",
                    "c": "fs-3",
                    "i": [options.name, {
                      "n": "small",
                      "c": " text-muted d-block",
                      "i": title
                    }]
                  }]
                }
              ]
            }, {
              "n": "div",
              "c": "col-lg-6 col-12 p-3 d-none d-md-block",
              "i": {
                "n": "img",
                "class": "img-fluid rounded box-shadow rounded-circle tiny-outline",
                "src": options.image,
              }
            },
          ]
        }, {
          "n": "div",
          "c": "fs-5 bg-75-black p-3 rounded",
          "i": [
            options.inner
          ]
        }]
      }
    }
  }

  if (!bio) {
  bio = {
    "n": "ua.hcard.simple",
    "class": " mb-1 mt-1 ",
    "i": [{
      "n": "div",
      "c": "row mb-3",
      "i": [
        {
          "n": "div",
          "c": "col-lg-6 col-12 p-3",
          "i": {
            "n": "img",
            "class": "img-fluid rounded box-shadow rounded-circle tiny-outline ratio-1x1",
            "src": options.image,
          }
        },
        {
          "n": "div",
          "c": "col-lg-6 col-12 p-0 d-flex align-content-end flex-wrap",
          "i": [
            {
              "n": "div",
              "c": "text-white bg-75-info p-2 mb-3 rounded d-block tiny-outline box-shadow col-12",
              "i": [{
                "n": "h1",
                "c": "fs-3",
                "i": [options.name, {
                  "n": "small",
                  "c": " text-muted d-block",
                  "i": title
                }]
              }]
            }
          ]
        }
      ]
    }, {
      "n": "div",
      "c": "fs-5 bg-75-black p-3 rounded",
      "i": [
        options.inner
      ]
    }]
  };
}


  //id overide

  if ("id" in options) {
    bio.id = options.id;
  }

  return jsonRender.render(bio, {
    PassthroughOptions: options
  });

};


/* Renders Into This JSON

{
    "n": "ua.hcard.simple", 
    "class": " mb-1 mt-1 ",
    "i": [{
      "n": "div",
      "c": "row mb-3",
      "i": [
        {
          "n": "div",
          "c": "col-lg-6 col-12 p-3",
          "i": {
            "n": "img",
            "class": "img-fluid rounded box-shadow rounded-circle tiny-outline",
            "src": options.image,
          }
        },
        {
          "n": "div",
          "c": "col-lg-6 col-12 p-0 d-flex align-content-end flex-wrap",
          "i": [
            {
                "n": "div",
                "c": "text-white bg-75-info p-2 mb-3 rounded d-block tiny-outline box-shadow col-12",
                "i": [{
                  "n": "h1",
                  "c": "fs-3",
                  "i": [ options.name, {
                    "n": "small",
                    "c": " text-muted d-block",
                    "i": options.title
                }]
                }]
            }
          ]
        }
      ]
    }, {
        "n": "div",
        "c": "fs-5 bg-75-black p-3 rounded",
        "i": [
          options.inner
        ]
  }

 */