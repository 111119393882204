var _ = require("../../uam/module.js")(module.exports, {
    namespace: "luxe.error",
    title: "Luxe Error Message",
    description: "Shows a freindly error message while redirecing the user to the latest styles.",
    editable: true,
    fields: {
        error: {
            type: "object",
            title: "Error",
            description: "The error that occurred."
        },
        type: {
            type: "string",
            title: "Type",
            description: "The type of error that occurred.",
            default: "other"
        }
    }, 
    passthrough: {
        except: ["inner"]
    },
    supportServer: false
});

// var events = require("../../../uam/events.js");
// var uai = require("../interface/interface.js");
// var uae = require("../interface/element.js");

var types = {
  "cart.empty": {
    title: "Checking out",
    description: "Your cart has yet to have any styles!"
  },
  "cart.error": {
    title: "Checking out",
    description: "Your cart has yet to have any styles!"
  },
  "product.unavailable": {
    title: "Product Unavailable",
    description: "The product is unavailable."
  },
  "other": {
    title: "Search Fault",
    description: "There was a problem with the system."
  },
  "notfound": {
    title: "Product Not Found",
    description: "The product is unavailable."
  }
};

/**
 * Renders the product unavailable element.
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
async function render(options) {

    console.log("luxe.error", options);

    var extra = "";

    if (options.error) {
        extra = options.error.message;
    }

    var myType = types[options.type];

    var ele = { 
        "n": "div",
        "c": "row text-center d-block",
        "i": {
          "n": "div",
          "c": "bg-5-dark tiny-outline m-3 p-3 text-black border-primary border-2 border rounded col-10 col-md-6 d-inline-block bg-gray",
          "i": [{
            "n": "h1",
            "c": "text-start ps-3 pe-3",
            "i": myType.title
          }, {
            "n": "p",
            "c": "text-start ps-3 pe-3",
            "i": [myType.description, " Check out the ",
              {
                "n": "a",
                "href": "../../../../../../../../../",
                "i": "latest styles"
              },
            ", ", 
            {
              "n": "a",
              "href": "../../../../../../../../../designers",
              "i": "designers"
            },
            ", or ", 
            {
              "n": "a",
              "href": "../../../../../../../../../new-arrivals",
              "i": "new arrivals"
            },
              " waiting for you. ", extra]
          }]
        }
      };

      return ele;

}; module.exports.render = render;