/*

This is the general log interface, all UA Tools,
use this interface.

Feel free to modify it for your particuluar application.
 */

const util = require('util');
var f = require("../scripts/f.js");

var logs = [];

/*
Adds an entry into the logs array based on the const type provided.
 */
function AddEntry(type, message, data) {
if (!silent) {
  $type = "unknown";
  switch (type) {
    case 1:
      $type = "info";
    break;
    case 2:
      $type = "warning";
    break;
    case 3:
      $type = "error";
    break;
  }

  var entry = {
    "on": new Date(),
    "message": message,
    "type": $type,
    "data": data
  };

  logs.push(entry);

  if (useConsole) {
    //console.log("trying...");
    //show all messages in console
    switch (type) {
      case typeofinfo:
        // console.info(message);
      break;
      case typeofwarning:
        console.warn(message);
      break;
      case typeoferror:
        console.error(message);
      break;
      default:
        // console.info(message);
      break;
    }

    OutputDataToConsole(data);
  }

  if (
    (throwErrors) &&
    (type == typeoferror)
  ) {
      throw message;
    }
  }

}

const typeofinfo = 1;
const typeofwarning = 2;
const typeoferror = 3;

function info(message, ...obj) {
  AddEntry(typeofinfo, message, obj);
}

function warn(message, ...obj) {
  AddEntry(typeofwarning, message, obj);
}

function error(message, ...obj) {
  AddEntry(typeoferror, message, obj);
}

function GetEntries() {
  return logs;
}

//throw errors
var throwErrors = true;

//show messages in console
var useConsole = true;

var silent = false;

var showtrace = false;

var useutil = false;

var storage = true;

/*
This tells the log entry to
  throwErrors - actually stop javascript and throw errors.
  useConsole - show all items the moment they get added.
 */

/**
 * This tells the log entry to
 *  throwErrors - actually stop javascript and throw errors.
 *  useConsole - show all items the moment they get added.
 * @param  {*}  _throwErrors       [actually stop javascript and throw errors]
 * @param  {*}  _useConsole        [show all items the moment they get added]
 * @param  {Boolean} [_silent=false]    [do not report messages]
 * @param  {Boolean} [_useutil=true]    [Use the util function or use defualt for var output]
 * @param  {Boolean} [_storage=true]    [Store all log entries in the array]
 * @param  {Boolean} [_showtrace=false] [Output a trace to the log call]
 * @return {*}                     [description]
 */
function init(_throwErrors, _useConsole, _silent = false, _useutil = true, _storage = true, _showtrace = false) {
  throwErrors = _throwErrors;
  useConsole = _useConsole;
  silent = _silent;
  useutil = _useutil;
  storage = _storage;
  showtrace = _showtrace;
}

function ReportStatusToConsole() {
  if (silent) {
    console.warn("The log is hushed.");
  }

  if (storage) {
    console.warn("Recording LOG Entries, RAM utilization may be high.");
  }

  if (showtrace) {
    console.warn("Each log output will also include a trace to console only.");
  }

  if (useConsole) {
    console.warn("The log will output to console.");
  }

  if (throwErrors) {
    console.warn("The log will through errors.");
  }

  console.log("logs:", logs);

}

/**
 * Outputs the complete data block of a varaible.
 * @param       {*} data [description]
 * @constructor
 */
function OutputDataToConsole(data) {
  if (!Array.isArray(data) || !data.length) {
  } else {

    data.forEach((ite, i) => {

      var item = false;
      
      try {
        if (f.isDomElement(ite)) {
          item = document.importNode(ite, true);
        } else {

          //if its an error object,
          if (typeof ite == "object") {
            //item = {...ite}; - don't clone because error object's done clone
            item = ite;
          } else if (typeof ite == "string") {
            item = ite + "";
          } else if (typeof ite == "boolean") {
            item = (ite == true);
          } else if (typeof ite == "array") {
            item = [...ite];
          } else if (isNaN(ite) ==  false) {
            item = ite * 1;
          } else {
            item = ite;
          }

        }
      } catch (error) {
        item = ite;
      }

    // if (data.length == 1 ) {
    //   if (useutil) {
    //     console.log(util.inspect(data[0], true, null, true /* enable colors */));
    //   } else {
    //     console.log(data[0]);
    //   }
    // } else {
    //   if (useutil) {
    //     console.log(util.inspect(data, true, null, true /* enable colors */));
    //   } else {
    //     console.log(data);
    //   }
    // }

      if (useutil) {
        console.log(util.inspect(item, true, null, true /* enable colors */));
      } else {

        //if it's an error object
        if ("error" in item) {
          console.log(item.error, {
            error: item.error,
            safe: f.GenerateSafeError(item.error),
            stack: f.ParseStackTrace(item.error.stack)
          });
        } else {
          // console.log(item);   
        }
      }

      // if ("stack" in item) {
      //   if (useutil) {
      //     console.log(util.inspect(item.stack, true, null, true /* enable colors */));
      //   } else {
      //     console.log("Data", item.stack);
      //   }
      // }

   });

    if (showtrace) {
      // console.trace("Calling function trace...");
    }

  }
}

function isError(obj){
  return Object.prototype.toString.call(obj) === "[object Error]";
}

module.exports.OutputDataToConsole = OutputDataToConsole;
module.exports.ReportStatusToConsole = ReportStatusToConsole;
module.exports.info = info;
module.exports.warn = warn;
module.exports.error = error;
module.exports.GetEntries = GetEntries;
module.exports.init = init;
