/*!
 * @deprecated
 * 
 * This is the old, more complicated logger for Universe Apps.
 * It is replaced by the open source project @jumpcutking/console
 * A console can be created for actions to be wrapped in and stored in a log.
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Universe App Logger by The Universe
 * 
 * Justin K Kazmierczak
 * 
 * You can append one log as en entry to another log.
 * 
 * The log records the time of entry of each entry.
 * For runtime estimates subtract the entry "on" from the log "created".
 * 
 */
const util = require('util');
const { isModuleNamespaceObject } = require('util/types');

/**
 * The defualt severity level defintion
 */
 var severity = {
  0: "undefined",
  1: "info",
  2: "warnning",
  3: "error",
  4: "verbose"
};

/**
 * The severity level to treat entries as informatal messages.
 */
var level_info = 1;
module.exports.level_info = level_info;

/**
 * The severity level to treat entries as warning messages.
 */
var level_warning = 2;
module.exports.level_warning = level_warning;

/**
 * The severity level to treat entries as errors.
 */
var level_error = 3;
module.exports.level_error = level_error;

/**
 * Allow the logger to throw errors based on the severity level of "level_error".
 */
var throwErrors = true;
module.exports.throwErrors = throwErrors;

/**
 * Show up to this specified level. 
 * 1, info. 2, warning. 3, error. 4+ extra
 */
var showLevel = 3;
module.exports.showLevel = showLevel;

/**
 * Allow the log to report messages to the console.
 */
var useConsole = true;
module.exports.useConsole = useConsole;

/**
 * When a log is silent, no information is recorded.
 */
var silent = false;
module.exports.silent = silent;

/**
 * Output a logs trace.
 */
var showtrace = false;

/**
 * Use util to output objects.
 */
var useutil = true;

/**
 * The depth the util will output objects with.
 */
var utildepth = 10;

/**
 * Show hidden objects on util output.
 */
var utilshowhidden = false;

// /**
//  * store the log entries
//  */
// var useStorage = false;

/**
 * Creates a log.
 * @returns a log object
 */
function create(options = {}) {

  //default object & defualt properties
  var log = {
    created: Date.now(),
    store: [],
    add: __add,
    useStorage: true,
    namespace: null
  };

  log = setOptions(log, options);
  return log;

}

/**
 * set's option overides in the log.
 * Options: 
 *  useStorage: should I store entries in the object or just report them
 * @param {*} log 
 * @param {*} options 
 */
function setOptions(log, options = {}) {

  if (typeof options === "string") {
    log.namespace = options;
  } else if (typeof options === "object") {
    if ("useStorage" in options) {
      log.useStorage = options.useStorage;
    }

    if ("namespace" in options) {
      log.namespace = options.namespace;
    }
  }

  return log;

}

/**
 * Adds an entry to the log
 * @param {*} id An identifier (beyond namespace or origin) for the resulting error. (API's may use this)
 * @param {*} message he human readable message to display.
 * @param {*} data Any relevant data that helps recreate the error.
 * @param {*} level The level of the message. Default: 0 undefined, 1 info, 2 warnning, 3 error, 4 unknown.
 * @param {*} trace The stack trace, where applicable.
 * @param {*} trace The namespace of the object call.
 * @param  {...any} origin The origin of the message usually a drill down orgin.
 * @returns The index of the entry in the log.
 */
function __add (id, message, data = false, level = 2, namespace = null, trace = false, ...origin) {
  if (!silent) {

      //the formed entry
      var entry = {
        id: id,
        on: new Date(),
        message: message,
        severity: DefineSeverity(level),
        level: level,
        data: data,
        trace: trace,
        namespace: namespace,
        origin: origin
      };

      // console.log(entry);

      //puush the fromed entry
      var index = -1;
      if (this.useStorage) {
        //We are storing the entries in this log.
        index = this.store.push(entry);
      }

      //output entry to console
      if (useConsole) {
        OutputToConsole(entry);
      }

    if (
      (throwErrors) &&
      (level === level_error)
      ) {
        throw GetMessageForEntry(entry);
      }
  }

  return index;
  
}

/**
 * Defines a severity level for easy readability.
 * Returns unknown if the level is not in the supplied severity array.
 * @param {*} level 
 * @returns  The severity level.
 */
function DefineSeverity(level) {
  if (level in severity) {
    return severity[level];
  } else {
    return "unknown";
  }
}

/**
 * Generates a reportable message.
 * @param {*} entry 
 * @returns The message.
 */
function GetMessageForEntry(entry) {

    var message = "";

    if (!(entry.namespace === null)) {
      return `${entry.namespace}:${entry.id} ${DefineSeverity(entry.level)}(${entry.level}) ${entry.message}`;
      // return entry.namespace + ":" + entry.id + " " + DefineSeverity(entry.level) + " " + entry.message;
    }

    message = message + `${entry.id}: ${DefineSeverity(entry.level)} ${entry.message}`;
    return message;
 }

module.exports.GetMessageForEntry = GetMessageForEntry;

/**
 * Reports the global settings for the logger to the console.
 */
function ReportStatusToConsole() {
  if (silent) {
    console.warn("The log is hushed.");
  } else {
    console.warn("Recording entries.");
  }

  if (showtrace) {
    console.warn("Each log output will also include a trace to console only.");
  }

  if (useConsole) {
    console.warn("The log will output to console.");
  }

  if (throwErrors) {
    console.warn("The log will through errors.");
  }

}
module.exports.ReportStatusToConsole = ReportStatusToConsole;

var generateStackTrace = require("../uam/functions/generateStacktrace.js").function;

/**
 * Outputs the complete data block of a varaible.
 * @param {*} data [description]
 * @constructor
 */
function OutputToConsole(entry) {

  if (entry.level > showLevel) {
    return;
  }

    var newMsg = GetMessageForEntry(entry), 
    data = entry.data, trace = entry.trace;
  
  if (!Array.isArray(data) || !data.length) {
  } else {
    data.forEach((ite, i) => {

      var item = false;
      
      try {
        if (isDomElement(ite)) {
          item = document.importNode(ite, true);
        } else {
          if (typeof ite == "object") {
            //item = {...ite}; - don't clone because error object's done clone
            item = ite;
          } else if (typeof ite == "string") {
            item = ite + "";
          } else if (typeof ite == "boolean") {
            item = (ite == true);
          } else if (typeof ite == "array") {
            item = [...ite];
          } else if (isNaN(ite) ==  false) {
            item = ite * 1;
          } else {
            item = ite;
          }

        }
      } catch (error) {
        item = ite;
      }

    // if (data.length == 1 ) {
    //   if (useutil) {
    //     console.log(util.inspect(data[0], true, null, true /* enable colors */));
    //   } else {
    //     console.log(data[0]);
    //   }
    // } else {
    //   if (useutil) {
    //     console.log(util.inspect(data, true, null, true /* enable colors */));
    //   } else {
    //     console.log(data);
    //   }
    // }

      if (useutil) {
        console.log(util.inspect(item, utilshowhidden, 
            utildepth, true /* enable colors */));
      } else {
        // console.log(item);
      }

      // if ("stack" in item) {
      //   if (useutil) {
      //     console.log(util.inspect(item.stack, true, null, true /* enable colors */));
      //   } else {
      //     console.log("Data", item.stack);
      //   }
      // }

   });

    if (showtrace) {
      console.log(trace);
    }

  }

  if (entry.data === null) {
        //show all messages in console
        OutputJustMessageToConsole(entry, newMsg);
  } else {

    if (useutil) {
      OutputJustMessageToConsole(entry, newMsg);
      console.log(util.inspect(entry.data, utilshowhidden, 
        utildepth, true /* enable colors */));
    } else {
        //show all messages in console
      OutputMessageAndObjectToConsole(entry, newMsg);
    }
  }

  console.warn("The old logger is deprecated. Please use @jumpcutking/console instead.", {
    from: generateStackTrace(2)[0]
  });


}


function OutputMessageAndObjectToConsole(entry, newMsg) {
  switch (entry.level) {
    case level_info:
        //console.info(newMsg);
        console.log('\x1b[36m%s\x1b[0m', "" + newMsg, entry.data);
    break;
    case level_warning:
        console.warn(newMsg, entry.data);
    break;
    case level_error:
        console.error(newMsg, entry.data);
    break;
    default:
        console.info(newMsg, entry.data);
    break;
  }
}

/**
 * Outputs just the message and no items.
 */
function OutputJustMessageToConsole(entry, newMsg){
  switch (entry.level) {
    case level_info:
        //console.info(newMsg);
        console.log('\x1b[36m%s\x1b[0m', "" + newMsg);
    break;
    case level_warning:
        console.warn(newMsg);
    break;
    case level_error:
        console.error(newMsg);
    break;
    default:
        console.info(newMsg);
    break;
  }    
}

/**
 * Detects if a object is a dom object.
 * @param  {*} obj The Object to search
 * @return {*} Returns true or false
 */
 isDomElement = function(obj) {
    return !!(obj && obj.nodeType === 1);
}

module.exports.create = create;
module.exports = create;