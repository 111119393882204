/*!
 * Wrapper for lottie player.
 * 
 * Built by @jumpcutking
 */

var jsonRender = require("../interface/jsonRender.js");
// var namespace = "ua.element.serverNotSupported";
// exports.namespace = namespace;

var required = ["inner"];

var define = {
  namespace: "ua.element.serverNotSupported",
  title: "Server Side Not Supported Tag",
  description: "Returns a JSON object for unsupported server side rendering for the client to render. This will make the client render the element instead of the server BUT will be bad for SEO.",
  // supportServer: false,
  fields: {
    "inner": {
      type: "The orginal json control to render.",
      description: "Any options - please note, this control will only render properly on client side.",
      required: true
    }
  },
  required: required
}; exports.define = define;

exports.render = async function (options) {
   //var date = new Date($(this).attr("fromserver"));

  //  if ("UATisServer" in window && window.UATisServer) {

    //I'm not comapatible with server side rendering (yet)

    var onlyClientSideRender = {
      "n": "json",
      "type": "ua/interface",
      // "render": "no-server",
      "i": JSON.stringify(options.inner, null, 0) + ""
    };

    // console.info("Only client side render", onlyClientSideRender);
    return await jsonRender.render(onlyClientSideRender);

  // };

  // return jsonRender.render(lottie, {
  //   PassthroughOptions: options
  // });

};


// function BuildObjectForNamespace(_namespace) {
//   // var n = {
//   //   namespace: _namespace,
//   //   define: exports.define,
//   //   render: exports.render
//   // };
//   // console.info("Built client only rendering object for namespace", n);
//   return {
//     namespace: _namespace,
//     define: exports.define,
//     render: exports.render
//   };
// } exports.BuildObjectForNamespace = BuildObjectForNamespace;