/*!
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.carousel";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var events = require("../../../uam/events.js");
var uai = require("../interface/interface.js");
var uae = require("../interface/element.js");

var bootstrap = {};
try {
  bootstrap = require("bootstrap");
} catch (error) {
  
}

// var uae = require("./templates/ua.element.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Carousel",
  description: "Displays a modern bootstrap carousel.",
  fields: {
    "inner": {
      type: "array of items", 
      fields: {
        "inner": {
          type: "object",
          description: "The inner items of the carousel."
        },
        "active": {
            type: "boolean",
            description: "Is this item the active item. The first carousel with this item will be the active item, others will be inactive."
        },
        "interval": {
            type: "number",
            description: "The amount of time in milliseconds to display the item."
        }
      },
      description: "The inner items of the carousel."
    },
    "autoplay": {
        type: "boolean",
        description: "Should the carousel autoplay.",
        default: true
    }, 
    "hidearrows": {
        type: "boolean",
        description: "Should the arrows be hidden.",
        default: false
    },
    "hideindicators": {
        type: "boolean",
        description: "Should the indicators be hidden.",
        default: false
    },
    "random": {
      type: "boolean",
      description: "Will the carousel pick a random item to set as active.",
      default: false,
      addtouae: true
    }
  },
  errors: {
    noCarouselItems: {
      title: "No Carousel Items",
      description: "There are no items to render in the carousel."
    }
  },
  required: required
} 

var errors = uae.getErrors(exports.define);


/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var id = uai.generateRandomID(36);

  var items = [];
    var hasActive = false;
    for (var i = 0; i < options.inner.length; i++) {
        var item = {
            button: {
                n: "button",
                type: "button",
                "data-bs-target": "#" + id,
                "data-bs-slide-to": i,
                "aria-label": "Slide " + i
            }, item: {
                n: "div",
                c: "carousel-item",
                i: options.inner[i].inner
            }
        }

        if ("icon" in options.inner[i]) {
          item.button.i = {
            "n": "i",
            "c": options.inner[i].icon,
          }

          if ("iconHover" in options.inner[i]) {
            item.button.i["data-bs-hover"] = options.inner[i].iconHover;
          }
        }

        // if ("iconHover" in options.inner[i]) {
        //   item.button.iconHover = options.inner[i].iconHover;
        // }

        if (!(hasActive)) {
            if (options.inner[i].active) {
                item.button.c = "active";
                item["aria-current"] = "true";
                item.item.c += " active";
                hasActive = true;
            }
        }

        if (options.inner[i].interval) {
            item.item["data-bs-interval"] = options.inner[i].interval;
        }

        items.push(item);

    }

    if (items.length == 0) {
      errors.noCarouselItems.throw(options);
    } 

    //if no active set the first one to active
    if (!hasActive) {
        items[0].button.c = "active";
        items[0].item.c += " active";
        items[0]["aria-current"] = "true";
        hasActive = true;
    }

    var caroseul = {};

    if (items.length > 1) {
  
      caroseul = {
        n: "div",
        c: "carousel slide text-primary",
        id: id,
        i: [
            {
                n: "div",
                c: "carousel-inner",
                i: items.map(function (item) {
                    return item.item;
                })
            }
        ]
      };
 
      if (!options.hideindicators) {
        caroseul.i.unshift({
            n: "div",
            c: "carousel-indicators",
            i: items.map(function (item) {
                return item.button;
            })
        }); 
      }

      if (!options.hidearrows) {
        caroseul.i.push([{
            n: "button",
            c: "carousel-control-prev",
            type: "button",
            "data-bs-target": "#" + id,
            "data-bs-slide": "prev",
            i: [
                {
                    n: "i",
                    c: "bi bi-chevron-left text-primary",
                    "aria-hidden": "false"
                },
                {
                    n: "span",
                    c: "visually-hidden",
                    i: "Previous"
                }
            ]
        },
        {
            n: "button",
            c: "carousel-control-next",
            type: "button",
            "data-bs-target": "#" + id,
            "data-bs-slide": "next",
            i: [
                {
                    n: "i",
                    c: "bi bi-chevron-right text-primary",
                    "aria-hidden": "false"
                },
                {
                    n: "span",
                    c: "visually-hidden",
                    i: "Next"
                }
            ]
        }]);
      }

    } else {
      caroseul = {
        n: "div",
        c: "carousel slide text-primary",
        id: id,
        i: [
            {
                n: "div",
                c: "carousel-indicators d-none",
                i: items.map(function (item) {
                    return item.button;
                })
            }, 
            {
                n: "div",
                c: "carousel-inner",
                i: items.map(function (item) {
                    return item.item;
                })
            },
            {
                n: "button",
                c: "carousel-control-prev d-none",
                type: "button",
                "data-bs-target": "#" + id,
                "data-bs-slide": "prev",
                i: [
                    {
                        n: "i",
                        c: "bi bi-chevron-left text-primary",
                        "aria-hidden": "false"
                    },
                    {
                        n: "span",
                        c: "visually-hidden",
                        i: "Previous"
                    }
                ]
            },
            {
                n: "button",
                c: "carousel-control-next d-none",
                type: "button",
                "data-bs-target": "#" + id,
                "data-bs-slide": "next",
                i: [
                    {
                        n: "i",
                        c: "bi bi-chevron-right text-primary",
                        "aria-hidden": "false"
                    },
                    {
                        n: "span",
                        c: "visually-hidden",
                        i: "Next"
                    }
                ]
            }
        ]
    };
    }


    if (options.autoplay) {
        caroseul["data-bs-ride"] = "carousel";
    }

    if (options.random) {
     
      //remove active from all items
      items.forEach(function (item) {
        try {
          item.button.c = item.button.c.replace(" active", "");
          item.item.c = item.item.c.replace(" active", "");
          delete item["aria-current"];
        } catch (error) {
          
        }
      });
     
      //pick a random item to set as active
      var random = Math.floor(Math.random() * items.length);
      items[random].button.c = "active";
      items[random].item.c += " active";
      items[random]["aria-current"] = "true";

      console.log(`Random Carousel: ${random}`, items[random]);

    }

  return caroseul;

}

function ActivateCarousel() {
  var carousels = document.querySelectorAll("[data-bs-ride='carousel']");

  console.log("Carousels", carousels);
  carousels.forEach(function (carousel) {
      var carouselInstance = new bootstrap.Carousel(carousel, {
          interval: 5000
      });
  });
} module.exports.ActivateCarousel = ActivateCarousel;



/**
 * Check for a random item to set as active.
 */
events.on("interface.afterrender", async function() {
  var uaes = document.querySelectorAll(`uae[namespace='${namespace}']`);

  for (var i = 0; i < uaes.length; i++) {

    var uae = uaes[i];

    if (uae.getAttribute("interface.afterrender")) {
      continue;
    }

    if (uae.getAttribute("random") == "true") {

      console.log("Random Carousel", uae.getAttribute("random"));

      var carousel = bootstrap.Carousel.getOrCreateInstance(uae.querySelector(".carousel"));
      console.log("Carousel", carousel);

      var totalItems = uae.querySelectorAll(".carousel-item").length;

      var random = Math.floor(Math.random() * totalItems);
      carousel.to(random);

      console.log(`Random Carousel: ${random}`);

    }

    uae.setAttribute("interface.afterrender", "true");

  }

});



/** 
 * 
 * 
 * 
<div id="${randomID}" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#${randomID}" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#${randomID}" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#${randomID}" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
      ${item.inner}
    </div>
    <div class="carousel-item">
      ${item}
    </div>
    <div class="carousel-item">
      ${item}
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#${randomID}" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#${randomID}" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
*/
