/*!
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");

var define = {
  namespace: "ua.checkbox",
  title: "Form Group: Checkbox",
  description: "A form group with a checkbox that optionally starts checked based on a specified condition.",
  control: true,
  prevalidate: true,
  supportServer: false,
  fields: {
    "name": {
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      type: "string",
      description: "The title of the control.",
      required: true
    },
    "icon": {
      type: "string",
      description: "The icon of the control.",
      required: false
    },
    "checked": {
      type: "boolean",
      description: "Determines if the checkbox is initially checked.",
      default: false
    }
  },
  passthrough: {
    except: ["checked", "type", "id"]
  }
}; 
module.exports.define = define;

exports.render = async function (options) {

  var randomName = uai.generateRandomID(24);
//   var checkedAttribute = options.checked ? "checked" : "";

  var element = {
    "n": "div",
    "name": options.name,
    "c": "form-group pb-3",
    "i": [{
      "n": "div",
      "c": "form-check",
      "i": [{
        "n": "input",
        "type": "checkbox",
        "class": "form-check-input",
        "id": randomName,
        // "checked": checkedAttribute
      }, {
        "n": "label",
        "for": randomName,
        "c": "form-check-label",
        "i": options.title
      }]
    }]
  };


  if ("checked" in options) {
    if (options.checked) {
        element.i[0].i[0].checked = true;
    }
  }

  if ("required" in options) {
    element.i[0].i[0].required = options.required;
    }
 
  if ("icon" in options) {
    element.i[0].i.unshift({
      "n": "i",
      "class": options.icon + " form-check-icon"
    });
  }

  var domElement = await jsonRender.render(element);
  
  // Event listeners
  var inputElement = domElement.querySelector('input');
  // inputElement.addEventListener("focus", focus);
  // inputElement.addEventListener("focusout", focus);
  inputElement.addEventListener("change", (event) => {
    setTimeout(() => {
      focus(event);
    }, 100);
  });

  return domElement;
}

function focus(event) {
  var input = event.target;
  input.classList.remove("is-invalid");
  input.classList.remove("is-valid");
  if (input.checked !== input.defaultChecked) {
    input.classList.add("is-valid");
  } else {
    input.classList.add("is-invalid");
  }
}

function validation(control) {
  var input = control.querySelector("input");
  var errors = [];
  if (!input.checkValidity()) {
    errors.push({
      type: "validation",
      message: input.validationMessage
    });
  }
  return errors;
}

async function save(name, control, repo) {
  var input = control.querySelector("input");
  repo.errors = validation(control);
  repo.success = repo.errors.length === 0;
  repo.data = { checked: input.checked };
  return repo;
}

async function load(name, control, data) {
  var input = control.querySelector("input");
  input.checked = data.checked;
}

module.exports.save = save;
module.exports.load = load;
