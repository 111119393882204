/* {"ns":"universe.functions.resolve", "v":1, "i": false} */

// const { resolveInclude } = require("ejs");

/**
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * Stallion.
 * Prompt.
 * Universe App Scafolding Object
 * 
 * Justin K Kazmierczak
 * 
 * Quick function for resolve file paths to app context.
 * 
 */

var namespace = "sleep";

/**
 * Set's the thread to sleep for a given amount of time.
 * @param {*} miliseconds 
 * @returns A promise that resolves after the given amount of time.
 */
function sleep(miliseconds) {

    return new Promise(resolve => setTimeout(resolve, miliseconds));

}

module.exports = {
    namespace: namespace,
    // mamespace: universe.functions.resolve,
    function: sleep,
    tests: [{
        namespace: `${namespace}.default`,
        must: true,
        run: async () => {
           
            //test sleep

            var miliseconds = 1000;
            var start = new Date();
            await sleep(miliseconds);
            var end = new Date();

            var time = end - start;

            if (time >= miliseconds) {
                return true;
            } else {
                return false;
            }

        }
    }]
};